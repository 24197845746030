import React from 'react';
import Chat from 'Components/core/Chat/Chat';
import { Message } from 'Components/core/Chat/type';
import Icon from 'Components/core/Icon/Icon';
import css from './QnA.module.scss';

interface Props {
  data?: Record<string, string>[];
  onClose: () => void;
}

const QnA = ({ data = [], onClose }: Props) => {
  const options = data.map((option, idx) => ({
    text: Object.keys(option)[0],
    diversion: idx + 1,
  }));

  const answers = data.map((answer, idx) => ({
    sender: 'codeduck',
    msg: Object.values(answer)[0],
    diversion: idx + 1,
    options,
  })) as Message[];

  const initialMessages: Message[] = [
    {
      sender: 'codeduck',
      msg: '안녕하세요!<br><b>채팅형 AI 멘토</b> <span style="color:#FF6B00;font-weight:bold">똑또기</span>예요.',
    },
    {
      sender: 'codeduck',
      msg: '코딩을 하다가 모르는 게 생기면 저에게 물어보세요!',
      options,
    },
  ];

  const messages = initialMessages.concat(answers);

  return (
    <div className={css.container}>
      <header className={css.header}>
        <Icon name="codeDuckGlasses" />
        <div className={css.headerText}>
          <p className={css.title}>똑또기</p>
          <p className={css.description}>세상 제일 똑똑한 오리 똑또기</p>
        </div>
        <Icon className={css.closeBtn} name="close" onClick={onClose} />
      </header>
      <div className={css.chatWrapper}>
        <Chat
          messages={messages}
          inputType="input"
          opponentIcon="codeDuckGlasses"
          generateAnswerFromGPT
        />
      </div>
    </div>
  );
};

export default QnA;
