import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import StepWrapper from 'Components/core/StepWrapper';
import Button from 'Components/core/Button';
import Icon from 'Components/core/Icon/Icon';
import useTossBilling from 'utils/hooks/useTossBilling';
import { GET_CUSTOMER_KEY, GET_HAS_PURCHASED } from 'gql/payment/query';
import { CustomerKey } from 'gql/payment/type';
import css from './Subscription.module.scss';

const Subscription = () => {
  const getBilling = useTossBilling();

  const { data, loading } = useQuery<{ hasPurchased: boolean }>(
    GET_HAS_PURCHASED,
    {
      context: { endpoint: 'payment' },
      variables: {
        productId: 1,
        product: 'SERVICE_PLAN',
      },
    },
  );

  const [getCustomerKey] = useLazyQuery<{ customerKey: CustomerKey }>(
    GET_CUSTOMER_KEY,
    {
      context: { endpoint: 'payment' },
    },
  );

  const handleBilling = () => {
    getCustomerKey({
      onCompleted: ({ customerKey: { customerKey } }) =>
        getBilling({ customerKey }),
    });
  };

  if (loading || !data) return null;

  const { hasPurchased } = data;
  const btnText = `1개월 ${hasPurchased ? '구독하기' : '무료 체험하기'}`;

  return (
    <StepWrapper submitText={btnText} onSubmit={handleBilling}>
      <Icon className={css.logo} name="justcodeLogo" />
      <h1 className={css.title}>
        구독을 하시면 저스트코드의
        <br />
        문제를 무제한으로 받을 수 있어요.
      </h1>
      <p className={css.subTitle}>
        구독으로 지속적인 코스 학습과 추천을 경험시켜 드립니다!
      </p>
      <Button className={css.button} size="large" onClick={handleBilling}>
        {btnText}
      </Button>
      <p className={css.price}>{!hasPurchased && '이후 '}14,900원/월</p>
      <p className={css.description}>
        {!hasPurchased && '체험 '}기간 종료 전에 알림을 전송해 드려요
      </p>
      <div className={css.benefits}>
        {BENEFITS.map(({ id, text }) => (
          <p key={id} className={css.benefit}>
            <Icon name="check" />
            {text}
          </p>
        ))}
      </div>
    </StepWrapper>
  );
};

export default Subscription;

const BENEFITS = [
  { id: 1, text: '나에게 꼭 맞는 난이도 문제 제공' },
  { id: 2, text: '다양한 코딩 언어 선택 학습' },
  { id: 3, text: '내 실력에 맞춰 최적화되는 AI' },
];
