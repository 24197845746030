import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { TossBillingType } from 'Pages/Toss/type';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';

interface Error {
  [key: string]: string;
}

const useTossBilling = () => {
  const isToken = localStorage.getItem(TOKEN);
  const navigate = useNavigate();

  const getBilling = ({ customerKey }: TossBillingType) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { TossPayments }: any = window;
    const CLIENT_KEY = process.env.REACT_APP_TOSS_BILLING_CLIENT_KEY;
    const tossPayments = TossPayments(CLIENT_KEY ?? '');

    if (!isToken) {
      alert('로그인이 필요한 서비스입니다.');
      navigate(PATH.LOGIN.base);
      return;
    }

    tossPayments
      .requestBillingAuth('카드', {
        customerKey: customerKey || uuidv4(),
        successUrl: `${process.env.REACT_APP_TOSS_SUCCESS_URL}?type=billing`,
        failUrl: `${process.env.REACT_APP_TOSS_FAIL_URL}?type=billing`,
      })
      .catch((error: Error) => {
        if (error) {
          alert(error.message);
        }
      });
  };

  return getBilling;
};

export default useTossBilling;
