import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import StepWrapper from 'Components/core/StepWrapper';
import Icon from 'Components/core/Icon/Icon';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { PATH } from 'utils/constants/routes';
import { GET_USER_LEARNING_DATA } from 'gql/course/query';
import { UserLearningData } from 'gql/course/type';
import { useScrollToFooter } from 'utils/hooks';
import LanguageSelect from './LanguageSelect/LanguageSelect';
import DifficultySelect from './DifficultySelect/DifficultySelect';
import { DifficultyType, LanguageType } from './type';
import css from './LevelSelect.module.scss';

type Steps = 'language' | 'difficulty';

const LevelSelect = () => {
  const navigate = useNavigate();
  const { step } = useParams<{ step: Steps }>();
  const [language, setLanguage] = useState<LanguageType>('Python');
  const [difficulty, setDifficulty] = useState<DifficultyType>('easy');
  const [hasChangedLanguage, setHasChangedLanguage] = useState(false);
  const [hasChangedDifficulty, setHasChangedDifficulty] = useState(false);

  const { data } = useQuery<{
    userLearningData: UserLearningData;
  }>(GET_USER_LEARNING_DATA, {
    context: { endpoint: 'course' },
    fetchPolicy: 'network-only',
    onCompleted: ({ userLearningData }) => {
      const { remainingDailyAssignmentGenerationCount } = userLearningData;

      if (
        remainingDailyAssignmentGenerationCount != null &&
        remainingDailyAssignmentGenerationCount <= 0
      ) {
        navigate(PATH.HOME.base);
      }
    },
  });

  const selectLanguage = (language: LanguageType, disabled?: boolean) => {
    if (disabled) {
      alertVar({
        show: true,
        dialog: AlertMessage.course.info.unreleasedLanguage,
      });

      return;
    }

    setLanguage(language);
    setHasChangedLanguage(true);
  };

  const selectDifficulty = (difficulty: DifficultyType, disabled?: boolean) => {
    if (disabled) {
      alertVar({
        show: true,
        dialog: AlertMessage.course.info.unreleasedDIfficulty,
      });

      return;
    }

    setDifficulty(difficulty);
    setHasChangedDifficulty(true);
  };

  const completeSelection = () => {
    navigate(PATH.CONTENT_LOADING.base, { state: { language, difficulty } });
  };

  useScrollToFooter(hasChangedLanguage);
  useScrollToFooter(hasChangedDifficulty);

  useEffect(() => {
    setDifficulty('easy');
  }, [language]);

  if (!data) return null;

  const SELECT_TYPE: {
    [status in Steps]: {
      title: string;
      description: string;
      selection: JSX.Element;
      onSubmit: () => void;
    };
  } = {
    language: {
      title: '코딩 언어 선택',
      description: '학습 문제를 풀 언어를 골라주세요!',
      selection: (
        <LanguageSelect language={language} onSelect={selectLanguage} />
      ),
      onSubmit: () => navigate(PATH.LEVEL_SELECT.difficulty),
    },
    difficulty: {
      title: '문제 난이도 선택',
      description: '예문을 보고 난이도를 골라주세요!',
      selection: (
        <DifficultySelect
          difficulty={difficulty}
          language={language}
          onSelect={selectDifficulty}
        />
      ),
      onSubmit: completeSelection,
    },
  };

  const { title, description, selection, onSubmit } =
    SELECT_TYPE[step as Steps];

  return (
    <StepWrapper submitText="선택하기" align="end" onSubmit={onSubmit}>
      <section className={css.container}>
        <div className={css.textWrapper}>
          <Icon name="codeDuckGlassesBlack" size={64} />
          <h1 className={css.title}>{title}</h1>
          <p className={css.description}>{description}</p>
        </div>
      </section>
      {selection}
    </StepWrapper>
  );
};

export default LevelSelect;
