import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'Components/core/Button';
import WecodeLogo from 'Components/core/Icon/Logo/Wecode';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import { useGetUserProfile } from 'utils/hooks';
import Profile from './Profile';
import MenuModal from './MenuModal';
import { useSwitchModal } from '../ModalPortal/Modal.hook';
import HamburgerMenu from 'assets/common/ic_hamburger.png';
import css from './GNB.module.scss';

const GNB = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { switchModal, isOpenModal } = useSwitchModal();
  const { data, error } = useGetUserProfile();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem(TOKEN));

  const goToLogin = () => {
    const exceptions = [PATH.LOGIN.contentLogin, PATH.LOGIN.base];

    if (!exceptions.includes(location.pathname)) {
      sessionStorage.setItem('current-location', location.pathname);
    }

    navigate(PATH.LOGIN.base);
  };

  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem(TOKEN) && !error);
  }, [localStorage.getItem(TOKEN), error]);

  return (
    <>
      <header className={css.header}>
        <nav className={css.nav}>
          <div className={css.leftWrapper}>
            <div className={css.logoContainer}>
              <WecodeLogo beta />
            </div>
            {/* <div className={css.navWrapper}>
              {GNB_ITEMS.map(({ id, path, content, shouldLogin }) => {
                if (!isLoggedIn && shouldLogin) return;

                return (
                  <Link key={id} to={path} className={css.navCategory}>
                    {content}
                  </Link>
                );
              })}
            </div> */}
          </div>
          <div className={css.rightWrapper}>
            {isLoggedIn ? (
              <>
                <Profile data={data} />
                <div
                  className={css.hamburgerMenuContainer}
                  onClick={switchModal}
                >
                  <img
                    src={HamburgerMenu}
                    alt="메뉴 버튼"
                    className={css.hamburgerMenu}
                  />
                </div>
              </>
            ) : (
              <div className={css.btnWrapper}>
                <Button variant="lineGrey" onClick={goToLogin}>
                  로그인
                </Button>
              </div>
            )}
          </div>
          {isOpenModal && <MenuModal switchModal={switchModal} />}
        </nav>
      </header>
      <div className={css.dummyNav} />
    </>
  );
};

export default GNB;
