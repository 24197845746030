import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useScrollToFooter = (dependency: any) => {
  useEffect(() => {
    if (!dependency) return;

    const footerHeight =
      document.getElementsByTagName('footer')[
        document.getElementsByTagName('footer').length - 1
      ]?.clientHeight;

    window.scrollTo({
      top: document.body.clientHeight - footerHeight,
      behavior: 'smooth',
    });
  }, [dependency]);
};
