/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { FingerEditor } from '@wecode-codingbootcamp/finger-editor';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'Components/core/Button';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import {
  submittedAnswersVar,
  answerStatusVar,
  customAnswersVar,
} from 'store/Course';
import { PATH } from 'utils/constants/routes';
import {
  EditorType,
  getAnswerComparisonResults,
  replacePrevUserData,
} from 'utils/fingereditor';
import { trim } from 'utils/formats';
import { useCart } from 'utils/hooks/useCart';
import { useWishList } from 'utils/hooks/useWishList';
import { cn, cond } from 'utils/styles';
import { KAKAO_OPEN_CHAT } from 'utils/constants/links';
import { RESULT_COURSES } from 'utils/constants/temp';
import {
  UPDATE_COURSE_CONTENT_STATUS,
  CREATE_USER_APP,
} from 'gql/course/mutation';
import PreviewSection from './PreviewSection';
import ContentsController from './UserSection/Controller&';
import ContentsPurchase from './UserSection/Purchase&';
import ContentsNavigator from './UserSection/Navigator&';
import EditorController from '../Components/FingerEditor/Component/DeprecatedEditorController/Controller';
import Toast from '../Components/FingerEditor/Component/Toast';
import Content from '../Components/MarkdownContent';
import ContentHeader from '../Components/ContentHeader';
import LoginModal from '../CurriculumDetail/LoginModal';
import { ContentType, Course, CourseContent } from '../DeprecatedSession/type';
import { ToastType } from '../Components/FingerEditor/Component/Toast/Toast';
import css from './DeprecatedContents.module.scss';

export interface ContentsProps {
  courseContent: CourseContent;
  contentList: ContentType[];
  courseData: Course;
  sampleCourseId?: number;
  sampleContentId?: number;
  isSample?: boolean;
  setContentId?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const { dataLayer }: any = window;

const DeprecatedContents = ({
  courseContent,
  contentList,
  courseData,
  sampleCourseId,
  sampleContentId,
  isSample = false,
  setContentId,
}: ContentsProps) => {
  const {
    id,
    sequence,
    content,
    status,
    assignmentSource,
    preAssignmentSource,
    userData,
    editor,
  } = courseContent;
  const {
    id: productId,
    price,
    title,
    purchased,
    deployed,
    latestInProgressContentId,
  } = courseData;
  const { code, assignmentChoices, filename } = editor;
  const editorRef = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [caret, setCaret] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobilePreview, setIsMobilePreview] = useState(false);
  const { answers } = courseContent.editor;
  const answerStatus = useReactiveVar(answerStatusVar);
  const submittedAnswers = useReactiveVar(submittedAnswersVar);
  const navigate = useNavigate();
  const {
    handleAddToCart,
    isOpenModal: isCartModal,
    switchModal: switchCartModal,
  } = useCart();
  const {
    changeHeart,
    isOpenModal: isWishlistModal,
    switchModal: switchWishlistModal,
  } = useWishList(productId, price, title);
  const { isOpenModal, switchModal } = useSwitchModal();
  const { courseId, contentId } = useParams<{
    [key: string]: string;
  }>();

  const isLastContent =
    sequence === contentList[contentList.length - 1].sequence;
  const preAssignmentSourceCopy = { ...preAssignmentSource };
  delete preAssignmentSourceCopy['preview.txt'];

  const previewSource =
    answerStatus === 'correct' || assignmentSource?.['preview.txt']
      ? assignmentSource
      : preAssignmentSourceCopy;

  const answerComparisonResults = getAnswerComparisonResults(
    answers?.flatMap(({ answers }) => answers) as string[],
    submittedAnswers,
    answerStatus,
  );

  const sequenceOptions = useMemo(
    () =>
      assignmentChoices.filter(({ sequence }) => {
        const lastSequence =
          assignmentChoices[assignmentChoices.length - 1].sequence;

        if (submittedAnswers.length === 0) return sequence === 1;

        if (submittedAnswers.length === lastSequence)
          return sequence === lastSequence;

        return sequence === submittedAnswers.length + 1;
      }),
    [submittedAnswers],
  );

  const type = sequenceOptions[0]?.type; // blank별로 타입이 정해져 있는 기존의 형태에 맞게 구현 (230227 래영)
  const hasSingleOptionList =
    new Set(assignmentChoices.map(({ name }) => name)).size ===
    sequenceOptions.length;
  const options = hasSingleOptionList
    ? assignmentChoices.filter(({ sequence }) => sequence === 1)
    : sequenceOptions;
  const feedback =
    (answers &&
      answers.length === 1 &&
      options.find(({ name }) => name === submittedAnswers[0])?.feedback) ||
    '';
  // TODO: 추후에 결과물이 없음을 보여주는 데이터 추가되면 수정할 것
  const isQuiz = type === 'quiz' || !RESULT_COURSES.includes(productId);
  const latestInProgressContentSequence = contentList.find(
    ({ id }) => id === latestInProgressContentId,
  )?.sequence as number;
  const toastVariant: ToastType = feedback
    ? 'feedback'
    : isQuiz
    ? 'quiz'
    : 'resultedContent';

  const [updateCourseContentStatus] = useMutation(
    UPDATE_COURSE_CONTENT_STATUS,
    {
      context: { endpoint: 'course' },
      variables: {
        id,
      },
      onError: () => {
        alertVar({
          show: true,
          type: 'error',
          dialog: AlertMessage.common.error.unknown,
          hasHelpEmailInfo: true,
        });
      },
    },
  );

  const [createUserApp] = useMutation(CREATE_USER_APP, {
    context: { endpoint: 'course' },
    variables: {
      courseId: Number(courseId ?? sampleCourseId),
    },
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  const deleteAnswer = () => {
    submittedAnswers.pop();
    submittedAnswersVar([...submittedAnswers]);
    answerStatusVar('not submitted');

    if (type === 'button' || answerStatus === 'not submitted') {
      customAnswersVar({});
      return;
    }
  };

  const goToPrevPage = () => {
    const prevContentId = contentList.find(
      content => content.sequence === sequence - 1,
    )?.id;

    if (isSample && setContentId) return setContentId(Number(prevContentId));

    navigate(`${PATH.COURSE.base}/${courseId}/${prevContentId}`);
  };

  const goToFirstContent = () => {
    const firstContentId = courseData.courseContents.find(
      ({ sequence }) => sequence === 1,
    )?.id;

    if (Number(contentId) === firstContentId) return;

    if (isSample && setContentId) return setContentId(Number(firstContentId));

    navigate(`${PATH.COURSE.base}/${courseId}/${firstContentId}`);
  };

  const goToContentWithSequence = (seq: number) => {
    const newContentId = courseData.courseContents.find(
      ({ sequence }) => sequence === seq,
    )?.id;

    if (Number(contentId) === newContentId) return;

    if (isSample && setContentId) return setContentId(Number(newContentId));

    navigate(`${PATH.COURSE.base}/${courseId}/${newContentId}`);
  };

  const deleteAllAnswers = () => {
    submittedAnswersVar([]);
    answerStatusVar('not submitted');

    if (type === 'button' || answerStatus === 'not submitted') {
      customAnswersVar({});
      return;
    }
  };

  const checkAnswer = (inputAnswers = submittedAnswers) => {
    // back에서 전달해주는 answers가 없는 경우 정답처리한다.
    if (answers === null) return answerStatusVar('correct');

    const isAllSubmitted =
      answers === null || answers.length === inputAnswers.length;
    const isInputEmpty = inputAnswers[0] === ' ';

    if (!isAllSubmitted) return;
    if (submittedAnswers.length === 0 && !isLastContent) return;
    if (isInputEmpty) return;

    if (answerStatus === 'correct') {
      submitAnswer();
      return;
    }

    const isCorrect = answers?.every(({ answers }, idx) =>
      answers.includes(inputAnswers[idx]),
    );

    if (answers?.length === 0) return answerStatusVar('correct');
    else answerStatusVar(isCorrect ? 'correct' : 'incorrect');
  };

  const submitAnswer = () => {
    const nextContentId = contentList.find(
      content => content.sequence === sequence + 1,
    )?.id;

    updateCourseContentStatus({
      onCompleted: () => {
        if (isLastContent) {
          createUserApp({
            onCompleted: () => {
              if (!deployed) {
                dataLayer.push({
                  event: 'CourseCompleted',
                });

                dataLayer.push({
                  event: 'class_completion',
                  class_name: title,
                });
              }

              navigate(
                `${PATH.COURSE.base}/${courseId ?? sampleCourseId}/completed`,
              );
            },
          });
        } else {
          if (isSample && setContentId)
            return setContentId(Number(nextContentId));

          navigate(`${PATH.COURSE.base}/${courseId}/${nextContentId}`);
        }
      },
    });
  };

  const editMode = {
    on: () => setIsEditing(true),
    off: () => setIsEditing(false),
  };

  const setFirstContentRender = () => {
    if (status !== 'COMPLETED') deleteAllAnswers();
    answerStatusVar('not submitted');
  };

  const setMobilePreviewStatus = () => {
    setIsMobilePreview(!isQuiz && answerStatus === 'correct' && isMobile);
  };

  const setViewPortOnResize = () => {
    const setViewPort = () => {
      setIsMobile(
        isSample ? window.innerWidth < 1024 : window.innerWidth < 768,
      );
    };

    setViewPort();

    window.addEventListener('resize', setViewPort);

    return () => window.removeEventListener('resize', setViewPort);
  };

  const setScrollOnAnswerStatusChange = () => {
    if (!editorRef.current) return;

    const editorPosY = editorRef.current.offsetTop;
    const currentScrollPosY = window.scrollY;

    if (answerStatus !== 'not submitted' && currentScrollPosY > editorPosY) {
      window.scrollTo({ top: editorPosY, behavior: 'smooth' });
    }
  };

  useEffect(setFirstContentRender, [contentId]);
  useEffect(setMobilePreviewStatus, [answerStatus, isMobile]);
  useEffect(setScrollOnAnswerStatusChange, [answerStatus, editorRef]);
  useEffect(setViewPortOnResize, []);
  useLayoutEffect(setFingereditorHeight, [submittedAnswers]);

  return (
    <div className={css.container}>
      <ContentHeader
        contentTitle={title}
        productId={productId}
        price={price}
        purchased={purchased || isSample}
      />
      <main
        className={cn(
          css.contentWrapper,
          css.columnGrid,
          cond(isSample, css.sample),
        )}
      >
        <div className={css.playground}>
          {(!isMobile || answerStatus !== 'correct') && (
            <div className={css.contentWrapper}>
              <Content content={content} />
            </div>
          )}
          {code && (
            <>
              {answerStatus === 'correct' && isMobile && !isQuiz && (
                <div className={css.buttonWrapper}>
                  <Button
                    variant="lineGrey"
                    onClick={() => setIsMobilePreview(!isMobilePreview)}
                  >
                    {isMobilePreview ? '코드 보기' : '결과보기'}
                  </Button>
                </div>
              )}
              {isMobilePreview ? (
                <PreviewSection
                  isSample={isSample}
                  isMobile={isMobile}
                  isQuiz={isQuiz}
                  userData={userData}
                  assignmentSource={previewSource}
                />
              ) : (
                <div ref={editorRef} className={css.editorWrapper}>
                  <FingerEditor
                    // assignmentCode={code}
                    assignmentCode={replacePrevUserData(code, userData)}
                    submittedAnswers={submittedAnswers}
                    filename={filename ?? 'index.html'}
                    className={cn(
                      cond(type === 'input', 'inputWidget'),
                      cond(isEditing, 'editing'),
                      // TODO: 추후에 fingereditor에서 상태 표기할지 말지 정하는 prop 추가되면 삭제 (230420 래영)
                      cond(answerStatus === 'not submitted', 'notSubmitted'),
                    )}
                    showLineNumbers={false}
                    answerComparisonResults={answerComparisonResults}
                  />
                </div>
              )}
              {!isMobilePreview && (
                <Toast
                  variant={toastVariant}
                  answerStatus={answerStatus}
                  feedback={feedback}
                />
              )}
            </>
          )}
          {sequenceOptions.length > 0 && (
            <EditorController
              code={trim(code)}
              options={options}
              type={type as EditorType}
              editMode={editMode}
              answers={answers}
              isEditing={isEditing}
              isLastContent={isLastContent}
              hasSingleOptionList={hasSingleOptionList}
              sampleCourseId={sampleCourseId}
              sampleContentId={sampleContentId}
              caret={caret}
              setCaret={setCaret}
              actions={{
                checkAnswer,
                submitAnswer,
                deleteAllAnswers,
              }}
            />
          )}
          <ContentsController
            courseData={courseData}
            courseContent={courseContent}
            assignmentChoices={assignmentChoices}
            hasEditor={!!code}
            isEditing={isEditing}
            isSample={isSample}
            isQuiz={isQuiz}
            isLastContent={isLastContent}
            actions={{
              deleteAnswer,
              goToPrevPage,
              goToFirstContent,
              deleteAllAnswers,
              submitAnswer,
            }}
          />
          <ContentsNavigator
            sequence={sequence}
            latestInProgressContentSequence={latestInProgressContentSequence}
            contentList={contentList}
            goToContentWithSequence={goToContentWithSequence}
          />
          {!isSample && (
            <ContentsPurchase
              courseData={courseData}
              actions={{ handleAddToCart, changeHeart }}
            />
          )}
          <p className={css.openChat}>
            궁금한 점은{' '}
            <a
              className={css.openChatBtn}
              href={KAKAO_OPEN_CHAT}
              target="_blank"
              rel="noreferrer"
            >
              저스트코드 코딩 질문방
            </a>
            에서 질문하세요!
            <span className={css.password}>비밀번호는 justcode예요!</span>
          </p>
        </div>
        {!isMobile && (
          <PreviewSection
            isSample={isSample}
            isQuiz={isQuiz}
            userData={userData}
            assignmentSource={previewSource}
          />
        )}
      </main>

      {/* FIXME: 간소화 필요 (230131 래영) */}
      {isOpenModal && <LoginModal switchModal={switchModal} />}
      {isCartModal && <LoginModal switchModal={switchCartModal} />}
      {isWishlistModal && <LoginModal switchModal={switchWishlistModal} />}
    </div>
  );
};

export default DeprecatedContents;

const setFingereditorHeight = () => {
  const initialProvider = document.getElementsByClassName(
    'sp-cm',
  ) as HTMLCollectionOf<HTMLElement>;

  // HACK: finger editor 를 찾기 위해 aria-label 없는지 판별 (fingereditor에는 aria-label 적용 되어있지 않음)
  // 추후 finger editor Repo에서 id나 class를 추가적으로 부여 할 예정

  if (initialProvider.length === 0) return;

  const fingerEditorNode = [...initialProvider].find(
    node => !node.getAttribute('aria-label'),
  );

  if (!fingerEditorNode) return;

  const height = fingerEditorNode.children[0]?.scrollHeight;

  const observer = new MutationObserver((mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        fingerEditorNode.style.minHeight = `${height}px`;
        observer.disconnect();
        break;
      }
    }
  });

  observer.observe(fingerEditorNode, { childList: true });

  return () => observer.disconnect();
};
