import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import StepWrapper from 'Components/core/StepWrapper';
import Icon from 'Components/core/Icon/Icon';
import CodeDuck from 'Components/core/CodeDuck';
import ErrorPage from 'Components/core/ErrorPage';
import { PATH } from 'utils/constants/routes';
import { GET_USER_LEARNING_DATA } from 'gql/course/query';
import { UserLearningData } from 'gql/course/type';
import { useGetUserProfile } from 'utils/hooks';
import css from './CourseResult.module.scss';

const CourseResult = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // const [isFirstElement, setIsFirstElement] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsFirstElement(false);
  //   }, 2500);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  if (!state) {
    return <Navigate to={PATH.HOME.base} />;
  }

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useGetUserProfile();

  const { data } = useQuery<{ userLearningData: UserLearningData }>(
    GET_USER_LEARNING_DATA,
    {
      context: { endpoint: 'course' },
    },
  );

  const handleSubmit = () => {
    if (data?.userLearningData.hasActiveSubscription) {
      navigate(PATH.LEVEL_SELECT.language);
    } else {
      navigate(PATH.SUBSCRIPTION.base);
    }
  };

  if (userError) return <ErrorPage />;
  if (!data || userLoading || !userData) return null;

  const level = 1;

  const { name } = userData.userProfile;
  const isFirstElement = true;

  return (
    <StepWrapper submitText="다음" align="end" onSubmit={handleSubmit}>
      <section className={css.resultContainer}>
        {isFirstElement ? (
          <div className={css.textWrapper}>
            <Icon className={css.checkIcon} name="checkAble" />
            <h1 className={css.title}>코딩 학습을 완료했어요!</h1>
            <p className={css.greyText}>
              정말 잘 했어요! 더 열심히 달려볼까요?
            </p>
          </div>
        ) : (
          <section className={css.borderContainer}>
            <CodeDuck
              progress={80}
              size="large"
              status="thumbsUp"
              progressColor="#FFC700"
              barStyle="round"
              barAnimation
            />
            <span className={css.level}>Lv.{level}</span>
            <p className={css.name}>{name}</p>
          </section>
        )}
      </section>
      {/* <div className={css.courseCardContainer}>
    <CourseCard courseInfo={courseData} size="small" />
  </div> */}
    </StepWrapper>
  );
};

export default CourseResult;
