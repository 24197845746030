import { gql } from '@apollo/client';

export const CREATE_WISH_LIST = gql`
  mutation createWishList($courseIds: [Float!]!) {
    createWishList(courseIds: $courseIds) {
      userId
    }
  }
`;

export const DELETE_WISH_LIST = gql`
  mutation deleteWishList($courseIds: [Float!]!) {
    deleteWishList(courseIds: $courseIds) {
      userId
    }
  }
`;

export const GET_UPLOAD_IMAGE_URL = gql`
  mutation {
    updateProfileImage
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation ($updateUserInput: UpdateUserInput!) {
    updateUserProfile(updateUserInput: $updateUserInput) {
      id
      name
      email
      phoneNumber
      gender
      profileImage
    }
  }
`;

export const CREATE_VERIFICATION_CODE = gql`
  mutation createVerificationCode(
    $expirationInMinute: Float
    $phoneNumber: String!
  ) {
    createVerificationCode(
      expirationInMinute: $expirationInMinute
      phoneNumber: $phoneNumber
    )
  }
`;

export const CHECK_VERIFICATION_CODE = gql`
  mutation checkVerificationCode(
    $verificationCode: String!
    $phoneNumber: String!
  ) {
    checkVerificationCode(
      verificationCode: $verificationCode
      phoneNumber: $phoneNumber
    ) {
      id
      verificationCode
      verificationObject
      isVerified
    }
  }
`;

export const UPDATE_VERIFIED_PHONE_NUMBER = gql`
  mutation updateVerifiedPhoneNumber($phoneNumber: String!) {
    updateVerifiedPhoneNumber(phoneNumber: $phoneNumber) {
      id
    }
  }
`;

export const CREATE_GOOGLE_TOKEN = gql`
  mutation createGoogleLogin($googleAuthToken: String!) {
    createGoogleLogin(
      googleAuthToken: $googleAuthToken
      servicePlatform: "JUSTCODE"
    ) {
      accessToken
      hasPassword
    }
  }
`;

export const CREATE_KAKAO_TOKEN = gql`
  mutation createKakaoLogin($kakaoAuthToken: String!) {
    createKakaoLogin(
      kakaoAuthToken: $kakaoAuthToken
      servicePlatform: "JUSTCODE"
    ) {
      accessToken
      hasPassword
      verifiedPhoneNumber
      isNewUser
    }
  }
`;

export const RESET_PASSWORD_QUERY = gql`
  mutation ($password: String!) {
    updatePassword(password: $password)
  }
`;
