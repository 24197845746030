import React from 'react';
import css from './FAQ.module.scss';

const FAQ = () => {
  return (
    <section className={css.container}>
      <p className={css.sectionTitle}>FAQ</p>
      <p className={css.sectionSubtitle}>자주 묻는 질문을 확인해 보세요</p>
      {QNA_LIST.map(({ id, question, answer }) => (
        <div key={id} className={css.qna}>
          <p className={css.question}>{question}</p>
          <p className={css.answer}>{answer}</p>
        </div>
      ))}
    </section>
  );
};

export default FAQ;

const QNA_LIST = [
  {
    id: 1,
    question: '코딩을 전혀 몰라요. 아무런 지식 없이 시작해도 될까요?',
    answer:
      '간편 코딩은 개발이 처음인 사람도 코딩할 수 있는 난이도에요. 사전 지식이 없어도 괜찮아요!',
  },
  {
    id: 2,
    question: '권장되는 컴퓨터 사양이 따로 있을까요?',
    answer:
      '간편 코딩은 따로 편집 프로그램이 필요 없어요. 언제, 어디서든 버튼 하나로 코딩을 배울 수 있답니다.',
  },
  {
    id: 3,
    question: '수업 내용 질문은 어떻게 하나요?',
    answer:
      '궁금한 사항은 아래 저스트코드 질문방을 통해 질문을 남겨주시면 멘토님들이 도와드릴게요.',
  },
];
