import React from 'react';
import Icon from 'Components/core/Icon/Icon';
import { cn, cond } from 'utils/styles';
import css from './PriceBadge.module.scss';

const PriceBadge = ({
  price,
  className = '',
}: {
  price: number;
  className?: string;
}) => {
  const priceText = price === 0 ? 'FREE' : price.toLocaleString();
  return (
    <div className={cn(css.priceBadge, cond(!price, css.free), className)}>
      <Icon name="wonPriceWhite" />
      <strong>{priceText}</strong>
    </div>
  );
};

export default PriceBadge;
