import React from 'react';
// import GoogleLogin from 'Components/GoogleLogin';
import KakaoLogin from 'Components/core/KakaoLogin';
import WecodeLogo from 'Components/core/Icon/Logo/Wecode';
import { cn } from 'utils/styles';
import shotSampleLogin from 'assets/page/login/shot_sample_login.png';
import css from './SocialLogin.module.scss';

const SocialLogin = () => {
  return (
    <main className={css.container}>
      <div className={css.wrapper}>
        <section className={css.lgLeft}>
          <div>
            <WecodeLogo variant="간편코딩" />
          </div>
          <div className={cn(css.imageWrapper, css.desktopOnly)}>
            <img src={shotSampleLogin} alt="login" />
          </div>
        </section>

        <section>
          <div className={css.loginWrapper}>
            <header className={css.textWrapper}>
              <h1 className={cn(css.title, css.mobileHidden)}>
                코딩을 시작할 때는, 저스트코드
              </h1>
              <p className={css.explain}>
                저스트코드와 함께 간편 코딩을 시작하세요.
              </p>
              <div className={css.kakaoLogin}>
                <KakaoLogin />
              </div>
            </header>
            {/* <GoogleLogin /> */}
            <footer>
              <p className={css.footerText}>
                코딩 이제 어렵게 생각하지마세요!
                <br />
                사전 지식 없이 <mark>누구나 쉽게 코딩</mark>할 수 있게
                <br />
                모바일에서 버튼 하나로 코딩을 배울 수 있어요
              </p>
            </footer>
          </div>
        </section>
      </div>
    </main>
  );
};

export default SocialLogin;
