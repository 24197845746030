import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { useLockBodyScroll } from 'utils/hooks';
import css from './Modal.module.scss';

// Portal
interface ModalPortalProps {
  children: ReactNode;
}

export const ModalPortal = ({ children }: ModalPortalProps) =>
  ReactDOM.createPortal(
    children,
    document.getElementById('modal-root') as HTMLElement,
  );

// Core Modal
interface Props {
  switchModal: () => void;
  children?: ReactNode;
}

const Modal = ({ switchModal, children }: Props) => {
  useLockBodyScroll();

  return (
    <ModalPortal>
      <section className={css.container}>
        <div className={css.dimmedBackground} onClick={switchModal} />
        <article onClick={e => e.stopPropagation()}>{children}</article>
      </section>
    </ModalPortal>
  );
};

export default Modal;
