/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { RoutesProps, useLocation } from 'react-router-dom';
import client from 'gql/settings';
import channelTalk from 'utils/channelTalk';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import { GET_USER_PROFILE, UserProfile } from 'utils/hooks';

const { dataLayer }: any = window;

const ChannelTalkProvider = ({ children }: RoutesProps) => {
  const { pathname } = useLocation();
  const isLoggedIn = localStorage.getItem(TOKEN);

  useEffect(() => {
    channelTalk.boot();

    if (EXCEPTIONS.some(path => pathname.includes(path))) {
      channelTalk.hideChannelButton();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const data = client.cache.readQuery<UserProfile>({
        query: GET_USER_PROFILE,
      });

      if (!data) return;

      const { name, realName, birthdate, email, gender, createdAt } =
        data.userProfile;

      dataLayer.push({
        event: 'logged_in',
        member_name: realName || name || 'justcoder',
        member_sex: gender?.toLowerCase() || null,
        member_birthday: birthdate
          ? `${birthdate.slice(4, 6)}-${birthdate.slice(6, 8)}`
          : null,
        member_birthyear: birthdate?.slice(0, 4) || null,
        member_age: birthdate
          ? new Date().getFullYear() - Number(birthdate.slice(0, 4))
          : null,
        member_signup_date: createdAt.split('T')[0],
        user_id: email,
      });
    }

    if (EXCEPTIONS.some(path => pathname.includes(path))) {
      channelTalk.hideChannelButton();

      return;
    }

    channelTalk.showChannelButton();
  }, [pathname]);

  return <>{children}</>;
};

export default ChannelTalkProvider;

const EXCEPTIONS = [
  PATH.COURSE.base,
  PATH.CONTENT.base,
  PATH.APP.base,
  PATH.SHARING.base,
  PATH.LEVEL_TEST.base,
  PATH.LEVEL_SELECT.base,
];
