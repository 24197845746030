import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { usePromiseAlert } from 'Components/core/ModalPortal/usePromiseAlert';
// import WecodeLogo from 'Components/core/Icon/Logo/Wecode';
import { AlertMessage } from 'lib/constant/alertMessage';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import { useGetUserProfile } from 'utils/hooks';
import { alertVar } from 'store/Alert';
import { GET_HAS_PURCHASED } from 'gql/payment/query';
import css from './Mypage.module.scss';

const Mypage = () => {
  const navigate = useNavigate();
  const showModal = usePromiseAlert({
    type: 'info',
    dialog: AlertMessage.common.warn.needLogin,
  });
  const { data: profileData } = useGetUserProfile();
  const isLoggedIn = !!localStorage.getItem(TOKEN);

  const { data: purchaseData } = useQuery<{
    hasPurchased: boolean;
  }>(GET_HAS_PURCHASED, {
    context: { endpoint: 'payment' },
    variables: {
      productId: 1,
      product: 'SERVICE_PLAN',
    },
  });

  const handleLogout = () => {
    alertVar({
      show: true,
      dialog: AlertMessage.auth.info.logout,
      type: 'positive',
      hasCancelButton: true,
      onSuccess: () => {
        localStorage.removeItem(TOKEN);

        navigate(PATH.HOME.base);
      },
    });
  };

  useEffect(() => {
    if (!isLoggedIn) {
      showModal().then(() => {
        sessionStorage.setItem('current-location', location.pathname);
        navigate(PATH.LOGIN.base);
      });
    }
  }, [localStorage.getItem(TOKEN)]);

  if (!isLoggedIn || !profileData || !purchaseData) return <></>;

  const { profileImage, name, createdAt } = profileData.userProfile;
  const { hasPurchased } = purchaseData;
  const firstDate = createdAt.split('T')[0].replaceAll('-', '.');
  // const billingInfoList = [
  //   { item: '등록일', info: '2000-00-00' },
  //   { item: '결제 정보', info: '국민카드 / **** **** **** 4497' },
  //   { item: '다음 결제일', info: '2000-00-00 (14,900원)' },
  // ];
  // const billingService = '1개월 무제한 이용권';
  // const hasBillingInfo = false;

  return (
    <div className={css.container}>
      <h1 className={css.pageTitle}>마이페이지</h1>
      <div className={css.content}>
        <section className={css.userProfile}>
          <img className={css.profileImage} src={profileImage} alt="프로필" />
          <p className={css.name}>{name}</p>
          <p className={css.profileInfo}>
            {hasPurchased ? '구독' : '무료'} 회원
          </p>
          <p className={css.profileInfo}>{firstDate} 에 처음 만났어요</p>
        </section>
        {/* <section>
          <h2 className={css.title}>등록된 결제 정보</h2>
          <div className={css.infoWrapper}>
            {hasBillingInfo ? (
              <>
                <p className={css.billingService}>
                  <WecodeLogo />
                  <span className={css.billingBadge}>{billingService}</span>
                </p>
                {billingInfoList.map(({ item, info }) => (
                  <p className={css.info} key={item}>
                    <span className={css.infoItem}>{item}</span>
                    <span className={css.infoValue}>{info}</span>
                  </p>
                ))}
              </>
            ) : (
              <p className={css.noBillingInfo}>등록된 결제 정보가 없습니다.</p>
            )}
          </div>
        </section> */}
        <button className={css.logoutBtn} onClick={handleLogout}>
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default Mypage;
