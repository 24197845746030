import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PATH } from 'utils/constants/routes';
import { cn, cond } from 'utils/styles';
import Icon from '../Icon/Icon';
import css from './Footer.module.scss';
import { ICONS } from './data';

const Footer = () => {
  const { pathname } = useLocation();

  const isSimplified = SIMPLIFYING_PATH.some(path => pathname.includes(path));
  const isNoMargin = pathname === PATH.LOGIN.base;

  return (
    <footer className={cn(css.footer, cond(isNoMargin, css.noMargin))}>
      <div className={cn(css.container, cond(isSimplified, css.simplified))}>
        {!isSimplified && (
          <div className={css.information}>
            <div className={css.logoWrapper}>
              <Link to={PATH.HOME.base}>
                <Icon name="justcodeLogo" />
              </Link>
              <div className={css.icons}>
                {ICONS.map(({ id, img, url, alt }) => {
                  return (
                    <a
                      key={id}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={img} alt={alt} />
                    </a>
                  );
                })}
              </div>
            </div>
            <div className={css.companyInformation}>
              <p className={css.companyName}>주식회사 그레이스풀레인</p>
              <div className={css.infoBox}>
                {FOOTER_DATA.map(({ category, id }) => (
                  <ul key={id} className={css.categories}>
                    {category.map(({ title, content }) => (
                      <li key={title} className={css.categoryBox}>
                        <p className={css.categoryName}>{title}</p>
                        <p className={css.categoryContent}>{content}</p>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
              <div className={css.policy}>
                <Link to={PATH.TERM.site} className={css.policyContent}>
                  이용 약관
                </Link>
                <Link to={PATH.TERM.privacy} className={css.policyContent}>
                  개인정보보호 정책
                </Link>
                <Link to={PATH.TERM.agreement} className={css.policyContent}>
                  개인정보 수집 및 이용 동의
                </Link>
                <Link to={PATH.TERM.refund} className={css.policyContent}>
                  환불 약관
                </Link>
              </div>
            </div>
          </div>
        )}
        <p className={cn(css.copyright, cond(isSimplified, css.simplified))}>
          Copyright Ⓒ Gracefulrain. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

const SIMPLIFYING_PATH = [
  PATH.LOGIN.base,
  PATH.COURSE.base,
  PATH.CONTENT.base,
  PATH.APP.base,
  PATH.LEVEL_TEST.base,
  PATH.CONTENT_RESULT.base,
  PATH.TEST_RESULT.base,
  PATH.LEVEL_SELECT.base,
  PATH.SUBSCRIPTION.base,
  PATH.TOSS.success,
  PATH.MYPAGE.base,
];

const FOOTER_DATA = [
  {
    id: 1,
    category: [
      {
        title: '사업자번호',
        content: '530-81-01310',
      },
      {
        title: '학원 등록번호',
        content: '제 13227 호',
      },
      {
        title: '통신판매업 신고번호',
        content: '2022-서울강남-06094',
      },
    ],
  },
  {
    id: 2,
    category: [
      {
        title: '대표자명',
        content: '송은우',
      },
      {
        title: '개인 정보 책임자',
        content: '송은우',
      },
      {
        title: '사업장 주소',
        content: '서울특별시 강남구 테헤란로 427, 10층(삼성동)',
      },
      {
        title: '유선 연락처',
        content: '070-4323-4050',
      },
    ],
  },
];
