import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { FingerEditor } from '@wecode-codingbootcamp/finger-editor';
import { useNavigate } from 'react-router-dom';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import CodeDuck from 'Components/core/CodeDuck/CodeDuck';
import { CodeDuckStatus } from 'Components/core/CodeDuck/type';
import SpeechBubble from 'Components/core/SpeechBubble/SpeechBubble';
import {
  CREATE_ASSIGNMENT_REPORT,
  UPDATE_USER_ASSIGNMENT_STATUS,
} from 'gql/course/mutation';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import {
  submittedAnswersVar,
  answerStatusVar,
  customAnswersVar,
} from 'store/Course';
import { PATH } from 'utils/constants/routes';
import {
  getAnswerComparisonResults,
  setFingereditorHeight,
} from 'utils/fingereditor';
import { trim } from 'utils/formats';
import { cn, cond } from 'utils/styles';
import { useLockBodyScroll } from 'utils/hooks';
import { Assignment, CodeInputType, UserLearningData } from 'gql/course/type';
import { GET_USER_LEARNING_DATA } from 'gql/course/query';
import PreviewSection from './PreviewSection';
import EditorController from '../Components/FingerEditor/Component/EditorController/Controller';
import Toast from '../Components/FingerEditor/Component/Toast';
import Content from '../Components/MarkdownContent';
import QnA from './QnA';
import ContentHeader from '../Components/ContentHeader';
import DoneModal from './DoneModal/DoneModal';
import css from './Contents.module.scss';

export interface Props {
  assignment: Assignment;
  contentList: number[];
  refetchContent?: () => void;
}

const Contents = ({ assignment, contentList, refetchContent }: Props) => {
  const {
    id: assignmentId,
    content,
    code,
    codeFilename,
    assignmentCodeChoices,
  } = assignment;
  const editorRef = useRef<HTMLDivElement>(null);
  const bgRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [caret, setCaret] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isQnAOn, setIsQnAOn] = useState(false);
  const answerStatus = useReactiveVar(answerStatusVar);
  const submittedAnswers = useReactiveVar(submittedAnswersVar);
  const { isOpenModal, switchModal } = useSwitchModal();

  const isLastContent = assignmentId === contentList[contentList.length - 1];
  const answers = useMemo(
    () =>
      Object.values(
        assignmentCodeChoices.reduce(
          (acc: { [key: number]: string[] }, curr) => {
            if (curr.isAnswer) {
              if (!acc[curr.sequence]) {
                acc[curr.sequence] = [];
              }
              acc[curr.sequence].push(`${curr.name}`);
            }
            return acc;
          },
          {},
        ),
      ),
    [assignmentId],
  );

  const answerComparisonResults = getAnswerComparisonResults(
    answers?.flatMap(answers => answers) as string[],
    submittedAnswers,
    answerStatus,
  );

  const sequenceOptions = useMemo(
    () =>
      assignmentCodeChoices.filter(({ sequence }) => {
        const lastSequence =
          assignmentCodeChoices[assignmentCodeChoices.length - 1].sequence;

        if (submittedAnswers.length === 0) return sequence === 1;

        if (submittedAnswers.length === lastSequence)
          return sequence === lastSequence;

        return sequence === submittedAnswers.length + 1;
      }),
    [submittedAnswers],
  );

  const type = sequenceOptions[0]?.codeInputType; // blank별로 타입이 정해져 있는 기존의 형태에 맞게 구현 (230227 래영)
  const hasSingleOptionList =
    new Set(assignmentCodeChoices.map(({ name }) => name)).size ===
    sequenceOptions.length;
  const options = hasSingleOptionList
    ? assignmentCodeChoices.filter(({ sequence }) => sequence === 1)
    : sequenceOptions;
  const feedback =
    (answers &&
      answers.length === 1 &&
      options.find(({ name }) => name === submittedAnswers[0])?.feedback) ||
    '';
  const codeDuckStatus = {
    'not submitted': 'default',
    correct: 'happy',
    incorrect: 'sad',
  }[answerStatus] as CodeDuckStatus;
  const bubbleActionType = 'pagination';
  const bubbleHeight = '132px';
  const nextContentId = contentList[contentList.indexOf(assignmentId) + 1];

  const { data } = useQuery<{ userLearningData: UserLearningData }>(
    GET_USER_LEARNING_DATA,
    {
      context: { endpoint: 'course' },
    },
  );

  const [updateUserAssignmentStatus] = useMutation(
    UPDATE_USER_ASSIGNMENT_STATUS,
    {
      context: { endpoint: 'course' },
      onCompleted: refetchContent,
      onError: () => {
        alertVar({
          show: true,
          type: 'error',
          dialog: AlertMessage.common.error.unknown,
          hasHelpEmailInfo: true,
        });
      },
    },
  );

  const [createAssignmentReport] = useMutation(CREATE_ASSIGNMENT_REPORT, {
    context: { endpoint: 'course' },
    variables: {
      assignmentId,
    },
    onCompleted: () => {
      alertVar({
        show: true,
        type: 'info',
        dialog: AlertMessage.course.positive.reportDone,
        onSuccess: submitAnswer,
        onClose: submitAnswer,
      });
    },
  });

  const deleteAllAnswers = () => {
    submittedAnswersVar([]);
    answerStatusVar('not submitted');

    if (type === CodeInputType.BUTTON || answerStatus === 'not submitted') {
      customAnswersVar({});
      return;
    }
  };

  const checkAnswer = (inputAnswers = submittedAnswers) => {
    // back에서 전달해주는 answers가 없는 경우 정답처리한다.
    if (answers === null) return answerStatusVar('correct');

    const isAllSubmitted =
      answers === null || answers.length === inputAnswers.length;
    const isInputEmpty = inputAnswers[0] === ' ';

    if (!isAllSubmitted) return;
    if (submittedAnswers.length === 0 && !isLastContent) return;
    if (isInputEmpty) return;

    if (answerStatus === 'correct') {
      submitAnswer();
      return;
    }

    const isCorrect = answers?.every((answers, idx) =>
      answers.includes(inputAnswers[idx]),
    );

    if (answers?.length === 0) return answerStatusVar('correct');
    else answerStatusVar(isCorrect ? 'correct' : 'incorrect');
  };

  const submitAnswer = () => {
    if (isLastContent) {
      if (data?.userLearningData.hasActiveSubscription) {
        navigate(PATH.CONTENT_RESULT.base, { state: { valid: true } });
      } else {
        switchModal();
      }

      return;
    }

    navigate(`${PATH.CONTENT.base}/${nextContentId}`);
  };

  const handleQnA = () => {
    setIsQnAOn(!isQnAOn);
  };

  const editMode = {
    on: () => setIsEditing(true),
    off: () => setIsEditing(false),
  };

  const setFirstContentRender = () => {
    deleteAllAnswers();
    answerStatusVar('not submitted');
  };

  const setViewPortOnResize = () => {
    const setViewPort = () => {
      setIsMobile(window.innerWidth < 768);
    };

    setViewPort();

    window.addEventListener('resize', setViewPort);

    return () => window.removeEventListener('resize', setViewPort);
  };

  const setGNBDimmedBg = () => {
    if (!bgRef.current) return;

    const gnbHeight = document.getElementsByTagName('header')[0]?.clientHeight;
    const borderHeight = 2;

    bgRef.current.style.height = `${gnbHeight + borderHeight}px`;
  };

  const userIncorrectAnswer = useMemo(
    () =>
      submittedAnswers
        .map(
          (answer, idx) =>
            !answerComparisonResults?.[idx] && {
              sequence: idx + 1,
              incorrectAnswer: answer,
              actualAssignmentAnswer: answers?.[idx],
            },
        )
        .filter(Boolean),
    [answerStatus],
  );

  useEffect(() => {
    if (answerStatus === 'not submitted') return;

    updateUserAssignmentStatus({
      variables: {
        assignmentId,
        scenario: 'CLASS',
        isCorrect: answerStatus === 'correct',
        ...(answerStatus === 'incorrect' && { userIncorrectAnswer }),
      },
    });
  }, [answerStatus]);

  useLockBodyScroll(isQnAOn);
  useEffect(setFirstContentRender, [assignmentId]);
  useEffect(setViewPortOnResize, []);
  // useEffect(setHintCount, []);
  useLayoutEffect(setGNBDimmedBg, [bgRef, isQnAOn]);
  useLayoutEffect(setFingereditorHeight, [submittedAnswers]);
  // useEffect(setScrollOnAnswerStatusChange, [answerStatus, editorRef]);

  return (
    <>
      <div className={css.container}>
        <ContentHeader
          contentTitle="AI와 코딩 학습하기"
          purchased
          report={createAssignmentReport}
        />
        <main className={cn(css.wrapper, css.columnGrid)}>
          <div className={css.playground}>
            <div className={css.contentWrapper}>
              <CodeDuck status={codeDuckStatus} />
              <div className={css.speechBubble}>
                <SpeechBubble
                  height={bubbleHeight}
                  actionType={bubbleActionType}
                  arrow="left"
                >
                  <Content content={content} />
                </SpeechBubble>
              </div>
            </div>
            {code && (
              <>
                <div ref={editorRef} className={css.editorWrapper}>
                  <FingerEditor
                    assignmentCode={code}
                    submittedAnswers={submittedAnswers}
                    filename={codeFilename ?? 'index.html'}
                    className={cn(
                      cond(isEditing, 'editing'),
                      // TODO: 추후에 fingereditor에서 상태 표기할지 말지 정하는 prop 추가되면 삭제 (230420 래영)
                      cond(answerStatus === 'not submitted', 'notSubmitted'),
                    )}
                    showLineNumbers={false}
                    answerComparisonResults={answerComparisonResults}
                  />
                </div>
                <Toast answerStatus={answerStatus} feedback={feedback} />
              </>
            )}
            {sequenceOptions.length > 0 && (
              <EditorController
                type={type}
                code={trim(code)}
                answers={answers}
                editMode={editMode}
                isEditing={isEditing}
                isLastContent={isLastContent}
                hasSingleOptionList={hasSingleOptionList}
                caret={caret}
                options={options}
                isQnAOn={isQnAOn}
                setCaret={setCaret}
                actions={{
                  checkAnswer,
                  submitAnswer,
                  deleteAllAnswers,
                  handleQnA,
                }}
              />
            )}
          </div>
          {!isMobile && <PreviewSection assignmentSource={null} />}
        </main>
      </div>
      {isQnAOn && (
        <>
          <QnA onClose={handleQnA} />
          <div
            className={cn(css.dimmedBackground, css.cover)}
            onClick={handleQnA}
          />
        </>
      )}
      {isOpenModal && <DoneModal where="content" switchModal={switchModal} />}
    </>
  );
};

export default Contents;
