import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from 'Components/core/Button';
import { LabeledInput } from 'Components/core/Forms';
import ImageTypeAlertModal from 'Components/core/ModalPortal/ImageTypeAlertModal/ImageTypeAlert';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import PromptModal from 'Components/core/PromptModal';
import { RuleType } from 'Components/core/Forms/Input/&Label';
import { InputType } from 'Components/core/Forms/Input/&Base';
import { GET_MYPAGE_PROFILE } from 'gql/auth/query';
import { GET_UPLOAD_IMAGE_URL, UPDATE_USER_INFO } from 'gql/auth/mutation';
import { AlertMessage } from 'lib/constant/alertMessage';
import { Validations } from 'lib/constant/validation';
import { alertVar } from 'store/Alert';
import { PATH } from 'utils/constants/routes';
import { checkComparedObject } from 'utils/validation/checkComparedObject';
import { useInputChangeValue } from './EditProfile.hook';
import { MyPageProfileType } from './type';
import Smile from 'assets/icon/svg/xl/smile.svg';
import css from './EditProfile.module.scss';

function EditProfile() {
  const [imagePreview, setImagePreview] = useState<string>('');
  const [initUserData, setInitUserData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });
  const [s3File, setS3File] = useState<File | undefined>(undefined);

  const { handleChange, formData, setFormData } = useInputChangeValue();
  const { isOpenModal, switchModal } = useSwitchModal();

  const navigate = useNavigate();

  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const HYPHEN_REG_PATTERN = /^(\d{2,3})(\d{3,4})(\d{4})$/;

  const { data, loading, refetch } = useQuery<MyPageProfileType>(
    GET_MYPAGE_PROFILE,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted(data) {
        setFormData({
          name: data?.userProfile.name ?? '',
          email: data?.userProfile.email ?? '',
          phoneNumber: data?.userProfile.phoneNumber?.replaceAll('-', '') ?? '',
          profileImage: data?.userProfile.profileImage || Smile,
        });
        setInitUserData({
          name: data?.userProfile.name ?? '',
          email: data?.userProfile.email ?? '',
          phoneNumber: data?.userProfile.phoneNumber?.replaceAll('-', '') ?? '',
        });
      },
      onError: () => {
        alertVar({
          show: true,
          type: 'error',
          dialog: AlertMessage.common.error.unknown,
          hasHelpEmailInfo: true,
        });
      },
    },
  );

  const [getImageUrl] = useMutation(GET_UPLOAD_IMAGE_URL, {
    context: { endpoint: 'auth' },
    onCompleted(data) {
      if (s3File) uploadImageToS3(data.updateProfileImage, s3File);
    },
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  const [editProfile] = useMutation(UPDATE_USER_INFO, {
    context: { endpoint: 'auth' },
    onCompleted: () => {
      alertVar({
        show: true,
        type: 'positive',
        dialog: AlertMessage.auth.positive.edit,
      });
      refetch();
    },
    onError(error) {
      if (error) {
        alertVar({
          show: true,
          type: 'error',
          dialog: AlertMessage.auth.error.retry,
        });
        navigate(PATH.DEPRECATED_MYPAGE.editProfile);
      }
    },
  });

  const submitNewProfileData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isAllPassed) {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.info.required,
      });
      return;
    }

    if (isAllPassed) {
      alertVar({
        show: true,
        dialog: AlertMessage.auth.info.saveUserInfo,
        hasCancelButton: true,
        onSuccess: () => {
          editProfile({
            variables: {
              updateUserInput: {
                name: formData.name,
                email: formData.email,
                phoneNumber: formData.phoneNumber.replace(
                  HYPHEN_REG_PATTERN,
                  '$1-$2-$3',
                ),
              },
            },
          });
        },
      });
    }
  };

  const isAllPassed = INPUT_DATA.every(({ name, rules }) =>
    rules.every(ruleType => Validations[ruleType].rule(formData[name])),
  );

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    fileRef.current.click();
  };

  const uploadImageToS3 = async (presignedUrl: string, file: File) => {
    try {
      const result = await axios.put(presignedUrl, file, {
        headers: { 'Content-Type': file.type },
      });
      if (result.status === 200) {
        alertVar({
          show: true,
          type: 'positive',
          dialog: AlertMessage.auth.positive.edit,
        });
        refetch();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        setImagePreview('');
        alertVar({
          show: true,
          type: 'error',
          dialog: AlertMessage.auth.error.retry,
        });
      }
    }
  };

  const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const file = e.target.files as FileList;

    if (!file[0].type.includes('image')) {
      switchModal();
      return;
    }

    const fileArray = Array.from(file);
    const selectedFiles: string[] = fileArray.map(file => {
      return URL.createObjectURL(file);
    });

    setImagePreview(selectedFiles?.[0]);
    setS3File(file[0]);
    getImageUrl();
  };

  const handleErrorImg = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = Smile;
  };

  const isMatch = checkComparedObject(initUserData, formData);

  if (loading || !data) return null;

  return (
    <form className={css.wrap} onSubmit={submitNewProfileData}>
      <div className={css.container}>
        <div className={css.profileImageWrapper}>
          <div className={css.imageBox}>
            <img
              className={css.profileImage}
              src={imagePreview || formData.profileImage}
              alt="profile"
              onError={handleErrorImg}
            />
          </div>
          <p className={css.koreanName}>{formData.name}</p>
          <button className={css.uploadBtn} onClick={handleClick} type="button">
            사진 업로드
          </button>
          <input
            type="file"
            ref={fileRef}
            className={css.file}
            onChange={handleChangeFiles}
          />
          <p className={css.explain}>
            JPG 혹은 PNG 이미지로
            <br />
            320*320 이상 사진을 사용해주세요.
          </p>
        </div>
        <div className={css.inputWrapper}>
          {INPUT_DATA.map(inputList => {
            const { id, label, placeholder, name, type, rules, disabled } =
              inputList;
            return (
              <LabeledInput
                className={css.input}
                key={id}
                name={name}
                // autoFocus={name === 'name'}
                value={formData[name]}
                label={label}
                type={type}
                onChange={handleChange}
                placeholder={placeholder}
                rules={rules}
                disabled={disabled}
              />
            );
          })}
        </div>
      </div>
      <div className={css.btnWrapper}>
        <Button size="large" variant="primary" type="submit">
          저장
        </Button>
      </div>
      <PromptModal when={!isMatch} />
      {isOpenModal && <ImageTypeAlertModal switchModal={switchModal} />}
    </form>
  );
}

export default EditProfile;

export interface InputData {
  id: number;
  label: string;
  placeholder: string;
  name: string;
  type: InputType;
  rules: RuleType[];
  disabled?: boolean;
}

const INPUT_DATA: InputData[] = [
  {
    id: 1,
    label: '이름*',
    placeholder: '이름을 입력해주세요',
    name: 'name',
    type: 'text',
    rules: ['name'],
  },
  {
    id: 2,
    label: '휴대전화 번호*',
    placeholder: '하이픈,‘-’없이 숫자만 입력해주세요',
    name: 'phoneNumber',
    type: 'number',
    rules: ['phoneNumber'],
    disabled: true,
  },
  {
    id: 3,
    label: 'email*',
    placeholder: '이메일을 입력해주세요',
    name: 'email',
    type: 'email',
    rules: ['email'],
  },
];
