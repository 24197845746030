import { ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { tokenVar } from 'store/Auth';
import { loadingVar } from 'store/Loading';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';

// TODO: staging, dev 서버 분리를 위해 npm script와 함께 수정, staging에서 반영 왜 안되는지 확인 필요 (221126 래영)
// const { REACT_APP_ENV } = process.env;

// const URI: { [key in string]: string } = {
//   production: 'https://api.wecode.co.kr',
//   staging: 'https://staging.api.wecode.co.kr',
//   dev: 'https://dev.api.wecode.co.kr',
//   local: 'http://localhost:8001/graphql',
// };

// const uri = URI[REACT_APP_ENV as string];

export const loadingLink = new ApolloLink((operation, forward) => {
  loadingVar(true);

  return forward(operation).map(data => {
    loadingVar(false);
    return data;
  });
});

export const loadingErrorLink = onError(() => {
  loadingVar(false);
});

export const authLink = new ApolloLink((operation, forward) => {
  const token = tokenVar();

  operation.setContext({
    headers: {
      Authorization: (token || localStorage.getItem(TOKEN)) ?? '',
      ...operation.getContext().headers,
    },
  });
  return forward(operation);
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (Array.isArray(graphQLErrors))
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      const isException = ERROR_EXCEPTIONS.includes(path[0]);
      const code = extensions?.code as string;
      const notAuthorized =
        code === 'NOT_AUTHORIZED' ||
        code === 'NOT AUTHORIZED' ||
        message === 'NOT_AUTHORIZED' ||
        message === 'NOT AUTHORIZED';
      const shouldLogin = notAuthorized && !isException;

      if (shouldLogin) {
        alertVar({
          show: true,
          type: 'info',
          dialog: AlertMessage.common.warn.needLogin,
          onClose: () => {
            sessionStorage.setItem('current-location', location.pathname);

            location.pathname = PATH.LOGIN.base;
          },
        });
      }

      // TODO
      // 1. 모든 요청 onError에서 처리
      // 2. 혹은 백엔드와 error code 맵핑한 상수 데이터에 code가 포함돼있다면 message 출력, 그렇지 않다면 뜻밖의 에러 출력 (230724 래영)

      // TODO: 각 요청 시 response message를 받아와서 매핑해 에러 처리하도록 수정 (230712 래영)
      // else if (!isException) {
      //   alertVar({
      //     show: true,
      //     type: 'error',
      //     dialog: AlertMessage.common.error.unknown,
      //     hasHelpEmailInfo: true,
      //   });
      // }

      console.warn(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
  }
});

export const httpLink = new ApolloLink((operation, forward) => {
  const { endpoint = 'auth' } = operation.getContext();

  operation.setContext({
    uri: `${process.env.REACT_APP_API_ENDPOINT}/${endpoint}`,
  });

  return forward(operation);
}).concat(new HttpLink());

const ERROR_EXCEPTIONS = ['userProfile'];
