import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'Components/core/Button';
import { Product } from 'Pages/CurriculumRouter/CurriculumDetail/type';
import { CouponType, CustomerKey } from 'gql/payment/type';
import { PATH } from 'utils/constants/routes';
import { STUDENT_COURSES, Target } from 'utils/constants/temp';
import { getPurchaseAudience } from 'utils/getPurchaseAudience';
import {
  CREATE_PAYMENT,
  CREATE_INSTANT_PURCHASE,
  CREATE_ORDER_GROUP,
} from 'gql/payment/mutation';
import { APPLY_COUPON, GET_CUSTOMER_KEY } from 'gql/payment/query';
import useTossPayment from 'utils/hooks/useTossPayment';
import useTossBilling from 'utils/hooks/useTossBilling';
import {
  PaymentMethod,
  PurchaseList,
} from '../../../Components/core/PaymentModal/type';
import css from './TossFail.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { dataLayer, kakaoPixel }: any = window;

const TossFail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isCouponValid, setIsCouponValid] = useState(false);
  const getPayment = useTossPayment();
  const getBilling = useTossBilling();
  const type = searchParams.get('type');
  const message = searchParams.get('message');
  const purchaseList: PurchaseList[] = JSON.parse(
    sessionStorage.getItem('purchaseList') || '[]',
  );
  const formData = JSON.parse(sessionStorage.getItem('formData') || '{}');
  const payMethod = `${sessionStorage.getItem('payMethod')}`;

  const isBilling = type === 'billing';

  if (!isBilling && (!purchaseList || !formData || !payMethod)) {
    return <Navigate to={PATH.HOME.base} />;
  }

  const [getCustomerKey] = useLazyQuery<{ customerKey: CustomerKey }>(
    GET_CUSTOMER_KEY,
    {
      context: { endpoint: 'payment' },
    },
  );

  const [applyCoupon, { data: couponData }] = useLazyQuery<{
    coupon: CouponType;
  }>(APPLY_COUPON, {
    context: { endpoint: 'payment' },
    onCompleted: () => setIsCouponValid(true),
  });

  const [createPayment] = useMutation(CREATE_PAYMENT, {
    context: { endpoint: 'payment' },
    onCompleted: ({ createPayment: { orderId, amount } }) => {
      dataLayer.push({
        event: 'Purchase',
        price: 0,
        productId: purchaseList[0].productId,
        productName: title,
        itemsCount: purchaseList.length,
      });

      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currency: 'KRW',
          value: amount.toLocaleString(),
          transaction_id: orderId,
          coupon: formData.coupon ? 'yes' : 'no',
          quantity: purchaseList.length,
          items: purchaseList.map(({ productId, name, price }) => {
            const target = STUDENT_COURSES.includes(productId)
              ? Target.student
              : Target.adult;

            return {
              item_name: name,
              item_id: productId,
              price,
              item_brand: 'justcode',
              target_audience: target,
            };
          }),
        },
      });

      kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_ID).purchase({
        total_quantity: purchaseList.length,
        total_price: isCouponApplicable
          ? couponData.coupon.discountedPrice.toLocaleString()
          : totalPrice.toLocaleString(),
        products: purchaseList.map(({ productId, name, price }) => ({
          id: productId,
          name,
          quantity: '1',
          price,
        })),
      });

      sessionStorage.removeItem('purchaseList');
      sessionStorage.removeItem('formData');
      sessionStorage.removeItem('payMethod');

      navigate(`${PATH.TOSS.success}?target_audience=${audience}&type=payment`);
    },
  });

  const [createInstantPurchase] = useMutation(CREATE_INSTANT_PURCHASE, {
    context: {
      endpoint: 'payment',
    },
    onCompleted: ({ createInstantPurchase: { id } }) => {
      if (isZeroPrice) {
        createPayment({
          variables: {
            paymentId: id,
            orderId: id,
            amount: 0,
            orderName: title,
            paymentMethod: PaymentMethod.FREE,
            ...(isCouponApplicable && { couponCode: formData.coupon }),
          },
        });
      } else {
        getPayment({
          name: formData.name || 'justcoder',
          title,
          price: isCouponApplicable
            ? couponData.coupon.discountedPrice
            : totalPrice,
          audience,
          payMethod,
          orderId: id,
          ...(isCouponApplicable && { couponCode: formData.coupon }),
        });
      }
    },
  });

  const [createOrderGroup] = useMutation(CREATE_ORDER_GROUP, {
    context: { endpoint: 'payment' },
    variables: {
      orderIds: purchaseList.map(({ id }) => id),
    },
    onCompleted: ({ createOrderGroup: { id } }) => {
      if (isZeroPrice) {
        createPayment({
          variables: {
            paymentId: id,
            orderId: id,
            amount: 0,
            orderName: title,
            paymentMethod: PaymentMethod.FREE,
            ...(isCouponApplicable && { couponCode: formData.coupon }),
          },
        });
      } else {
        getPayment({
          name: formData.name || 'justcoder',
          title,
          price: isCouponApplicable
            ? couponData.coupon.discountedPrice
            : totalPrice,
          audience,
          payMethod,
          orderId: id,
          ...(isCouponApplicable && { couponCode: formData.coupon }),
        });
      }
    },
  });

  const handlePayment = () => {
    if (purchaseList.length === 1) {
      const { productId, name, price } = purchaseList[0];
      createInstantPurchase({
        variables: {
          name,
          price,
          product: Product.COURSE,
          productId,
        },
      });
    } else {
      createOrderGroup();
    }
  };

  const handleBilling = () => {
    getCustomerKey({
      onCompleted: ({ customerKey: { customerKey } }) =>
        getBilling({ customerKey }),
    });
  };

  const firstProductTitle = purchaseList[0]?.name;
  const title =
    purchaseList.length > 1
      ? `${firstProductTitle} 외 ${purchaseList.length - 1}개 강의`
      : firstProductTitle;
  const totalPrice = purchaseList.reduce((acc, { price }) => acc + price, 0);
  const isCouponApplicable = !!formData.coupon && isCouponValid && !!couponData;
  const isZeroPrice =
    !totalPrice ||
    (isCouponApplicable && couponData.coupon.discountedPrice === 0);
  const audience = getPurchaseAudience(purchaseList);

  useEffect(() => {
    if (!formData.coupon) return;

    applyCoupon({
      variables: {
        originalPrice: totalPrice,
        code: formData.coupon,
      },
    });
  }, [totalPrice]);

  useEffect(() => {
    if (!message) return;

    alert(message);
  }, []);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('purchaseList');
      sessionStorage.removeItem('formData');
      sessionStorage.removeItem('payMethod');
    };
  }, []);

  return (
    <div className={css.container}>
      <h1 className={css.title}>구매하기</h1>
      <div className={css.wrapper}>
        <p className={css.header}>결제가 취소되었습니다.</p>
        <p className={css.description}>
          구매하시려면 다시 결제를 진행해 주세요.
        </p>
      </div>
      <div className={css.btnWrapper}>
        <Button
          variant="lineBase"
          size="large"
          onClick={isBilling ? handleBilling : handlePayment}
        >
          다시 시도하기
        </Button>
      </div>
    </div>
  );
};

export default TossFail;
