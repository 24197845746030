import React from 'react';
import { AnswerStatusType } from 'store/Course';
import { cn } from 'utils/styles';
import { getToastContent } from './util';
import css from './Toast.module.scss';

export type ToastType = 'resultedContent' | 'feedback' | 'quiz';

interface Props {
  answerStatus: AnswerStatusType;
  feedback?: string;
  variant?: ToastType;
}

const Toast = ({ answerStatus, feedback, variant = 'quiz' }: Props) => {
  if (answerStatus === 'not submitted') return null;

  const { title, description } = getToastContent(
    variant,
    answerStatus,
    feedback,
  );

  return (
    <div className={cn(css.container, css[answerStatus])}>
      <p className={css.title}>{title}</p>
      <p className={css.description}>{description}</p>
    </div>
  );
};

export default Toast;
