import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Preview } from '@wecode-codingbootcamp/finger-editor';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'Components/core/Button';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import ShareModal from 'Components/core/ShareModal';
import { ShareData } from 'Components/core/ShareModal/type';
import { GET_SHARE_DATA, GET_USER_APP } from 'gql/course/query';
import { CREATE_USER_APP_PERMISSION } from 'gql/course/mutation';
import { UserAppPermissionType, UserAppType } from 'gql/course/type';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { PATH } from 'utils/constants/routes';
import { getPreviewFile } from 'utils/fingereditor';
import css from './Result.module.scss';

const Result = () => {
  const navigate = useNavigate();
  const { appId, sharingCode } = useParams() as {
    appId: string;
    sharingCode: string;
  };
  const headerRef = useRef<HTMLDivElement>(null);
  const [previewHeight, setPreviewHeight] = useState('');
  const { isOpenModal, switchModal } = useSwitchModal();

  const { loading, data, error } = useQuery<{ userApp: UserAppType }>(
    GET_USER_APP,
    {
      variables: { appId },
      context: { endpoint: 'course' },
      onCompleted: ({ userApp: { courseId } }) =>
        getShareData({ variables: { id: courseId } }),
      skip: !appId,
    },
  );

  const [createUserAppPermission, { error: permissionError }] = useMutation<{
    createUserAppPermission: UserAppPermissionType;
  }>(CREATE_USER_APP_PERMISSION, {
    context: { endpoint: 'course' },
    variables: {
      sharingCode,
    },
    onCompleted: ({ createUserAppPermission: { appId } }) =>
      navigate(`${PATH.APP.base}/${appId}`),
  });

  const [getShareData, { data: shareData }] = useLazyQuery<{
    course: ShareData;
  }>(GET_SHARE_DATA, {
    context: { endpoint: 'course' },
  });

  useLayoutEffect(() => {
    if (!headerRef || !headerRef.current) return;

    const resizePreviewHeight = () => {
      const gnbHeight =
        document.getElementsByTagName('header')[0]?.clientHeight;
      const footerHeight =
        document.getElementsByTagName('footer')[
          document.getElementsByTagName('footer').length - 1
        ]?.clientHeight;
      const headerHeight = headerRef.current?.clientHeight;
      const borderHeight = 2;
      setPreviewHeight(
        `calc(100vh - ${gnbHeight}px - ${footerHeight}px - ${headerHeight}px - ${borderHeight}px)`,
      );
    };

    resizePreviewHeight();

    window.addEventListener('resize', resizePreviewHeight);

    return () => {
      window.removeEventListener('resize', resizePreviewHeight);
    };
  }, [headerRef.current, data]);

  useEffect(() => {
    if (appId) return;

    createUserAppPermission();
  }, []);

  useEffect(() => {
    if (shouldLogin) {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.warn.needLogin,
        onClose: () => {
          sessionStorage.setItem('current-location', location.pathname);
          navigate(PATH.LOGIN.base);
        },
      });

      return;
    }

    if (hasNoPermission) {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.course.error.notAuthorized,
        onClose: () => navigate(PATH.HOME.base),
      });

      return;
    }
  }, [error, permissionError]);

  const shouldLogin =
    error?.message === 'LOGIN_REQUIRED' ||
    permissionError?.message === 'NOT_AUTHORIZED';
  const hasNoPermission = error?.message === 'NOT_AUTHORIZED';
  const hasAccess = !loading && data && !shouldLogin && !hasNoPermission;

  if (!hasAccess || !appId) return <></>;

  const { courseId, userData, assignmentSource, permission, isOwner } =
    data.userApp;

  const previewFile = getPreviewFile(assignmentSource, userData);

  return (
    <>
      <div className={css.resultHeader} ref={headerRef}>
        <span className={css.title}>{shareData?.course.title}</span>
        {isOwner && <Button onClick={switchModal}>공유하기</Button>}
      </div>
      <Preview
        previewFile={previewFile}
        isCorrectAnswer
        style={{ height: previewHeight }}
        scroll
      />
      {isOpenModal && shareData?.course && (
        <ShareModal
          courseId={courseId}
          appId={appId}
          userPermission={permission}
          shareData={shareData.course}
          onClose={switchModal}
        />
      )}
    </>
  );
};

export default Result;
