import React from 'react';
import CourseSurvey from 'Components/core/ModalPortal/CourseSurvey/CourseSurvey';
import Button from 'Components/core/Button';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';

const TestPage = () => {
  const { switchModal, isOpenModal } = useSwitchModal();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 40,
      }}
    >
      <Button onClick={switchModal}>서베이 열림</Button>
      {isOpenModal && <CourseSurvey switchModal={switchModal} />}
    </div>
  );
};

export default TestPage;
