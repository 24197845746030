import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Preview } from '@wecode-codingbootcamp/finger-editor';
import Icon, { IconName } from 'Components/core/Icon/Icon';
import { AssignmentSource } from 'Pages/CurriculumRouter/Session/type';
import { answerStatusVar } from 'store/Course';
import { getPreviewFile } from 'utils/fingereditor';
import { cn, cond } from 'utils/styles';
import css from './PreviewSection.module.scss';

interface Props {
  isSample?: boolean;
  isMobile?: boolean;
  isQuiz?: boolean;
  userData?: Record<string, string> | null;
  assignmentSource: AssignmentSource | null;
}

const PreviewSection = ({
  isSample = false,
  isMobile = false,
  isQuiz = true,
  userData,
  assignmentSource,
}: Props) => {
  assignmentSource = assignmentSource ?? {};
  userData = userData ?? {};

  const answerStatus = useReactiveVar(answerStatusVar);
  const previewFile: Record<string, string> = getPreviewFile(
    assignmentSource,
    userData,
  );
  const PREVIEW_STATUS_MAP = {
    'not submitted': {
      title: isQuiz ? '퀴즈를 풀어보세요!' : '코드를 선택해주세요!',
      description: isQuiz
        ? '정답은 몇번일까요?'
        : '어떤 코드가 들어가야 할까요?',
      icon: 'watingAnswer',
    },
    incorrect: {
      title: isQuiz ? '이런! 정답이 아니에요.' : '결과를 확인할 수 없어요.',
      description: isQuiz
        ? '조금만 더 생각해볼까요?'
        : '코드를 다시 확인해주세요.',
      icon: 'wrongAnswer',
    },
    correct: {
      title: '정답입니다!',
      description: '다음 버튼을 눌러 계속 진행해 볼까요?',
      icon: 'correctAnswer',
    },
  };

  // useEffect(() => {
  //   const $iframe = document.querySelector('iframe');
  //   if (!$iframe) return;
  //   const iframeDoc = $iframe.contentWindow?.document;

  //   const iframeContentHeight = iframeDoc?.body?.scrollHeight ?? 520;
  //   $iframe.style.height = iframeContentHeight + 'px';
  // }, [assignmentSource]);

  if (answerStatus === 'not submitted' && previewFile['preview.txt']) {
    return (
      <div className={css.previewGif}>
        <div className={css.imgWrapper}>
          <img src={previewFile['preview.txt']} alt="시연 영상" />
        </div>
      </div>
    );
  }

  if (
    answerStatus === 'incorrect' ||
    (answerStatus === 'not submitted' &&
      Object.keys(previewFile).length === 0) ||
    isQuiz
  ) {
    return (
      <div
        className={cn(
          css.preview,
          cond(isSample, css.sample),
          cond(isMobile, css.mobile),
        )}
      >
        <div className={css.messageWrapper}>
          <Icon name={PREVIEW_STATUS_MAP[answerStatus].icon as IconName} />
          <h2>{PREVIEW_STATUS_MAP[answerStatus].title}</h2>
          <p>{PREVIEW_STATUS_MAP[answerStatus].description}</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(
        css.preview,
        cond(isSample, css.sample),
        cond(isMobile, css.mobile),
      )}
    >
      <Preview
        isCorrectAnswer={answerStatus === 'correct'}
        scroll
        previewFile={previewFile}
        style={{ borderRadius: 8, minHeight: '50vh' }}
      />
    </div>
  );
};

export default PreviewSection;
