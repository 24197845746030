import React from 'react';
import Button from 'Components/core/Button';
import { Order } from 'gql/payment/type';
import EmptyList from './EmptyList';
import Product from './Product';
import css from './CartList.module.scss';

interface Props {
  list: Order[];
  checkedList: { id: number; price: number }[];
  handleCheck: (
    id: number,
    productId: number,
    price: number,
    name: string,
  ) => void;
  handleAllCheck: () => void;
  handleDelete: (orderId?: number) => void;
  handleAllDelete: () => void;
}

function CartList({
  list,
  checkedList,
  handleCheck,
  handleAllCheck,
  handleDelete,
}: Props) {
  const isEmpty = list.length === 0;
  const isAllChecked =
    checkedList.length !== 0 && checkedList.length === list.length;

  const BTN_LIST = [
    // { id: 0, text: '전체 선택', handler: handleAllCheck },
    { id: 1, text: '선택 삭제', handler: handleDelete },
    // { id: 2, text: '전체 삭제', handler: handleAllDelete },
  ];

  return (
    <div className={css.container}>
      {isEmpty ? (
        <EmptyList />
      ) : (
        <>
          <div className={css.listHeader}>
            <div className={css.selectWrap}>
              <input
                type="checkbox"
                className={css.checkbox}
                checked={isAllChecked}
                onChange={handleAllCheck}
              />
              <div className={css.btnWrapper}>
                {BTN_LIST.map(({ id, text, handler }) => (
                  // event 객체 전달되면 안됨 - 수정 금지
                  <Button key={id} variant="lineGrey" onClick={() => handler()}>
                    {text}
                  </Button>
                ))}
              </div>
            </div>
            <span className={css.count}>
              선택한 강의 {checkedList.length}개
            </span>
          </div>
          {list.map(product => (
            <Product
              key={product.id}
              data={product}
              isChecked={checkedList.some(({ id }) => id === product.id)}
              handleDelete={() => handleDelete(product.id)}
              handleCheck={handleCheck}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default CartList;
