import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { hydrate } from 'react-dom';
import { createRoot } from 'react-dom/client';
import OpenGraph from 'Components/core/Helmet/OpenGraph';
import client from 'gql/settings';
import Router from 'Router';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'styles/reset.scss';
import 'styles/common.scss';
import 'styles/variables/animation.scss';
import 'styles/markdown.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { Kakao }: any = window;
Kakao?.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);

const rootElement = document.getElementById('root');
const app = (
  <ApolloProvider client={client}>
    <OpenGraph />
    <Router />
  </ApolloProvider>
);

serviceWorkerRegistration.unregister();

if (!rootElement) throw new Error('rootElement is null or undefined');

if (rootElement?.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  createRoot(rootElement).render(app);
}
