import Slide1_lg from 'assets/page/classPage/slide_18.png';
import Slide1_sm from 'assets/page/classPage/slide_18_mobile@2x.png';
import Slide1_md from 'assets/page/classPage/slide_18_tablet@2x.png';
import Slide2_lg from 'assets/page/classPage/slide_31.png';
import Slide2_sm from 'assets/page/classPage/slide_31_mobile@2x.png';
import Slide2_md from 'assets/page/classPage/slide_31_tablet@2x.png';
import Slide3_lg from 'assets/page/classPage/slide_32.png';
import Slide3_sm from 'assets/page/classPage/slide_32_mobile@2x.png';
import Slide3_md from 'assets/page/classPage/slide_32_tablet@2x.png';

export const SLIDE_DATA = [
  {
    id: 110,
    lg: Slide1_lg,
    md: Slide1_md,
    sm: Slide1_sm,
    name: '지금 딱 원하는 정보만 슬라이드 스크랩북',
  },
  {
    id: 130,
    lg: Slide2_lg,
    md: Slide2_md,
    sm: Slide2_sm,
    name: '웹 사이트 만들기 기초',
  },
  {
    id: 131,
    lg: Slide3_lg,
    md: Slide3_md,
    sm: Slide3_sm,
    name: '웹 사이트 꾸미기 기초',
  },
];
