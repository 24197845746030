import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import ErrorPage from 'Components/core/ErrorPage';
import { usePromiseAlert } from 'Components/core/ModalPortal/usePromiseAlert';
import { CartType } from 'gql/payment/type';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import { DELETE_CART, DELETE_ALL_CART } from 'gql/payment/mutation';
import { GET_CART, GET_TOTAL_CART } from 'gql/payment/query';
import CartList from './CartList';
import PaymentBox from './PaymentBox';
import css from './Cart.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { kakaoPixel }: any = window;

function Cart() {
  const navigate = useNavigate();
  const showModal = usePromiseAlert({
    type: 'warn',
    dialog: AlertMessage.common.error.nonAuthorizationPage,
  });

  useEffect(() => {
    kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_ID).viewCart();

    if (!localStorage.getItem(TOKEN)) {
      showModal().then(() => {
        sessionStorage.setItem('current-location', location.pathname);
        navigate(PATH.LOGIN.base);
      });
    }
  }, []);

  if (!localStorage.getItem(TOKEN)) {
    return <></>;
  }

  const [isMounted, setIsMounted] = useState(false);
  const [checkedList, setCheckedList] = useState<
    { id: number; productId: number; price: number; name: string }[]
  >([]);

  const { loading, error, data, refetch } = useQuery<CartType>(GET_CART, {
    context: { endpoint: 'payment' },
    fetchPolicy: 'network-only',
    onCompleted: ({ myCart }) => {
      if (isMounted) return;

      setIsMounted(true);
      setCheckedList(
        myCart.orders.map(({ id, productId, price, name }) => ({
          id,
          productId,
          price,
          name,
        })),
      );
    },
  });

  const [deleteCart] = useMutation(DELETE_CART, {
    context: { endpoint: 'payment' },
    onCompleted: refetch,
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
    refetchQueries: [
      {
        query: GET_TOTAL_CART,
        context: { endpoint: 'payment' },
      },
      'totalCartsCounts',
    ],
  });

  const [deleteAllCart] = useMutation(DELETE_ALL_CART, {
    variables: {
      cartId: data?.myCart.id,
    },
    context: { endpoint: 'payment' },
    onCompleted: refetch,
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
    refetchQueries: [
      {
        query: GET_TOTAL_CART,
        context: { endpoint: 'payment' },
      },
      'totalCartsCounts',
    ],
  });

  useEffect(() => {
    setCheckedList(
      checkedList.filter(list =>
        data?.myCart.orders.some(order => order.id === list.id),
      ),
    );
  }, [data]);

  const handleCheck = (
    id: number,
    productId: number,
    price: number,
    name: string,
  ) => {
    if (checkedList.find(product => product.id === id)) {
      setCheckedList(checkedList.filter(product => product.id !== id));
    } else {
      setCheckedList(checkedList.concat({ id, productId, price, name }));
    }
  };

  const handleAllCheck = () => {
    const isAllChecked =
      checkedList.length !== 0 && checkedList.length === orders.length;

    if (isAllChecked) {
      setCheckedList([]);
    } else {
      setCheckedList(
        orders.map(({ id, productId, price, name }) => ({
          id,
          productId,
          price,
          name,
        })),
      );
    }
  };

  const handleDelete = (orderId?: number) => {
    if (checkedList.length === 0) return;

    alertVar({
      show: true,
      hasCancelButton: true,
      type: 'warn',
      dialog: AlertMessage.cart.warn.delete,
      onSuccess: () => {
        deleteCart({
          variables: {
            orderIds: orderId ? [orderId] : checkedList.map(({ id }) => id),
            cartId: data?.myCart.id,
          },
        });
      },
    });
  };

  const handleAllDelete = () => {
    alertVar({
      show: true,
      hasCancelButton: true,
      type: 'warn',
      dialog: AlertMessage.cart.warn.deleteAll,
      onSuccess: deleteAllCart,
    });
  };

  if (error) return <ErrorPage />;
  if (loading || !data) return null;

  const { orders } = data.myCart;
  const isEmpty = orders.length === 0;
  const totalPrice = checkedList.reduce((acc, cur) => acc + cur.price, 0);
  const selectedCount = checkedList.length;

  return (
    <div className={css.container}>
      <h1 className={css.title}>장바구니</h1>
      <div className={css.wrapper}>
        <CartList
          list={orders}
          checkedList={checkedList}
          handleDelete={handleDelete}
          handleAllDelete={handleAllDelete}
          handleCheck={handleCheck}
          handleAllCheck={handleAllCheck}
        />
        <PaymentBox
          purchaseList={checkedList}
          totalPrice={totalPrice}
          isEmpty={isEmpty}
          selectedCount={selectedCount}
        />
      </div>
    </div>
  );
}

export default Cart;
