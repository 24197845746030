import React from 'react';
import { useReactiveVar } from '@apollo/client';
import Icon from 'Components/core/Icon/Icon';
import CourseSurvey from 'Components/core/ModalPortal/CourseSurvey/CourseSurvey';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import { AssignmentChoice } from 'Pages/CurriculumRouter/DeprecatedSession/type';
import ProgressBar from 'Pages/CurriculumRouter/Components/ProgressBar';
import { answerStatusVar, customAnswerVar } from 'store/Course';
import { TOKEN } from 'utils/constants';
import { cn, cond } from 'utils/styles';
import { ContentsProps } from '../DeprecatedContents';
import css from './Controller&.module.scss';

interface Props extends Pick<ContentsProps, 'courseData' | 'courseContent'> {
  isEditing: boolean;
  hasEditor: boolean;
  isSample?: boolean;
  isQuiz: boolean;
  isLastContent: boolean;
  assignmentChoices: AssignmentChoice[];
  actions: {
    deleteAnswer: () => void;
    goToPrevPage: () => void;
    goToFirstContent: () => void;
    deleteAllAnswers: () => void;
    submitAnswer: () => void;
  };
}

const ContentsController = ({
  courseData,
  courseContent,
  assignmentChoices,
  isLastContent,
  isEditing,
  isQuiz,
  isSample = false,
  hasEditor,
  actions,
}: Props) => {
  const hasChoice = assignmentChoices[0].id !== null;
  const { progress, evaluated } = courseData;
  const { sequence } = courseContent;
  const { switchModal, isOpenModal: isOpenSurvey } = useSwitchModal();
  const { deleteAllAnswers, deleteAnswer, goToFirstContent, submitAnswer } =
    actions;
  const answerStatus = useReactiveVar(answerStatusVar);
  const customAnswer = useReactiveVar(customAnswerVar);
  // const canGoPrev = sequence !== 1 && !isEditing;
  const canGoFirst = sequence !== 1 && !isEditing;
  const canRedo = (hasEditor && !isEditing) || isQuiz;
  const canDelete = (hasEditor && !isEditing) || isQuiz;
  const canGoNext =
    ((answerStatus === 'correct' || !!customAnswer) && !isEditing) ||
    !hasChoice;
  const isLoggedIn = !!localStorage.getItem(TOKEN);

  const onClickNextBtn = () => {
    if (isLastContent && !evaluated) {
      switchModal();
    } else if (canGoNext) {
      submitAnswer();
    }
  };

  return (
    <div className={css.btnWrapper}>
      {isLoggedIn && (
        <div className={cn(css.progressWrapper, cond(isSample, css.sample))}>
          <ProgressBar
            variant="thick"
            progress={progress}
            width="300px"
            hasPercentage
          />
        </div>
      )}
      <div className={cn(css.iconGroup, cond(isSample, css.sample))}>
        {/* 변경된 기획에 따라 이전 버튼 임시 제거 (230414 래영) */}
        {/* <div
          className={cn(css.iconWrapper, cond(!canGoPrev, css.disabled))}
          onClick={canGoPrev ? goToPrevPage : undefined}
        >
          <Icon name="backGrey" />
          <p>이전</p>
        </div> */}
        <div
          className={cn(css.iconWrapper, cond(!canGoFirst, css.disabled))}
          onClick={canGoFirst ? goToFirstContent : undefined}
        >
          <Icon name="homeIcon" />
          <p>첫 단계로</p>
        </div>
        <div
          className={cn(css.iconWrapper, cond(!canRedo, css.disabled))}
          onClick={canRedo ? deleteAllAnswers : undefined}
        >
          <Icon name="refresh" />
          <p>다시 선택</p>
        </div>
        <div
          className={cn(css.iconWrapper, cond(!canDelete, css.disabled))}
          onClick={canDelete ? deleteAnswer : undefined}
        >
          <Icon name="delPhoneGrey" />
          <p>지우기</p>
        </div>
        <div
          className={cn(css.iconWrapper, cond(!canGoNext, css.disabled))}
          onClick={canGoNext ? onClickNextBtn : undefined}
        >
          <Icon name="nextGrey" />
          <p>다음</p>
        </div>
      </div>
      {isOpenSurvey && !evaluated && (
        <CourseSurvey switchModal={switchModal} onClose={submitAnswer} />
      )}
    </div>
  );
};

export default ContentsController;
