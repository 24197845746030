import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { customizedComponent } from '../../markdownOption';

interface Props {
  content: string;
}

const MarkdownContent = ({ content }: Props) => {
  return (
    <ReactMarkdown
      className="sessionContentMarkdown"
      components={customizedComponent}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rehypePlugins={[rehypeRaw as any]}
    >
      {content}
    </ReactMarkdown>
  );
};

export default MarkdownContent;
