import React from 'react';
import Content from 'Pages/CurriculumRouter/Components/MarkdownContent';
import css from './Term.module.scss';

const Refund = () => {
  return (
    <div className={css.refund}>
      <div className={css.left}>환불 안내</div>
      <div className={css.right}>
        <Content content={MD.trim()} />
      </div>
    </div>
  );
};

const MD = `
# 환불 정책

<br>

## 가. 동영상 학습비의 구매계약 철회, 반환 등

<br>

### 1. 청약 철회
<br>

(1) 원칙<br>
서비스의 이용에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날로부터 7일(수신확인의 통지를 받은 때보다 서비스의 공급이 늦게 이루어진 경우에는 공급을 받거나 공급이 개시된 날로부터 7일) 이내에 청약의 철회를 할 수 있습니다.<br></br>

표시, 광고 내용과 다르거나 계약 내용과 다르게 이행된 경우에는 공급받은 날로부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 청약철회 등을 할 수 있습니다.<br></br>

(2) 이용자의 청약철회권이 제한되는 경우<br></br>

가. 이용자의 책임 있는 사유로 서비스가 멸실되거나 훼손된 경우<br></br>

나. 이용자의 사용 또는 일부 소비로 서비스의 가치가 현저히 감소한 경우<br></br>

다. 시간의 경과에 의해 재판매가 곤란할 정도로 서비스의 가치가 현저히 감소한 경우<br><br>

라. 복제가 가능한 서비스의 포장을 훼손한 경우

<br>
<br>

### 2. 학습비 반환
<br>

유료 서비스 금액(학습비)의 반환에 관하여 “학원의 설립 운영 및 과외 교습에 관한 법률” 등에서 정하고 있는 기준에 의해 반환합니다.

1. 일부 디지털 콘텐츠의 경우, 이용자의 기타 사유에 의한 수업 연장 기간을 포함하지 않습니다.
2. 이용자가 할인에 의해 학습비를 결제하였을 경우, 학습비 반환 금액의 기준은 할인이 적용되어 실제 결제한 금액에 의하여 정합니다.
3. 학습비 반환 사유가 발생한 날부터 5일(영업일 기준) 이내에 반환합니다.
4. 이용자가 유료 서비스 결제 후 7일 이내 해지를 요구하는 경우 2강 이하 열람 시 또는 학습 2단계 이하 열람 시 전액 환불됩니다. 7일 이내라도 3강 이상 열람 또는 학습 3단계 이상 열람 시에는 부분 환불됩니다.
5. 증정품(교재, 사은품)이 포함된 경우 처음 수령했을 때 상태 그대로 증정품을 반환하셔야 하며, 왕복 택배비는 이용자가 부담하여야 합니다.
6. 구독형 학습비의 경우 구매 후 7일 이내 구독 취소(환불 요청) 시, 이용 이력이 없을 경우 환불 가능합니다.
7. 첫 구매 시, 3일 이내에 구독 취소(환불 요청)시, 이용 여부 상관 없이 100% 환불 가능합니다. (계정당 1회 무료 체험 가능)

### 3. 기타

- 회사와 이용자가 체결한 구매계약에 의해서 제공되는 내역이 디지털 콘텐츠(동영상 등)의 학습비와 재화(교재·도서 등)가 포함되었을 경우, 각각 구분하여 학습비 반환 및 환급 기준을 적용합니다.
- 미성년자와 거래에 관한 계약 시 법정대리인이 그 계약에 동의하지 아니하면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있습니다.

<br><br>

## 나. 교재·도서의 반품 및 환불

<br>

다음 각호의 경우에는, 배송된 재화일지라도 교재·도서를 반품 받은 다음 5일(영업일 기준) 이내에 이용자의 요구에 따라 즉시 환급, 반품 및 교환 조치를 합니다. 단, 이용자의 그 반품 및 교환 조치의 요구 기간은 배송된 날로부터 7일 이내에만 가능합니다.

   1. 배송된 재화가 주문 내용과 상이하거나 회사가 제공하는 "서비스"에서 게시된 정보와 상이할 경우
   2. 배송된 재화가 파손, 손상되었거나 오염되었을 경우 (단, 이용자의 고의·과실이 없어야 합니다.)
   3. 재화가 광고에 표시된 배송기간보다 늦게 배송된 경우

이용자가 교재·도서를 구매 후 사용하여 상품 가치에 손상을 입혔을 경우, 어떠한 사유에서도 환불 및 반품이 되지 않습니다. 또한 완질의 경우 완성품으로 일부의 교재를 사용한 경우에도 전량 환불 및 반품이 되지 않습니다.

<br><br>

## 다. 사이버 머니

<br>

### 1. 적립과 사용
<br>

(1) 원칙

사이버머니의 적립과 사용 조건은 회사의 정한 바에 따르며 회사의 사정에 따라 사전 통지 후 변경될 수 있습니다.<br></br>

사이버머니는 원칙적으로 서비스 및 도서 등 유료 상품 결제 시 사용 가능하나(단, 에듀머니 등 일부 사이버머니의 경우 도서만 구입하는 경우 살 수 없음) 회사 정책에 따라 사용 대상 상품의 범위가 제한될 수 있습니다.<br></br>

1회 결제 시 사이버머니의 최대 사용금액에 대해 제한이 있을 수 있습니다.

<br>
<br>

### 2. 소멸
<br>

(1) 원칙

사이버머니는 적립 시점을 기준으로 1년이 경과하면 순차적으로 자동 소멸됩니다. 단, 에듀머니 등 회사가 별도로 정한 사이버머니의 경우 이벤트 프로모션에 따라 유효기간이 상이할 수 있습니다.<br></br>

사이버머니는 현금 등으로 교환 불가하며, 사이버머니로 결제한 상품의 환불 시 무상으로 적립한 사이버머니는 환불되지 않고 소멸됩니다.<br></br>

사이버머니는 제3자에게 양도 또는 대여 불가하며 회원 탈퇴 시 자동 소멸됩니다. 소멸된 사이버머니는 재가입하더라도 복구되지 않습니다.<br></br>

`;
export default Refund;
