import lineColumn from 'line-column';
import { AssignmentSource } from 'Pages/CurriculumRouter/Session/type';
import { AnswerStatusType } from 'store/Course';

export const inputMatcher = /\[\[(\w+)\]\]/g;
export const userDataKeyMatcher = /\[\[:([\w:]+)\]\]/g;

export type EditorType = 'button' | 'input' | 'image' | 'date' | 'quiz';

type LineColList = {
  line: number;
  startColumn: number;
  endColumn: number;
};

const SPACE = ' ';

/**
 * 한 줄에 [[blank]]가 여러개 있을 경우,
 * 기존 코드에서 [[blank]]를 제거하면서 blankIdxList를 만들어야 한다.
 *
 * @param code
 * @returns {line: number, col: number}[]
 * @example [{line: 3, col: 26}, {line: 4, col: 26}]
 */

export const fillBlanksWithAnswers = (
  code: string,
  submitAnswerList: string[],
) => {
  const lineColList: LineColList[] = [];
  const blanks = code.match(inputMatcher) ?? code.match(userDataKeyMatcher);

  if (!blanks) return { lineColList, code };

  for (let i = 0; i < blanks.length; i++) {
    const blankIdx = code.indexOf(blanks[i]);
    code = code.replace(blanks[i], submitAnswerList?.[i] ?? SPACE);

    const { line, col } = lineColumn(code).fromIndex(blankIdx)!;
    const startColumn = col - 1 || 1;
    const endColumn = startColumn + (submitAnswerList[i]?.length || 1);

    lineColList.push({ line, startColumn, endColumn });
  }

  return { lineColList, code };
};

export const formatAssignment = (source: AssignmentSource) => ({
  ...Object.entries(source).reduce(
    (acc, [file, code]) => ({
      ...acc,
      [file]: code.replaceAll('\\n', '\n').trim(),
    }),
    {},
  ),
  'sandbox.config.json': '{ "template": "parcel" }',
});

export const extractHtmlInnerText = (node: string) => {
  const tagReg = /<[^>]*>?/g;
  return node.replace(tagReg, '');
};

export const getPreviewFile = (
  file: Record<string, string>,
  userData: Record<string, string>,
) => {
  const previewFile = Object.entries(file).reduce((acc, [filename, code]) => {
    let parsedCode = code;
    const blankVariables = getCustomBlankVariables(code);

    blankVariables?.forEach(variable => {
      parsedCode = parsedCode.replace(`[[:${variable}:]]`, userData[variable]);
    });

    return {
      ...acc,
      [filename]: parsedCode,
    };
  }, {});

  return previewFile;
};

export const getCustomBlankVariables = (code: string) => {
  const blanks = code.match(userDataKeyMatcher);
  if (!blanks) return null;
  const variables = blanks.map(blank => blank.replace(/[[\]:]/g, ''));

  return variables;
};

export const replacePrevUserData = (
  code: string,
  userData: Record<string, string>,
) => {
  const previousUserDataMatcher = /<<user_([\w:]+)>>/g;
  const variables = [...code.matchAll(previousUserDataMatcher)].map(
    ([, variable]) => variable,
  );

  if (variables.length) {
    variables.forEach(variable => {
      code = code.replace(`<<user_${variable}>>`, userData[variable]);
    });
  }

  return code;
};

export const getAnswerComparisonResults = (
  answers: string[],
  submittedAnswers: string[],
  answerStatus: AnswerStatusType,
) => {
  if (answerStatus !== 'incorrect') return;

  return answers.map((answer, i) => submittedAnswers[i] === answer);
};

export const setFingereditorHeight = () => {
  const initialProvider = document.getElementsByClassName(
    'sp-cm',
  ) as HTMLCollectionOf<HTMLElement>;

  // HACK: finger editor 를 찾기 위해 aria-label 없는지 판별 (fingereditor에는 aria-label 적용 되어있지 않음)
  // 추후 finger editor Repo에서 id나 class를 추가적으로 부여 할 예정

  if (initialProvider.length === 0) return;

  const fingerEditorNode = [...initialProvider].filter(
    node => !node.getAttribute('aria-label'),
  );

  if (fingerEditorNode.length === 0) return;

  fingerEditorNode.forEach(node => {
    setTimeout(() => {
      requestAnimationFrame(() => {
        const height = node.children[0]?.scrollHeight;
        node.style.minHeight = `${height}px`;
      });
    }, 100);
  });
};

export const EDITOR_THEME = {
  colors: {
    surface1: '#161732',
    surface2: '#E2E8F0',
    surface3: '#112331',
    clickable: '#6988a1',
    base: '#808080',
    disabled: '#4D4D4D',
    hover: '#c5e4fd',
    accent: '#c5e4fd',
    error: '#ffcdca',
    errorSurface: '#811e18',
  },
  syntax: {
    plain: 'white',
    comment: '#999999',
    keyword: '#6796e1',
    tag: '#6796e1',
    punctuation: '#7fdbca',
    definition: '#49d48a',
    property: '#b7d0f6',
    static: '#c792ea',
    string: '#e79e3e',
  },
  font: {
    size: '16px',
    lineHeight: '1.5',
  },
};
