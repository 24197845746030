import React from 'react';
import Button from 'Components/core/Button';
import Icon, { IconName } from 'Components/core/Icon/Icon';
import css from './NoContent.module.scss';

interface Props {
  icon: IconName;
  title: string;
  subText: string;
  btnText?: string;
  onClick?: () => void;
  width?: string;
}

function NoContent({ icon, title, subText, btnText, onClick, width }: Props) {
  return (
    <div className={css.noContent}>
      <Icon name={icon} />
      <p className={css.title}>{title}</p>
      <div className={css.description}>{subText}</div>
      {btnText && (
        <Button
          size="medium"
          variant="lineGrey"
          onClick={onClick}
          className={css.footnote}
          width={width}
        >
          {btnText}
        </Button>
      )}
    </div>
  );
}

export default NoContent;
