import { makeVar } from '@apollo/client';
import { AlertMessage } from 'lib/constant/alertMessage';
import { noop } from 'utils';

export type SemanticType = 'error' | 'info' | 'positive' | 'warn';

export type AlertDialog = {
  icon?: string;
  message: string;
  desc?: string;
};

export interface AlertState {
  show?: boolean;
  title?: string;
  type?: SemanticType;
  message?: string;
  dialog: AlertDialog;
  hasCancelButton?: boolean;
  hasHelpEmailInfo?: boolean;
  confirmText?: string;
  onSuccess?: () => void;
  onClose?: () => void;
}

export const alertInitialState: AlertState = {
  show: false,
  title: '',
  type: 'info',
  message: '',
  dialog: AlertMessage.common.error.unknown,
  hasCancelButton: false,
  hasHelpEmailInfo: false,
  confirmText: '확인',
  onSuccess: noop,
  onClose: noop,
};

export const alertVar = makeVar<AlertState>(alertInitialState);
