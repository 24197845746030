import React, { FormEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import Button from 'Components/core/Button';
import { CREATE_COURSE_EVALUATION } from 'gql/course/mutation';
import Modal from '../Modal';
import AlertHeader from '../Alert/Header';
import css from './CourseSurvey.module.scss';

interface Props {
  switchModal: () => void;
  onClose?: () => void;
}

const CourseSurvey = ({ switchModal, onClose }: Props) => {
  const { courseId } = useParams();
  const [feedbackList, setFeedbackList] = useState(FEEDBACK_LIST);
  const [createCourseEvaluation] = useMutation(CREATE_COURSE_EVALUATION, {
    context: { endpoint: 'course' },
    onCompleted: onClose,
  });
  const checkedFeedback = feedbackList.find(({ isChecked }) => isChecked);

  const onChangeFeedback = (id: number) => () => {
    const next = feedbackList.map(feedback => ({
      ...feedback,
      isChecked: feedback.id === id,
    }));
    setFeedbackList(next);
  };

  const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (checkedFeedback) {
      createCourseEvaluation({
        variables: {
          courseId: parseInt(courseId!),
          evaluationTypeId: checkedFeedback.id,
        },
      });
    } else {
      switchModal();
    }

    onClose?.();
  };

  return (
    <Modal switchModal={switchModal}>
      <div className={css.modal}>
        <AlertHeader title="코스 평가" switchModal={onSubmit} />
        <form className={css.body} onSubmit={onSubmit}>
          <ul className={css.feedbackList}>
            {feedbackList.map(({ id, emoji, title, isChecked }) => (
              <label key={id} className={css.feedback}>
                <input
                  onChange={onChangeFeedback(id)}
                  className={css.radio}
                  value={title}
                  type="radio"
                  name="feedback"
                  checked={isChecked}
                />
                <div className={css.emoji}>{emoji}</div>
                <div className={css.title}>{title}</div>
              </label>
            ))}
          </ul>
          <Button
            disabled={!checkedFeedback}
            className={css.submitButton}
            size="full"
            type="submit"
          >
            제출
          </Button>
        </form>
        <div className={css.footer}>
          <strong>더 자세한 평가를 남기고 싶다면?</strong>
          <p>설문조사를 작성하시면 더 자세한 내용을 남겨주실 수 있습니다.</p>
          <Button variant="lineGrey" href={SURVEY_LINK}>
            설문조사 남기기
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CourseSurvey;

const FEEDBACK_LIST = [
  {
    id: 1,
    emoji: '🙂' as string,
    title: '좋아요',
    description: '좋은 콘텐츠에 감사합니다',
    isChecked: false,
  },
  {
    id: 2,
    emoji: '🥰' as string,
    title: '재밌어요',
    description: '재밌게 배웠어요',
    isChecked: false,
  },
  {
    id: 3,
    emoji: '🤓' as string,
    title: '유익해요',
    description: '유익한 콘텐츠에 감사합니다',
    isChecked: false,
  },
  {
    id: 4,
    emoji: '😨' as string,
    title: '어려워요',
    description: '어려운 부분이 있어요',
    isChecked: false,
  },
  {
    id: 5,
    emoji: '🥱' as string,
    title: '지루해요',
    description: '지루한 콘텐츠에요',
    isChecked: false,
  },
];

const SURVEY_LINK = 'https://1oeiza2ss8x.typeform.com/to/saYzVp1C';
