import blog from 'assets/Footer_blog.svg';
import instagram from 'assets/Footer_instagram.svg';
import wecode from 'assets/Footer_website.svg';
import youtube from 'assets/Footer_youtube.svg';

interface Icons {
  id: number;
  img: string;
  url: string;
  alt: string;
}

export const ICONS: Icons[] = [
  {
    id: 1,
    img: instagram,
    url: 'https://www.instagram.com/justcode_by_wecode/',
    alt: 'Instagram icon',
  },
  {
    id: 2,
    img: youtube,
    url: 'https://www.youtube.com/channel/UCSBzZfbXra68mJC7nJ6qN-w',
    alt: 'Youtube icon',
  },
  {
    id: 3,
    img: blog,
    url: 'https://blog.naver.com/wecode',
    alt: 'Wecode blog icon',
  },
  {
    id: 4,
    img: wecode,
    url: 'https://wecode.co.kr/',
    alt: 'Wecode Homepage icon',
  },
];
