import { AnswerStatusType } from 'store/Course';
import { ToastType } from './Toast';

export const getToastContent = (
  variant: ToastType,
  answerStatus: Exclude<AnswerStatusType, 'not submitted'>,
  feedback?: string,
) => {
  const toastContent = {
    resultedContent: {
      correct: {
        title: '성공입니다!',
        description: '점점 더 만들어지고 있어요! 계속해볼까요?',
      },
      incorrect: {
        title: '다시 선택해주세요.',
        description: '조금만 바꿔보면 될 것 같아요!',
      },
    },
    feedback: {
      correct: {
        title: '성공입니다!',
        description: '다음 버튼을 눌러 계속 진행해 볼까요?',
      },
      incorrect: {
        title: '다시 입력해주세요.',
        description: feedback || '조금만 더 생각해볼까요?',
      },
    },
    quiz: {
      correct: {
        title: '정답입니다!',
        description: '다음 버튼을 눌러 계속 진행해 볼까요?',
      },
      incorrect: {
        title: '다시 선택해주세요.',
        description: '조금만 더 생각해볼까요?',
      },
    },
  };

  return toastContent[variant][answerStatus];
};
