import React, { useLayoutEffect } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { githubGist } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { FingerEditor } from '@wecode-codingbootcamp/finger-editor';
import { parseCode } from 'utils/formats';
import { isValidURL } from 'utils/validation/checkValid';
import { setFingereditorHeight } from 'utils/fingereditor';
import ArrowBullet from './Components/ArrowBullet';
import BulletList from './Components/BulletList';
import Callout from './Components/Callout';
import CheckBulletBox from './Components/CheckBulletBox';
import MarkdownCodeEditor from './Components/MarkdownCodeEditor';
import Columns from './Components/Columns';
import Introduction from './Components/Introduction';
import LinkhavingComment from './Components/LinkHavingComment';
import LinkToSubContentBox from './Components/LinkToSubContentBox';
import Reference from './Components/Reference';
import {
  BulletListProps,
  CodeProps,
  CodePropsCombinedHref,
  CommentingProps,
  CustomCodeProps,
  IntroductionProps,
  PreviewProps,
  SubContentProps,
} from './types';

export const customizedComponent = {
  codeeditor: ({ children }: CodeProps) => {
    const files = parseCode(String(children).replace(/\n$/, ''));
    return <MarkdownCodeEditor files={files} />;
  },
  codepreview: ({ filename = 'index.js', children }: PreviewProps) => {
    useLayoutEffect(setFingereditorHeight, []);

    return (
      <FingerEditor
        className="notSubmitted"
        assignmentCode={children[0] as string}
        submittedAnswers={[]}
        filename={filename}
        showLineNumbers={false}
      />
    );
  },
  code: ({ inline, className, children, ...props }: CodeProps) => {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <SyntaxHighlighter
        style={githubGist}
        language={match[1]}
        customStyle={{
          fontSize: '15px',
          padding: '20px',
          lineHeight: '1.3em',
          backgroundColor: '#faf9f8',
        }}
        PreTag="span"
        wrapLongLines={true}
        {...props}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    ) : (
      <code
        className={className}
        style={{
          display: 'inline-block',
          height: '20px',
          padding: '2px 4px',
          borderRadius: '5px',
          color: '#eb5757',
          backgroundColor: '#ededeb',
          fontWeight: 600,
          fontSize: '14px',
          fontFamily: 'noto-sans-cjk-kr, sans-serif',
          lineHeight: '1.3',
        }}
        {...props}
      >
        {children}
      </code>
    );
  },
  highlight: ({ ...props }: CodeProps) => (
    <span style={{ backgroundColor: '#EFEEFF', padding: '0 3px' }} {...props} />
  ),
  a: ({ href, children, ...props }: CodePropsCombinedHref) => {
    const isLinkToBtn =
      href && !isValidURL(href) && href.indexOf('/session/') !== -1;
    return href && isLinkToBtn ? (
      <LinkToSubContentBox link={href} isReference {...props}>
        {children}
      </LinkToSubContentBox>
    ) : (
      <a
        className="reference"
        href={href}
        target="_blank"
        rel="noreferrer"
        {...props}
      >
        {children}
      </a>
    );
  },
  subcontent: ({ content, children, ...props }: SubContentProps) => {
    return (
      <LinkToSubContentBox link={content} isReference={false} {...props}>
        {children}
      </LinkToSubContentBox>
    );
  },
  commenting: ({ children, comment, ...props }: CommentingProps) => {
    return (
      <LinkhavingComment comment={comment} {...props}>
        {children}
      </LinkhavingComment>
    );
  },
  background: ({ children, ...props }: CustomCodeProps) => {
    return (
      <p
        style={{
          backgroundColor: '#f1f5f9',
          // backgroundColor: BACKGROUND_STRING_TO_HEX[color],
          padding: '10px 2px',
        }}
        {...props}
      >
        {children}
      </p>
    );
  },
  color: ({ children, color, ...props }: CustomCodeProps) => {
    return (
      <span style={{ color: color }} {...props}>
        {children}
      </span>
    );
  },
  callout: ({ children, emoji, ...props }: CustomCodeProps) => {
    return (
      <Callout content={children} emoji={emoji ? emoji : '💡'} {...props} />
    );
  },
  columns: ({ children, number, ...props }: CustomCodeProps) => {
    return (
      <Columns number={number} {...props}>
        {children}
      </Columns>
    );
  },
  indentation: ({ children, ...props }: CustomCodeProps) => {
    return (
      <span style={{ display: 'block', marginLeft: '20px' }} {...props}>
        {children}
      </span>
    );
  },
  reference: ({ children, ...props }: CustomCodeProps) => {
    return <Reference {...props}>{children}</Reference>;
  },
  arrowbullet: ({ children, ...props }: CustomCodeProps) => {
    return <ArrowBullet {...props}>{children}</ArrowBullet>;
  },
  checkbulletbox: ({ content, children, ...props }: SubContentProps) => {
    return (
      <CheckBulletBox title={content} {...props}>
        {children}
      </CheckBulletBox>
    );
  },
  introduction: ({ text, subtext, ...props }: IntroductionProps) => {
    return <Introduction text={text} subtext={subtext} {...props} />;
  },
  bulletlist: ({ benefits, suggestions, ...props }: BulletListProps) => {
    const parsedBenefits = benefits.split(',');
    const parsedSuggestions = suggestions.split(',');

    return (
      <BulletList
        benefits={parsedBenefits}
        suggestions={parsedSuggestions}
        {...props}
      />
    );
  },
  // img: () => {
  //   return (
  //     <img
  //       className={cn(SESSION_IMG_CLASSNAME, 'loading')}
  //       src="/images/loading-thin.gif"
  //       alt="loading"
  //     />
  //   );
  // },
};

// const BACKGROUND_STRING_TO_HEX: BackgroundColor = {
//   gray: '#ebeced',
//   yellow: '#fbf3da',
//   red: '#fae4e3',
//   green: '#ddebf0',
// };
