interface RegExpType {
  [key: string]: RegExp;
}

export const checkRegExp = (regObj: RegExpType) => {
  return Object.entries(regObj)
    .map(([key, value]: [string, RegExp]) => {
      const reg = new RegExp(value);
      return reg.test(key);
    })
    .every(v => v);
};
