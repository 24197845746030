import React from 'react';
import css from './WaitingIcon.module.scss';

const WaitingIcon = () => {
  return (
    <div className={css.container}>
      {Array.from({ length: 3 }).map((_, idx) => (
        <span
          className={css.loadingCircle}
          key={idx}
          style={{ '--delay': `${idx * 0.1}s` } as React.CSSProperties}
        />
      ))}
    </div>
  );
};

export default WaitingIcon;
