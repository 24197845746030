import React from 'react';
import Icon from 'Components/core/Icon/Icon';
import Modal from 'Components/core/ModalPortal/Modal';
import { createMarkup } from 'utils/constants/createMarkup';
import css from './InformationModal.module.scss';

interface Props {
  title: string;
  text: string;
  switchModal: () => void;
}

const InformationModal = ({ title, text, switchModal }: Props) => {
  return (
    <Modal switchModal={switchModal}>
      <div className={css.container}>
        <div className={css.titleBox}>
          <p>{title}</p>
          <Icon name="close" onClick={switchModal} />
        </div>
        <p
          className={css.description}
          dangerouslySetInnerHTML={createMarkup(text)}
        />
      </div>
    </Modal>
  );
};
export default InformationModal;
