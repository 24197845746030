import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import GoogleLogin from 'Components/GoogleLogin';
import KakaoLogin from 'Components/core/KakaoLogin';
import css from './BeforeLoginContent.module.scss';

const BeforeLoginContent = () => {
  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem('current-location', location.pathname);
  }, []);

  return (
    <div className={css.loginWrapper}>
      <div className={css.btnBox}>
        <KakaoLogin />
      </div>
      {/* <GoogleLogin /> */}
    </div>
  );
};

export default BeforeLoginContent;
