import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import GoogleLogin from 'Components/GoogleLogin';
import Icon from 'Components/core/Icon/Icon';
import KakaoLogin from 'Components/core/KakaoLogin';
import Modal from 'Components/core/ModalPortal/Modal';
import css from './LoginModal.module.scss';

interface Props {
  switchModal: () => void;
}

const LoginModal = (props: Props) => {
  const { switchModal } = props;
  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem('current-location', location.pathname);
  }, []);

  return (
    <Modal switchModal={switchModal}>
      <div className={css.container}>
        <div className={css.titleBox}>
          <p>로그인이 필요합니다.</p>
          <Icon name="close" onClick={switchModal} />
        </div>
        <div className={css.btnWrapper}>
          <div className={css.btnBox}>
            <KakaoLogin />
          </div>
          {/* <GoogleLogin /> */}
        </div>
      </div>
    </Modal>
  );
};
export default LoginModal;
