import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import MarkdownContent from 'Pages/CurriculumRouter/Components/MarkdownContent/MarkdownContent';
import { createMarkup } from 'utils/constants/createMarkup';
import { cn, cond } from 'utils/styles';
import { playBeep } from 'utils';
import { LevelTestType } from 'gql/course/type';
import { GET_GENERATE_CHAT_RESPONSE_FOR_ASSIGNMENT } from 'gql/course/query';
import SpeechBubble from '../SpeechBubble/SpeechBubble';
import Icon, { IconName } from '../Icon/Icon';
import { Message, Option } from './type';
import WaitingIcon from '../WaitingIcon/WaitingIcon';
import css from './Chat.module.scss';

interface Props {
  messages: Message[];
  inputType: 'input' | 'button';
  title?: string;
  opponentIcon?: IconName;
  generateAnswerFromGPT?: boolean;
  repeat?: boolean;
  onClose?: () => void;
  onTestComplete?: (score: number, levelTestType: LevelTestType) => void;
}

const Chat = ({
  messages: initialMessages,
  inputType = 'input',
  title,
  opponentIcon = 'codeDuckBlack',
  generateAnswerFromGPT,
  repeat,
  onClose,
  onTestComplete,
}: Props) => {
  const { contentId } = useParams();
  const lastMsgRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [msgQueue, setMsgQueue] = useState<Message[]>(initialMessages);
  const [selectOptions, setSelectOptions] = useState<Option[]>([]);
  const [optionQueue, setOptionQueue] = useState<Option[]>([]);
  const [isMsgLoading, setIsMsgLoading] = useState(true);
  const [newMessage, setNewMessage] = useState('');
  const [selectedDiversions, setSelectedDiversions] = useState<number[]>([]);
  const [points, setPoints] = useState(0);

  const totalDiversions = initialMessages.reduce(
    (acc, cur) => (cur.diversion ? acc + 1 : acc),
    0,
  );
  const diversion = selectedDiversions[0];
  const levelTestType = {
    1: LevelTestType.WITHOUT_CODING_EXPERIENCE,
    2: LevelTestType.WITH_CODING_EXPERIENCE,
  }[diversion] as LevelTestType;

  const [generateChatResponseForAssignment, { loading }] = useLazyQuery<{
    generateChatResponseForAssignment: string;
  }>(GET_GENERATE_CHAT_RESPONSE_FOR_ASSIGNMENT, {
    context: { endpoint: 'course' },
    fetchPolicy: 'no-cache',
    onCompleted: ({ generateChatResponseForAssignment }) => {
      const realMessages = messages;
      realMessages.pop();
      realMessages.push({
        sender: 'codeduck',
        msg: generateChatResponseForAssignment,
      });

      setMessages(realMessages);
      setIsMsgLoading(false);
    },
  });

  const handleSendClick = (
    message?: string,
    diversion?: number,
    point?: number,
    complete?: boolean,
  ) => {
    if ((newMessage.trim() === '' && !message) || optionQueue.length !== 0)
      return;

    setMessages([...messages, { sender: 'user', msg: message || newMessage }]);
    setNewMessage('');

    if (generateAnswerFromGPT) {
      const msgForLoadingIcon: Message = { sender: 'codeduck', msg: '' };

      setIsMsgLoading(true);
      setMessages(prev => prev.concat(msgForLoadingIcon));
      generateChatResponseForAssignment({
        variables: {
          assignmentId: Number(contentId),
          userChat: newMessage,
        },
      });
    }

    if (point) {
      setIsMsgLoading(true);
      setPoints(prevPoint => prevPoint + point);
    }

    if (message) {
      if (diversion) {
        const filteredMsg = msgQueue.filter(
          ({ diversion: msgDiversion }) =>
            msgDiversion === diversion || !msgDiversion,
        );
        const newMsgQueue = repeat
          ? [...filteredMsg, ...msgQueue]
          : filteredMsg;

        setSelectedDiversions(prev => prev.concat(diversion));
        setMsgQueue(newMsgQueue);
      }

      setIsMsgLoading(true);
      setSelectOptions([]);
    }

    if (complete) {
      onTestComplete?.(points, levelTestType);
    }

    playBeep();
  };

  const handleClose = () => {
    if (!onClose || isMsgLoading || optionQueue.length !== 0) return;

    onClose();
  };

  useEffect(() => {
    if (messages.length === 0) {
      setIsMsgLoading(true);
      setMessages(prevMessages => [...prevMessages, msgQueue[0]]);
      setMsgQueue(prevQueue => prevQueue.slice(1));

      return;
    }

    if (msgQueue.length > 0 && selectOptions.length === 0) {
      setIsMsgLoading(true);
      const timer = setTimeout(() => {
        setMessages(prevMessages => [...prevMessages, msgQueue[0]]);
        setMsgQueue(prevQueue => prevQueue.slice(1));

        if (msgQueue[0].options) {
          setOptionQueue(msgQueue[0].options);
        }
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setIsMsgLoading(false);
    }
  }, [msgQueue, selectOptions]);

  useEffect(() => {
    if (optionQueue.length > 0) {
      const timer = setTimeout(() => {
        setSelectOptions(prevOptions => [...prevOptions, optionQueue[0]]);
        setOptionQueue(prevQueue => prevQueue.slice(1));
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [optionQueue]);

  useEffect(() => {
    if (!lastMsgRef.current) return;

    lastMsgRef.current.scrollIntoView();
  }, [messages, selectOptions, loading]);

  const INPUT_TYPE = {
    input: (
      <>
        <input
          className={css.messageInput}
          value={newMessage}
          onChange={e => {
            !isMsgLoading && setNewMessage(e.target.value);
          }}
          placeholder="질문을 입력하세요"
          disabled={isMsgLoading}
        />
        <button
          className={css.messageSendButton}
          onClick={() => handleSendClick()}
          disabled={isMsgLoading || !newMessage}
        >
          전송
        </button>
      </>
    ),
    button: (
      <>
        {onClose && <Icon name="btnQuit" onClick={handleClose} />}
        <div className={css.actionContainer}>
          {(totalDiversions === 0 ||
            totalDiversions !== selectedDiversions.length) &&
            (isMsgLoading ? (
              <Icon className={css.waitIcon} name="wait" />
            ) : (
              <div className={css.selectBtnWrapper}>
                {selectOptions.map(({ text, diversion, point, complete }) => (
                  <button
                    key={text}
                    className={css.selectButton}
                    onClick={() =>
                      handleSendClick(text, diversion, point, complete)
                    }
                    disabled={
                      repeat && selectedDiversions.includes(Number(diversion))
                    }
                  >
                    {text}
                  </button>
                ))}
              </div>
            ))}
        </div>
      </>
    ),
  };

  return (
    <div className={css.container}>
      {title && <header className={css.header}>{title}</header>}
      <div className={css.messageContainer}>
        {messages.map(({ sender, msg, size = 'fit' }, idx) => {
          const isFirstMsg = idx === 0 || messages[idx - 1].sender !== sender;
          const isLastMsg = idx === messages.length - 1;

          return (
            <div
              key={idx + msg}
              ref={isLastMsg ? lastMsgRef : null}
              className={cn(
                css.messageWrapper,
                cond((isFirstMsg && idx > 0) || size === 'full', css.topGap),
              )}
            >
              {sender === 'codeduck' ? (
                <>
                  {size === 'fit' && (
                    <div
                      className={cn(
                        css.codeduckIcon,
                        cond(isFirstMsg, css.visible),
                      )}
                    >
                      <Icon name={opponentIcon} />
                    </div>
                  )}
                  {loading && isLastMsg ? (
                    <WaitingIcon />
                  ) : (
                    msg && (
                      <SpeechBubble
                        className={cn(css.codeduckMessage, css[size])}
                        arrow={isFirstMsg ? 'left' : ''}
                      >
                        <MarkdownContent content={msg} />
                      </SpeechBubble>
                    )
                  )}
                </>
              ) : (
                <div
                  className={css.userMessage}
                  dangerouslySetInnerHTML={createMarkup(msg)}
                />
              )}
            </div>
          );
        })}
      </div>
      <form
        className={css.messageInputContainer}
        onSubmit={e => e.preventDefault()}
      >
        {INPUT_TYPE[inputType]}
      </form>
    </div>
  );
};

export default Chat;
