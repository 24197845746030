// TODO: 추후에 결과물이 없음을 보여주는 데이터 추가되면 해당 파일 삭제하고 데이터로 처리할 것
export const RESULT_COURSES: number[] = [
  11, 12, 15, 93, 94, 95, 96, 97, 100, 101, 102, 103, 104, 105, 106, 107, 108,
  109, 110, 111, 112, 113, 114, 115, 116, 117, 130, 131, 142, 143, 144, 150,
];

// TODO: 추후에 course에 target 관련 필드 추가되면 파일 삭제하고 데이터로 처리할 것
export const STUDENT_COURSES: number[] = [
  156, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
  173,
];

export enum Target {
  adult = 'adult',
  student = 'student',
  others = 'others',
}
