import React from 'react';
import { useQuery } from '@apollo/client';
import Button from 'Components/core/Button';
import { GET_PRODUCT_THUMBNAIL } from 'gql/course/query';
import { Thumbnail } from 'gql/course/type';
import { PaymentHistoryType } from 'gql/payment/type';
import css from './Payment.module.scss';

interface Props {
  paymentData: PaymentHistoryType;
  openModal: () => void;
}

const Payment = ({ paymentData, openModal }: Props) => {
  const {
    orderId,
    orderName,
    orders,
    amount,
    method,
    approvedAt,
    refundable,
    receiptUrl,
  } = paymentData;

  const { data: thumbnailData } = useQuery<Thumbnail>(GET_PRODUCT_THUMBNAIL, {
    context: { endpoint: 'course' },
    variables: {
      id: orders[0].productId,
    },
  });

  const paymentDate = approvedAt.split('T')[0];

  return (
    <div className={css.container}>
      <div className={css.infoWrapper}>
        <img
          src={thumbnailData?.course.thumbnail}
          alt={orders[0].name}
          className={css.thumbnail}
        />
        <div className={css.infoDetail}>
          <p className={css.orderId}>주문번호 {orderId}</p>
          <p className={css.orderTitle}>{orderName}</p>
          {orders.length > 1 &&
            orders.map(({ id, name, price }) => (
              <p key={id} className={css.courseItem}>
                <span className={css.courseTitle}>{name}</span>
                <span className={css.coursePrice}>
                  {price.toLocaleString()}원
                </span>
              </p>
            ))}
          <p className={css.totalPrice}>합계 {amount.toLocaleString()}원</p>
          <div className={css.paymentInfo}>
            <span>구매 방식: {method}</span>
            <span>구매일: {paymentDate}</span>
            {refundable && (
              <Button variant="ghost" onClick={openModal}>
                환불 문의
              </Button>
            )}
          </div>
        </div>
      </div>
      {receiptUrl && (
        <div className={css.btnWrapper}>
          <Button
            variant="lineGrey"
            size="small"
            onClick={() => window.open(receiptUrl)}
          >
            영수증
          </Button>
        </div>
      )}
    </div>
  );
};

export default Payment;
