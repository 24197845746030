/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import { Product } from 'Pages/CurriculumRouter/CurriculumDetail/type';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import { CREATE_ORDER, DELETE_CART } from 'gql/payment/mutation';
import { GET_TOTAL_CART } from 'gql/payment/query';

const { dataLayer, kakaoPixel }: any = window;

export type productInfoType = {
  name: string;
  price: number;
  product: Product.COURSE;
  productId: number;
};

export const useCart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpenModal, switchModal } = useSwitchModal();

  const [createOrder] = useMutation(CREATE_ORDER, {
    context: {
      endpoint: 'payment',
    },
    onCompleted: data => {
      const { status, price, productId, name } = data.createOrder;

      if (status === 'PENDING') {
        dataLayer.push({
          event: 'AddToCart',
          price,
          productId,
          productName: name,
        });

        kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_ID).addToCart({
          id: productId,
        });

        if (location.pathname.includes(PATH.CURRICULUM.detailBase)) {
          dataLayer.push({
            event: 'add_to_cart',
            class_name: name,
          });
        }

        alertVar({
          show: true,
          type: 'positive',
          hasCancelButton: true,
          dialog: AlertMessage.cart.positive.success,
          onSuccess: () => navigate(PATH.CART.base),
        });
      }
    },
    refetchQueries: [
      {
        query: GET_TOTAL_CART,
        context: { endpoint: 'payment' },
      },
      'totalCartsCounts',
    ],
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.tryAgain,
        hasHelpEmailInfo: true,
      });
    },
  });

  const [deleteCart] = useMutation(DELETE_CART, {
    context: { endpoint: 'payment' },
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
    refetchQueries: [
      {
        query: GET_TOTAL_CART,
        context: { endpoint: 'payment' },
      },
      'totalCartsCounts',
    ],
  });

  const handleAddToCart = (productInfo: productInfoType) => {
    if (!localStorage.getItem(TOKEN)) {
      switchModal();
      return;
    }

    alertVar({
      show: true,
      hasCancelButton: true,
      dialog: AlertMessage.cart.info.add,
      onSuccess: () => {
        createOrder({
          variables: productInfo,
        });
      },
    });
  };

  const handleDeleteFromCart = (orderIds: number[], cartId: number) => {
    alertVar({
      show: true,
      hasCancelButton: true,
      type: 'warn',
      dialog: AlertMessage.cart.warn.delete,
      onSuccess: () => {
        deleteCart({
          variables: {
            orderIds,
            cartId,
          },
        });
      },
    });
  };

  return { isOpenModal, switchModal, handleAddToCart, handleDeleteFromCart };
};
