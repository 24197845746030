import beep from 'assets/beep.wav';

//eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};
//eslint-disable-next-line @typescript-eslint/no-empty-function
export const asyncNoop = async () => {};

export const sleep = async (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const getLineCount = (element: HTMLElement) => {
  const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
  const height = element.offsetHeight;
  return Math.round(height / lineHeight);
};

// audio 관련 side effect 발생 시 여기 확인 필요 (230331 래영)
export const playBeep = () => {
  const audioContext = new AudioContext();
  const source = audioContext.createBufferSource();
  const gainNode = audioContext.createGain();

  audioContext.resume();

  fetch(beep)
    .then(response => response.arrayBuffer())
    .then(buffer => audioContext.decodeAudioData(buffer))
    .then(decodedBuffer => {
      source.buffer = decodedBuffer;
      source.connect(gainNode);
      gainNode.connect(audioContext.destination);
      source.start(0);
    });
};
