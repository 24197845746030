import React from 'react';
import { Link } from 'react-router-dom';
import { PATH } from 'utils/constants/routes';
import Icon from '../Icon';
import { ReactComponent as EasyCodingLogo } from 'assets/common/easycoding_logo.svg';
import css from './Wecode.module.scss';

interface Props {
  variant?: 'wecode' | '간편코딩';
  beta?: boolean;
  onClick?: () => void;
}

const FULL_WIDTH = { width: '100%' };

const WecodeLogo = ({ onClick, beta, variant = 'wecode' }: Props) => {
  if (variant === '간편코딩') {
    return (
      <div style={FULL_WIDTH} onClick={onClick}>
        <EasyCodingLogo />
      </div>
    );
  }
  return (
    <Link className={css.logo} to={PATH.HOME.base} onClick={onClick}>
      <Icon name="justcodeLogo" />
      {beta && <span className={css.betaBadge}>Beta</span>}
    </Link>
  );
};

export default WecodeLogo;
