import React from 'react';
import css from './Reference.module.scss';

interface Props {
  children: React.ReactNode;
}

const Reference = ({ children }: Props) => {
  return (
    <div className={css.container}>
      <p className={css.subTitle}>참고자료</p>
      {children}
    </div>
  );
};

export default Reference;
