import React from 'react';
import { useNavigate } from 'react-router';
import Button from 'Components/core/Button';
import Icon from 'Components/core/Icon/Icon';
import Modal from 'Components/core/ModalPortal/Modal';
import { PATH } from 'utils/constants/routes';
import css from './NotDoneModal.module.scss';

interface Props {
  switchModal: () => void;
  contentId: number;
}

const NotDoneModal = ({ switchModal, contentId }: Props) => {
  const navigate = useNavigate();

  return (
    <Modal switchModal={switchModal}>
      <div className={css.container}>
        <div className={css.iconBox}>
          <Icon name="notDone" />
        </div>
        <p className={css.title}>아직 코딩을 끝내지 않으셨네요.</p>
        <div className={css.content}>
          <p className={css.contentText}>코스를 마무리하고, 코드를 완성하면</p>
          <p className={css.contentText}>결과물을 확인하실 수 있습니다.</p>
        </div>
        <div className={css.btnBox}>
          {/* TODO: Button 클릭시 학습페이지로 이동 */}
          <Button
            size="large"
            onClick={() => navigate(`${PATH.COURSE.base}/${contentId}`)}
          >
            이어하기
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NotDoneModal;
