import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import AlertHeader from 'Components/core/ModalPortal/Alert/Header';
import Modal from 'Components/core/ModalPortal/Modal';
import Button from 'Components/core/Button';
import PaymentModal from 'Components/core/PaymentModal';
import { createMarkup } from 'utils/constants/createMarkup';
import { CourseType } from 'gql/course/type';
import { useCart, useWishList } from 'utils/hooks';
import { TOKEN } from 'utils/constants';
import PurchaseSection from '../Purchase/Purchase';
import ShareButton from '../Share';
import LoginModal from '../LoginModal';
import css from './Banner.module.scss';

const SALE_MESSAGE = '오픈 기념 이벤트';

interface Props extends CourseType {
  shouldBuy: boolean;
  btnText: string;
  onClick: () => void;
}

const Banner = ({ shouldBuy, btnText, onClick, course }: Props) => {
  const {
    id: productId,
    coverImage,
    title,
    price,
    originalPrice,
    discountPercentage,
    introduction,
  } = course;
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const { isOpenModal, switchModal } = useSwitchModal();
  const { courseId } = useParams();
  const {
    handleAddToCart,
    isOpenModal: isCartModal,
    switchModal: switchCartModal,
  } = useCart();
  const {
    changeHeart,
    isOpenModal: isWishlistModal,
    switchModal: switchWishlistModal,
  } = useWishList(
    parseInt(String(courseId)),
    parseInt(String(course.price)),
    course.title,
  );

  const toggleShareModal = () => setIsOpenShareModal(prev => !prev);

  const handleClick = () => {
    if (shouldBuy) {
      handlePayment();
    } else {
      onClick();
    }
  };

  const handlePayment = () => {
    if (!localStorage.getItem(TOKEN)) {
      switchModal();
      return;
    }

    setIsOpenPaymentModal(true);
  };

  // 변경된 기획에 따라 제거, 추후에 하부 description에 추가될 예정 (230406 래영)
  // const contentInfo = useMemo(
  //   () => ({
  //     time: {
  //       icon: 'icTime',
  //       name: '소요 시간',
  //       unit: '분',
  //       value: Math.round(totalDurations),
  //     },
  //     volume: {
  //       icon: 'icCard',
  //       name: '코딩 분량',
  //       unit: '단계',
  //       value: lecturesCount,
  //     },
  //   }),
  //   [lecturesCount, totalDurations],
  // );

  return (
    <>
      <section className={css.banner}>
        <div className={css.contentContainer}>
          <div className={css.thumbnail} onClick={handleClick}>
            <img src={coverImage} alt={title} />
          </div>
          <section className={css.information}>
            <div>
              <p className={css.subtitle}>간편 코딩 저스트코드</p>
              <h1
                className={css.title}
                dangerouslySetInnerHTML={createMarkup(title)}
              />
            </div>
            <div>
              {!!discountPercentage && (
                <>
                  <p className={css.discountPercentage}>
                    {SALE_MESSAGE}, {discountPercentage}% 할인 ✨
                  </p>
                  <p className={css.originalPrice}>
                    {originalPrice.toLocaleString()}원
                  </p>
                </>
              )}
              <p className={css.price}>
                {originalPrice === 0 ? '무료' : `${price.toLocaleString()}원`}
              </p>
              <Button
                className={css.startBtn}
                size="full"
                width="100%"
                onClick={handleClick}
              >
                {btnText}
              </Button>
            </div>
            <div>
              <p className={css.courseTitle}>{title}</p>
              <p className={css.introduction}>{introduction}</p>
            </div>
            <PurchaseSection
              courseData={{ course }}
              actions={{
                handleAddToCart,
                changeHeart,
                handleShare: toggleShareModal,
              }}
            />
            {/* 변경된 기획에 따라 제거, 추후에 하부 description에 추가될 예정 (230406 래영) */}
            {/* <ul className={css.infoList}>
            {Object.entries(contentInfo).map(([key, value]) => (
              <li key={key} className={css.infoItem}>
                <Icon name={value.icon as IconName} size={56} />
                <div className={css.infoText}>
                  <strong>
                    {value.value}
                    {value.unit}
                  </strong>
                  <span>{value.name}</span>
                </div>
              </li>
            ))}
          </ul> */}
          </section>
        </div>
      </section>
      {/* FIXME: 간소화 필요 (230131 래영) */}
      {isOpenShareModal && (
        <Modal switchModal={toggleShareModal}>
          <div className={css.shareModal}>
            <AlertHeader title="강의 공유하기" switchModal={toggleShareModal} />
            <div className={css.shareButtonWrapper}>
              <ShareButton course={course} />
            </div>
          </div>
        </Modal>
      )}
      {isOpenPaymentModal && (
        <PaymentModal
          purchaseList={[{ productId, name: title, price }]}
          onClose={() => setIsOpenPaymentModal(false)}
        />
      )}
      {isWishlistModal && <LoginModal switchModal={switchWishlistModal} />}
      {isOpenModal && <LoginModal switchModal={switchModal} />}
      {isCartModal && <LoginModal switchModal={switchCartModal} />}
    </>
  );
};

export default Banner;
