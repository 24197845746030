import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from 'Components/core/Footer';
import GNB from 'Components/core/GNB';
// import GoogleRedirect from 'Components/core/GoogleLogin/GoogleRedirect';
import KakaoRedirect from 'Components/core/KakaoLogin/KakaoRedirect';
import Alert from 'Components/core/ModalPortal/Alert';
import Cart from 'Pages/Cart';
import ClassPage from 'Pages/ClassPage/ClassPage';
import Completed from 'Pages/CurriculumRouter/Completed';
import CurriculumDetail from 'Pages/CurriculumRouter/CurriculumDetail';
import Login from 'Pages/CurriculumRouter/Login';
import Payment from 'Pages/CurriculumRouter/Payment';
import Result from 'Pages/CurriculumRouter/Result';
import Session from 'Pages/CurriculumRouter/Session';
import Home from 'Pages/Home/Home';
import Mypage from 'Pages/Mypage';
import NotFound from 'Pages/NotFound';
import SocialLogin from 'Pages/SocialLogin';
import Term from 'Pages/Term/Term';
import TestPage from 'Pages/Test.page';
import TossFail from 'Pages/Toss/Fail/TossFail';
import TossSuccess from 'Pages/Toss/Success/TossSuccess';
// import LevelTest from 'Pages/LevelTest/LevelTest';
// import TestResult from 'Pages/TestResult/TestResult';
import CourseResult from 'Pages/CourseResult/CourseResult';
import CourseLoading from 'Pages/CourseLoading';
import LevelSelect from 'Pages/LevelSelect';
import DeprecatedSession from 'Pages/CurriculumRouter/DeprecatedSession';
import Subscription from 'Pages/Subscription/Subscription';
import DeprecatedMypage from 'Pages/DeprecatedMypage';
import { alertVar } from 'store/Alert';
import { PATH } from 'utils/constants/routes';
import ChannelTalkProvider from './ChannelTalkProvider';
import ScrollToTop from './ScrollToTop';

const Router = () => {
  const alertModal = useReactiveVar(alertVar);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <GNB />
      <ChannelTalkProvider>
        <Routes>
          <Route path={PATH.HOME.base} element={<Home />} />
          <Route path={PATH.CLASS.base} element={<ClassPage />} />
          {/* <Route path="/auth/google" element={<GoogleRedirect />} /> */}
          <Route path={PATH.AUTH.kakao} element={<KakaoRedirect />} />
          <Route path={PATH.LOGIN.base} element={<SocialLogin />} />
          <Route path={PATH.LOGIN.contentLogin} element={<Login />} />
          <Route path={PATH.CURRICULUM.detail} element={<CurriculumDetail />} />
          <Route path={PATH.COURSE.detail} element={<DeprecatedSession />} />
          <Route path={PATH.COURSE.myContent} element={<DeprecatedSession />} />
          <Route path={PATH.COURSE.completed} element={<Completed />} />
          <Route path={PATH.COURSE.payment} element={<Payment />} />
          <Route path={PATH.CONTENT.base} element={<Session />} />
          <Route path={PATH.CONTENT.detail} element={<Session />} />
          <Route path={PATH.APP.detail} element={<Result />} />
          <Route path={PATH.SHARING.detail} element={<Result />} />
          <Route path={PATH.TOSS.success} element={<TossSuccess />} />
          <Route path={PATH.TOSS.fail} element={<TossFail />} />
          <Route
            path={PATH.DEPRECATED_MYPAGE.tab}
            element={<DeprecatedMypage />}
          />
          <Route path={PATH.MYPAGE.base} element={<Mypage />} />
          <Route path={PATH.CART.base} element={<Cart />} />
          <Route path={PATH.SUBSCRIPTION.base} element={<Subscription />} />
          <Route path={PATH.TERM.base} element={<Term />} />
          <Route path={PATH.LEVEL_SELECT.step} element={<LevelSelect />} />
          {/* <Route path={PATH.LEVEL_TEST.base} element={<LevelTest />} />
          <Route path={PATH.TEST_RESULT.step} element={<TestResult />} /> */}
          <Route path={PATH.CONTENT_RESULT.base} element={<CourseResult />} />
          <Route path={PATH.CONTENT_LOADING.base} element={<CourseLoading />} />
          <Route
            path={PATH.SUBSCRIPTION_TEST.base}
            element={<Subscription />}
          />
          <Route
            path="/test/asidfjlasijeflaisejflisjaefliajslfiejfjsei23213431283883"
            element={<TestPage />}
          />
          <Route path={PATH.NOTFOUND.base} element={<NotFound />} />
        </Routes>
      </ChannelTalkProvider>
      <Footer />
      {alertModal.show && <Alert />}
    </BrowserRouter>
  );
};

export default Router;
