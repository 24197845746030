import { InMemoryCache } from '@apollo/client';
import { loadingVar } from 'store/Loading';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        loading: {
          read() {
            return loadingVar();
          },
        },
      },
    },
  },
});

export default cache;
