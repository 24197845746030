/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import OpenGraph from 'Components/core/Helmet/OpenGraph';
import { GET_COURSE, GET_COURSE_CONTENT } from 'gql/course/query';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { customAnswersVar, submittedAnswersVar } from 'store/Course';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import { RESULT_COURSES } from 'utils/constants/temp';
import { Course, CourseContent } from './type';
import DeprecatedContents from '../DeprecatedContents';
import css from './DeprecatedSession.module.scss';

const { dataLayer }: any = window;

const DeprecatedSession = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { courseId, contentId } = useParams();
  const isLoggedIn = !!localStorage.getItem(TOKEN);
  const hasResult = RESULT_COURSES.includes(Number(courseId));

  const { data, loading, refetch } = useQuery<{ course: Course }>(GET_COURSE, {
    variables: {
      id: Number(courseId),
      isLastContent: false,
    },
    context: { endpoint: 'course' },
    fetchPolicy: 'network-only',
    onCompleted: ({ course }) => {
      dataLayer.push({
        event: 'class_completion_percentage',
        class_percent: course.progress,
        class_name: course.title,
      });

      const hasContentId = course.courseContents.some(
        ({ id }) => id === Number(contentId),
      );

      const firstContentId = course.courseContents[0].id;

      if (!hasContentId) {
        navigate(
          `${PATH.COURSE.base}/${courseId}/${
            course.latestInProgressContentId || firstContentId
          }`,
          {
            replace: true,
          },
        );
      }
    },
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  const [getCourseContent, { data: contentData, error }] = useLazyQuery<{
    courseContent: CourseContent;
  }>(GET_COURSE_CONTENT, {
    context: { endpoint: 'course' },
    onCompleted: ({ courseContent }) => {
      if (
        courseContent.status === 'COMPLETED' &&
        courseContent.editor.answers &&
        hasResult
      ) {
        submittedAnswersVar(
          courseContent.editor.answers?.flatMap(({ answers }) => answers),
        );
      } else {
        submittedAnswersVar([]);
      }

      customAnswersVar(courseContent.userData ?? {});
    },
  });

  const prevSequence =
    (data?.course.courseContents.find(({ id }) => id === Number(contentId))
      ?.sequence ?? 0) - 1;

  const preCourseContentId =
    data?.course.courseContents.find(
      ({ sequence }) => sequence === prevSequence,
    )?.id ?? null;

  useEffect(() => {
    if (!contentId) return;

    sessionStorage.removeItem('page');

    getCourseContent({
      variables: {
        id: Number(contentId),
        preCourseContentId,
      },
      fetchPolicy: 'no-cache',
    });

    return () => {
      sessionStorage.removeItem('page');
    };
  }, [contentId, preCourseContentId]);

  useEffect(() => {
    if (!data) return;

    if (shouldLogin) {
      if (!isLoggedIn) {
        sessionStorage.setItem('current-location', location.pathname);
        navigate(PATH.LOGIN.contentLogin);

        return;
      }

      sessionStorage.setItem('current-location', location.pathname);
      navigate(`${PATH.COURSE.base}/${courseId}/payment`);
    }
  }, [error]);

  useEffect(() => {
    if (!contentData) return;

    const isLastContent =
      contentData?.courseContent.sequence ===
      data?.course.courseContents[data?.course.courseContents.length - 1]
        .sequence;

    refetch({ id: Number(courseId), isLastContent });
  }, [contentData]);

  const shouldLogin =
    error?.message === 'NOT_AUTHORIZED' || error?.message === 'LOGIN_REQUIRED';

  if (shouldLogin) {
    return <></>;
  }

  if (loading || !data || !contentData) return null;

  const { courseContent } = contentData;

  const contentList = data.course.courseContents;

  return (
    <div className={css.container}>
      <OpenGraph
        title={data.course.title}
        description={data.course.introduction}
        image={data.course.ogImage}
      />
      <DeprecatedContents
        courseData={data.course}
        courseContent={courseContent}
        contentList={contentList}
      />
    </div>
  );
};

export default DeprecatedSession;
