import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import Button from 'Components/core/Button';
import Icon from 'Components/core/Icon/Icon';
import { usePromiseAlert } from 'Components/core/ModalPortal/usePromiseAlert';
import { GET_COURSE_COMPLETED } from 'gql/course/query';
import { CourseCompletedType } from 'gql/course/type';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import { RESULT_COURSES } from 'utils/constants/temp';
import ContentHeader from '../Components/ContentHeader';
import css from './Completed.module.scss';

const Completed = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const isLoggedIn = localStorage.getItem(TOKEN);

  const showModal = usePromiseAlert({
    type: 'warn',
    dialog: AlertMessage.common.error.nonAuthorizationPage,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      showModal().then(() => navigate(PATH.LOGIN.base));
    }
  }, []);

  const { data, loading, error } = useQuery<{ course: CourseCompletedType }>(
    GET_COURSE_COMPLETED,
    {
      variables: {
        id: Number(courseId),
      },
      context: { endpoint: 'course' },
      fetchPolicy: 'cache-first',
      onError: () => {
        alertVar({
          show: true,
          type: 'error',
          dialog: AlertMessage.common.error.unknown,
          hasHelpEmailInfo: true,
        });
      },
    },
  );

  if (loading || !data || error || !isLoggedIn) return null;

  const { title, appId } = data.course;
  const hasResult = RESULT_COURSES.includes(Number(courseId));

  return (
    <div className={css.container}>
      <div className={css.headerText}>
        <ContentHeader contentTitle={title} />
      </div>
      <div className={css.completed}>
        <Icon name="completed" />
        <p className={css.title}>{hasResult ? '완성' : '완료'}했습니다!</p>
        <div className={css.description}>
          {hasResult ? '코드를 완성하셨습니다.' : '문제를 모두 해결하셨습니다.'}
          <br />또 다른 코딩에도 도전해보세요.
        </div>
        {hasResult && (
          <Button
            size="large"
            variant="primary"
            onClick={() => navigate(`${PATH.APP.base}/${appId}`)}
            width="240px"
          >
            내 결과물 보기
          </Button>
        )}
      </div>
      <Button
        className={css.homeBtn}
        size="full"
        variant="lineGrey"
        onClick={() => navigate(PATH.HOME.base)}
        width="112px"
        height="40px"
      >
        첫 페이지로
      </Button>
    </div>
  );
};

export default Completed;
