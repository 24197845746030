import React, { useState } from 'react';
import Button from 'Components/core/Button';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import PaymentModal from 'Components/core/PaymentModal';
import Icon from 'Components/core/Icon/Icon';
import { TOKEN } from 'utils/constants';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import LoginModal from '../../CurriculumDetail/LoginModal';
import css from './ContentHeader.module.scss';

interface Props {
  contentTitle: string;
  productId?: number;
  price?: number;
  purchased?: boolean;
  report?: () => void;
}

const ContentHeader = ({
  contentTitle,
  productId,
  price,
  purchased,
  report,
}: Props) => {
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const { isOpenModal, switchModal } = useSwitchModal();

  const onClickPayment = () => {
    if (!localStorage.getItem(TOKEN)) {
      switchModal();
      return;
    }

    setIsOpenPaymentModal(true);
  };

  const handleReportModal = () => {
    alertVar({
      show: true,
      type: 'info',
      dialog: AlertMessage.course.info.report,
      hasCancelButton: true,
      confirmText: '신고하기',
      onSuccess: report,
    });
  };

  const closeModal = () => setIsOpenPaymentModal(false);

  const hasPurchaseBtn = !!price && !!productId;

  return (
    <header className={css.container}>
      <span className={css.title}>{contentTitle}</span>
      {report && (
        <Button
          className={css.reportBtn}
          variant="lineGrey"
          onClick={handleReportModal}
        >
          <Icon name="impactLine" size={18} /> 문제가 이상해요
        </Button>
      )}
      {hasPurchaseBtn && (
        <>
          {!purchased && (
            <Button variant="lineGrey" size="small" onClick={onClickPayment}>
              구매하기
            </Button>
          )}
          {isOpenPaymentModal && (
            <PaymentModal
              purchaseList={[{ productId, name: contentTitle, price }]}
              onClose={closeModal}
            />
          )}
        </>
      )}
      {isOpenModal && <LoginModal switchModal={switchModal} />}
    </header>
  );
};

export default ContentHeader;
