/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ShareData } from 'Components/core/ShareModal/type';
import { shareToKakao, shareToFacebook, copyToClipboard } from 'utils/share';
import { cn, cond } from 'utils/styles';
import FacebookLink from 'assets/common/Facebook_logo.png';
import FacebookLinkDiasbled from 'assets/common/Facebook_logo_disabled.png';
import KakaoLink from 'assets/common/Kakao_logo.png';
import KakaoLinkDisabled from 'assets/common/Kakao_logo_disabled.png';
import DefaultLink from 'assets/common/Link.png';
import DefaultLinkDisabled from 'assets/common/Link_disabled.png';
import css from './Share.module.scss';

const { dataLayer }: any = window;

interface Props {
  course: ShareData;
  width?: string;
  disabled?: boolean;
}

const Share = ({ course, width, disabled = false }: Props) => {
  const { title, introduction, thumbnail } = course;

  const LINK_DATA = [
    {
      id: 1,
      name: 'kakao link',
      socialName: '카카오톡',
      imgUrl: disabled ? KakaoLinkDisabled : KakaoLink,
      onClick: () => {
        dataLayer.push({
          event: 'share',
          class_name: title,
        });

        shareToKakao(title, introduction, thumbnail);
      },
    },
    {
      id: 2,
      name: 'facebook link',
      socialName: '페이스북',
      imgUrl: disabled ? FacebookLinkDiasbled : FacebookLink,
      onClick: () => {
        dataLayer.push({
          event: 'share',
          class_name: title,
        });

        shareToFacebook();
      },
    },
    {
      id: 3,
      name: 'default link',
      socialName: 'URL 복사',
      imgUrl: disabled ? DefaultLinkDisabled : DefaultLink,
      onClick: () => {
        dataLayer.push({
          event: 'share',
          class_name: title,
        });

        copyToClipboard();
      },
    },
  ];

  return (
    <ul className={css.linkBox} style={{ width }}>
      {LINK_DATA.map(data => {
        const { id, name, imgUrl, socialName, onClick } = data;
        return (
          <li
            key={id}
            className={cn(css.shareBtn, cond(disabled, css.disabled))}
            onClick={disabled ? undefined : onClick}
          >
            <img
              src={imgUrl}
              alt={name}
              className={cn(css.linkImg, cond(disabled, css.disabled))}
            />
            <p className={cn(css.type, cond(disabled, css.disabled))}>
              {socialName}
            </p>
          </li>
        );
      })}
    </ul>
  );
};

export default Share;
