import React from 'react';
import { EditProfileFormData } from './type';

export const useInputChangeValue = () => {
  const [formData, setFormData] = React.useState<EditProfileFormData>({
    name: '',
    phoneNumber: '',
    email: '',
    profileImage: '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return { handleChange, formData, setFormData };
};
