import { gql } from '@apollo/client';

export const UPDATE_USER_DATA = gql`
  mutation updateUserData($courseId: Float!, $data: JSONObject!) {
    updateUserData(courseId: $courseId, data: $data) {
      data
    }
  }
`;

export const PRESIGNED_URL = gql`
  mutation presignedUrl {
    presignedUrl {
      presignedUrl
    }
  }
`;

export const CREATE_COURSE_EVALUATION = gql`
  mutation createCourseEvaluation(
    $courseId: Float!
    $evaluationTypeId: Float!
  ) {
    createCourseEvaluation(
      courseId: $courseId
      evaluationTypeId: $evaluationTypeId
    ) {
      courseEvaluationId
    }
  }
`;

export const CREATE_LEVEL_TEST_SCORE = gql`
  mutation createLevelTestScore(
    $score: Float!
    $levelTestType: LevelTestType!
  ) {
    createLevelTestScore(score: $score, levelTestType: $levelTestType) {
      score
      levelTestType
    }
  }
`;

export const CREATE_USER_APP_PERMISSION = gql`
  mutation createUserAppPermission($sharingCode: String!) {
    createUserAppPermission(sharingCode: $sharingCode) {
      appId
    }
  }
`;

export const UPDATE_USER_APP_PERMISSION = gql`
  mutation updateUserAppPermission(
    $courseId: Float!
    $permission: UserAppPermissionValue!
  ) {
    updateUserAppPermission(courseId: $courseId, permission: $permission) {
      id
    }
  }
`;

export const CREATE_SHARING_URL = gql`
  mutation createSharingUrl($courseId: Float!) {
    createSharingUrl(courseId: $courseId) {
      id
      sharingUrl
    }
  }
`;

export const UPDATE_COURSE_CONTENT_STATUS = gql`
  mutation updateCourseContentStatus($id: Float!) {
    updateCourseContentStatus(id: $id) {
      status
    }
  }
`;

export const UPDATE_USER_ASSIGNMENT_STATUS = gql`
  mutation updateUserAssignmentStatus(
    $assignmentId: Float!
    $scenario: UpdateUserAssignmentStatusScenario!
    $isCorrect: Boolean!
    $userIncorrectAnswer: [UserIncorrectAnswerInput!]
  ) {
    updateUserAssignmentStatus(
      assignmentId: $assignmentId
      scenario: $scenario
      isCorrect: $isCorrect
      userIncorrectAnswer: $userIncorrectAnswer
    ) {
      insertId
      affectedRows
    }
  }
`;

export const UPDATE_ASSIGNMENT_RETRY_COUNT = gql`
  mutation updateAssignmentRetryCount($assignmentId: Float!) {
    updateAssignmentRetryCount(assignmentId: $assignmentId) {
      affectedRows
    }
  }
`;

export const CREATE_USER_APP = gql`
  mutation createUserApp($courseId: Float!) {
    createUserApp(courseId: $courseId) {
      id
    }
  }
`;

export const DELETE_WISHLIST = gql`
  mutation deleteWishList($courseIds: [Float!]!) {
    deleteWishList(courseIds: $courseIds) {
      userId
    }
  }
`;

export const CREATE_BOOKMARK = gql`
  mutation createBookmark($courseContentId: Float!) {
    createBookmark(courseContentId: $courseContentId) {
      userId
    }
  }
`;

export const DELETE_BOOKMARK = gql`
  mutation deleteBookmark($courseContentId: Float!) {
    deleteBookmark(courseContentId: $courseContentId) {
      userId
    }
  }
`;

export const SUBMIT_LEARN_FINISH = gql`
  mutation updateCourseStatus($id: Float!) {
    updateCourseContentStatus(id: $id) {
      status
    }
  }
`;

export const GENERATE_ASSIGNMENT_FOR_USER = gql`
  mutation generateAssignmentForUser(
    $programmingLanguage: ProgrammingLanguage!
    $difficulty: AssignmentGenerationDifficulty!
    $chapter: String!
    $numberOfAssignments: Float!
    $number: Float!
  ) {
    generateAssignmentForUser(
      programmingLanguage: $programmingLanguage
      difficulty: $difficulty
      chapter: $chapter
      numberOfAssignments: $numberOfAssignments
      number: $number
    )
  }
`;

export const CREATE_ASSIGNMENT_REPORT = gql`
  mutation createAssignmentReport(
    $assignmentId: Float!
    $reportDetail: String
  ) {
    createAssignmentReport(
      assignmentId: $assignmentId
      reportDetail: $reportDetail
    )
  }
`;
