/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'Components/core/Button';
import Icon, { IconName } from 'Components/core/Icon/Icon';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import PaymentModal from 'Components/core/PaymentModal';
import { CREATE_WISH_LIST, DELETE_WISH_LIST } from 'gql/auth/mutation';
import { GET_COURSE } from 'gql/course/query';
import { GET_TOTAL_CART } from 'gql/payment/query';
import { CREATE_ORDER } from 'gql/payment/mutation';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import { cn, cond } from 'utils/styles';
import LoginModal from '../CurriculumDetail/LoginModal';
import { Product } from '../CurriculumDetail/type';
import { Course } from '../Session/type';
import coffee from 'assets/coffee.svg';
import css from './Payment.module.scss';
import { PAYMENT_BENEFITS } from './data';

interface Props {
  id?: number;
  isWrapped?: boolean;
  prevAction?: () => void;
}

const { dataLayer, kakaoPixel }: any = window;

const Payment = ({ id, isWrapped = false, prevAction }: Props) => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { isOpenModal, switchModal } = useSwitchModal();

  const { data } = useQuery<{ course: Course }>(GET_COURSE, {
    variables: {
      id: Number(courseId ?? id),
      isLastContent: false,
    },
    context: { endpoint: 'course' },
    fetchPolicy: 'cache-first',
  });

  const [createHeart] = useMutation(CREATE_WISH_LIST, {
    context: { endpoint: 'course' },
    onError(error) {
      if (error?.graphQLErrors[0]?.message === 'NOT_AUTHORIZED') {
        switchModal();
      }
    },
    onCompleted: () => {
      dataLayer.push({
        event: 'AddToWishlist',
        price: data?.course.price,
        productId: courseId ?? id,
        productName: data?.course.title,
      });

      kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_ID).addToWishList({
        id: courseId ?? id,
      });
    },
    refetchQueries: [
      {
        query: GET_COURSE,
        context: { endpoint: 'course' },
        variables: { id: Number(courseId ?? id), isLastContent: false },
      },
      'course',
    ],
  });

  const [deleteHeart] = useMutation(DELETE_WISH_LIST, {
    context: { endpoint: 'course' },
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
    refetchQueries: [
      {
        query: GET_COURSE,
        context: { endpoint: 'course' },
        variables: { id: Number(courseId ?? id), isLastContent: false },
      },
      'course',
    ],
  });

  const [createOrder] = useMutation(CREATE_ORDER, {
    context: {
      endpoint: 'payment',
    },
    onCompleted: data => {
      if (data.createOrder.status === 'PENDING') {
        dataLayer.push({
          event: 'AddToCart',
          price: data.createOrder.price,
          productId: data.createOrder.productId,
          productName: data.createOrder.name,
        });

        alertVar({
          show: true,
          type: 'positive',
          hasCancelButton: true,
          dialog: AlertMessage.cart.positive.success,
          onSuccess: () => navigate(PATH.CART.base),
        });
      }
    },
    refetchQueries: [
      {
        query: GET_TOTAL_CART,
        context: { endpoint: 'payment' },
      },
      'totalCartsCounts',
    ],
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.tryAgain,
        hasHelpEmailInfo: true,
      });
    },
  });

  const changeHeart = () => {
    if (wishListed) {
      deleteHeart({ variables: { courseIds: [Number(courseId ?? id)] } });
    } else {
      createHeart({ variables: { courseIds: [Number(courseId ?? id)] } });
    }
  };

  const goBack = () => {
    prevAction ? prevAction() : navigate(-2);
  };

  const goToCart = () => {
    if (!localStorage.getItem(TOKEN)) {
      switchModal();
      return;
    }

    alertVar({
      show: true,
      hasCancelButton: true,
      dialog: AlertMessage.cart.info.add,
      onSuccess: () => {
        createOrder({
          variables: {
            name: title,
            price,
            product: Product.COURSE,
            productId: Number(courseId ?? id),
          },
        });
      },
    });
  };

  if (!data) {
    return <></>;
  }

  const { title, price, wishListed } = data.course;

  const USER_BTN_LIST = [
    {
      id: 1,
      icon: wishListed ? 'heartActive' : 'emptyHeartBold',
      text: '찜하기',
      action: changeHeart,
    },
    {
      id: 2,
      icon: 'emptyShopCartBold',
      text: '장바구니',
      action: goToCart,
    },
  ];

  return (
    <>
      <div className={cn(css.container, cond(isWrapped, css.wrapped))}>
        <img className={css.img} src={coffee} alt="커피" />
        <div className={css.contentWrapper}>
          <h1 className={css.title}>결제가 필요합니다.</h1>
          <p className={css.description}>
            조금만 더 하면, 코드를 완성하고
            <br />
            동작하는 결과를 완성할 수 있어요.
          </p>
        </div>
        <div className={css.btnWrapper}>
          <Button
            className={css.paymentBtn}
            size="full"
            height="40px"
            onClick={() => setIsPaymentModalOpen(true)}
          >
            {price.toLocaleString()}원 결제
          </Button>
          <Button variant="lineGrey" size="full" height="40px" onClick={goBack}>
            뒤로
          </Button>
        </div>
        <div className={css.bottomContainer}>
          <div className={css.userBtnWrapper}>
            {USER_BTN_LIST.map(({ id, icon, text, action }) => (
              <div key={id} className={css.userBtn} onClick={action}>
                <Icon name={icon as IconName} />
                <span className={css.btnText}>{text}</span>
              </div>
            ))}
          </div>
          <div className={css.infoWrapper}>
            {/* 기획 변경에 따라 삭제 (230131 래영) */}
            {/* <p className={css.subTitle}>누구나 코딩을 할 수 있어요!</p>
            <div className={css.infoBox}>
              <div className={css.info}>
                <Icon name="icDifficult" />
                <p className={css.infoType}>분류</p>
                <p className={css.infoText}>타이머</p>
              </div>
              <div className={css.line} />
              <div className={css.info}>
                <Icon name="icTime" />
                <p className={css.infoType}>필요한 시간</p>
                <p className={css.infoText}>{totalDurations}분</p>
              </div>
            </div> */}
            <p className={css.subTitle}>결제 혜택</p>
            {PAYMENT_BENEFITS.map(({ id, text }) => (
              <p key={id} className={css.benefit}>
                <Icon name="LNBProcessCheck" />
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
      {isOpenModal && <LoginModal switchModal={switchModal} />}
      {isPaymentModalOpen && (
        <PaymentModal
          purchaseList={[
            { productId: Number(courseId ?? id), name: title, price },
          ]}
          onClose={() => setIsPaymentModalOpen(false)}
        />
      )}
    </>
  );
};

export default Payment;
