import React from 'react';
import { IconName } from 'Components/core/Icon/Icon';
import { PATH } from 'utils/constants/routes';
import WishList from './Content/WishList';
import MyCourseInfo from './Content/MyCourseInfo';
import PurchaseHistory from './Content/PurchaseHistory';
import EditProfile from './Content/EditProfile';
import Logout from './Content/Logout';

interface Tabs {
  id: number;
  title: string;
  path: string;
  iconName: IconName;
  content: JSX.Element;
}

export const TABS: Tabs[] = [
  // TODO: 마이페이지 기획 후 추가 시 수정 (230630 래영)
  // {
  //   id: 1,
  //   title: '내 정보',
  //   path: PATH.DEPRECATED_MYPAGE.myInfo,
  //   iconName: 'profile',
  //   content: <MyInfo />,
  // },
  {
    id: 2,
    title: '찜한 강의',
    path: PATH.DEPRECATED_MYPAGE.favorite,
    iconName: 'heartDefault',
    content: <WishList />,
  },
  // TODO: 북마크 관련 기획 재조정되면 사용 (230104 래영)
  // {
  //   id: 2,
  //   title: '북마크',
  //   path: PATH.DEPRECATED_MYPAGE.bookmark,
  //   iconName: 'bookmark',
  //   content: <BookmarkList />,
  // },
  {
    id: 3,
    title: '내 강의',
    path: PATH.DEPRECATED_MYPAGE.myCourse,
    iconName: 'history',
    content: <MyCourseInfo />,
  },
  {
    id: 4,
    title: '구매 내역',
    path: PATH.DEPRECATED_MYPAGE.purchaseHistory,
    iconName: 'receipt',
    content: <PurchaseHistory />,
  },
  // {
  //   id: 4,
  //   title: '비밀번호 변경',
  //   iconName: 'lock',
  //   content: <ChangePassword />,
  // },
  {
    id: 5,
    title: '회원정보 수정',
    path: PATH.DEPRECATED_MYPAGE.editProfile,
    iconName: 'profile',
    content: <EditProfile />,
  },
  {
    id: 6,
    title: '로그아웃',
    path: PATH.DEPRECATED_MYPAGE.logout,
    iconName: 'logout',
    content: <Logout />,
  },
];
