import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import MypageCard from 'Components/domain/Mypage/Card';
import ErrorPage from 'Components/core/ErrorPage';
import { GET_MY_COURSE_INFO } from 'gql/course/query';
import { CourseInfo } from 'gql/course/type';
import { PATH } from 'utils/constants/routes';
import NoContent from '../NoContent';
import css from './MyCourseInfo.module.scss';

function MyCourseInfo() {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery<{ myCourseInfo: CourseInfo[] }>(
    GET_MY_COURSE_INFO,
    {
      context: { endpoint: 'course' },
      fetchPolicy: 'no-cache',
    },
  );

  if (error) return <ErrorPage />;
  if (loading || !data) return null;

  const purchasedCourseList = data.myCourseInfo.filter(
    ({ purchased }) => purchased,
  );
  const experiencedCourseList = data.myCourseInfo.filter(
    ({ purchased }) => !purchased,
  );

  const COURSE_LIST = [
    {
      id: 1,
      title: '열람한 강의',
      noContent: (
        <NoContent
          icon="emptyBookmark"
          title="열람한 강의가 표시됩니다."
          subText="열람했던 모든 강의가 표시됩니다."
          width="96px"
        />
      ),
      list: experiencedCourseList,
    },
    {
      id: 2,
      title: '구매한 강의',
      noContent: (
        <NoContent
          icon="cartEmpty"
          title="구매한 강의가 표시됩니다."
          subText="코딩은 더 많은 가능성을 선사합니다."
          btnText="클래스 둘러보기"
          onClick={() => navigate(PATH.CLASS.base)}
          width="96px"
        />
      ),
      list: purchasedCourseList,
    },
  ];

  return (
    <div className={css.container}>
      {COURSE_LIST.map(({ id, title, noContent, list }) => (
        <section className={css.wrapper} key={id}>
          <p className={css.title}>{title}</p>
          {list.length === 0
            ? noContent
            : list.map(course => (
                <div key={course.id} className={css.cardBox}>
                  <MypageCard data={course} variant="myCourse" />
                </div>
              ))}
        </section>
      ))}
    </div>
  );
}

export default MyCourseInfo;
