import React from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'Components/core/ModalPortal/Modal';
import Icon from 'Components/core/Icon/Icon';
import Button from 'Components/core/Button/Button';
import { PATH } from 'utils/constants/routes';
import css from './DoneModal.module.scss';

interface Props {
  where: 'content' | 'home';
  purchased?: boolean;
  switchModal: () => void;
}

const DoneModal = ({ where, purchased, switchModal }: Props) => {
  const navigate = useNavigate();

  const BUTTONS: { [key in typeof where]: JSX.Element } = {
    content: (
      <>
        <Button
          className={css.button}
          size="full"
          variant="lineBase"
          onClick={() => navigate(PATH.HOME.base)}
        >
          홈으로
        </Button>
        <Button
          className={css.button}
          size="full"
          onClick={() => navigate(PATH.SUBSCRIPTION.base)}
        >
          확인
        </Button>
      </>
    ),
    home: (
      <Button
        className={css.button}
        size="full"
        onClick={() => navigate(PATH.SUBSCRIPTION.base)}
      >
        {purchased ? '구독하고 계속하기' : '1개월 무료 체험하기'}
      </Button>
    ),
  };

  return (
    <Modal switchModal={switchModal}>
      <div className={css.container}>
        <Icon className={css.resultIcon} name="result" />
        <div className={css.body}>
          <p className={css.title}>오늘 무료 문제를 다 풀었어요</p>
          <p className={css.description}>
            내일 오시면 10문제를 더 받을 수 있어요!
          </p>
        </div>
        <div className={css.btnWrapper}>{BUTTONS[where]}</div>
      </div>
    </Modal>
  );
};

export default DoneModal;
