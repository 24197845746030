import { URL_REG } from 'utils/constants';
import { checkRegExp } from './checkRegExp';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidURL(link: any): boolean {
  if (typeof link !== 'string') {
    return false;
  }

  return checkRegExp({ [link]: URL_REG });
}
