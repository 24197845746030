/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PriceBadge from 'Components/core/Badge/PriceBadge';
import { createMarkup } from 'utils/constants/createMarkup';
import { cn, cond } from 'utils/styles';
import { PATH } from 'utils/constants/routes';
import { ClassCourseInfo } from 'gql/course/type';
import css from './Promotion.module.scss';

const { dataLayer }: any = window;
interface Props {
  title: string;
  subtitle?: string;
  list: ClassCourseInfo[];
  size?: 'large' | 'small';
  titleColor?: 'white' | 'black';
  disabled?: boolean;
}

const Promotion = ({
  title,
  subtitle = '',
  list,
  size = 'large',
  titleColor = 'black',
  disabled = false,
}: Props) => {
  const navigate = useNavigate();

  const getContentPath = (courseId: number) =>
    `${PATH.CURRICULUM.detail.replace(':courseId', String(courseId))}`;

  if (list.length === 0) return null;

  return (
    <div className={css.itemContainer}>
      <div className={css.sectionItem}>
        <h5 className={css.sectionSubtitle}>{subtitle}</h5>
        <h2
          className={css.sectionTitle}
          dangerouslySetInnerHTML={createMarkup(title)}
        />
        <div className={cn(css.cardContainer, css[size])}>
          {list.map(({ id, images, title, price }) => (
            <div
              key={id}
              className={cn(css.card, css[size], cond(disabled, css.disabled))}
              onClick={() => {
                if (!disabled) {
                  dataLayer.push({
                    event: 'class_view',
                    class_name: title,
                    inflow: 'classpage_main',
                  });
                  navigate(getContentPath(id));
                }
              }}
            >
              <img
                className={cn(css.cardImg, css[size])}
                src={images.MEDIUM}
                alt={title}
                draggable={false}
                onContextMenu={e => e.preventDefault()}
              />
              <h2
                className={cn(css.courseTitle, css[size])}
                dangerouslySetInnerHTML={createMarkup(title)}
                style={{ color: titleColor }}
              />
              <PriceBadge price={price} className={css.priceBadge} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Promotion;
