interface QueryType {
  [paramskey: string]: string;
}

/**
 * 쿼리 스트링을 만들어주는 함수
 * @param queryParam 객체형태
 * @returns queryParam 객체에 대해서 쿼리스트링을 리턴
 */
export const convertQueryString = (queryParam: QueryType) => {
  return Object.entries(queryParam)
    .map(([paramsKey, paramsValue]) => `${paramsKey}=${paramsValue}`)
    .join('&');
};

/**
 * 쿼리 스트링을 객체로 만들어주는 함수
 * @param queryString 쿼리스트링
 * @returns 쿼리스트링을 객체로 리턴
 */
export const convertQueryObject = (queryString: string) => {
  return queryString
    .substring(1)
    .split('&')
    .map(query => query.split('='))
    .reduce((acc, [paramsKey, paramsValue]) => {
      acc[paramsKey] = paramsValue;
      return acc;
    }, {} as QueryType);
};
