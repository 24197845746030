import React, { useState } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import StepWrapper from 'Components/core/StepWrapper';
import Icon from 'Components/core/Icon/Icon';
import { DifficultyType, LanguageType } from 'Pages/LevelSelect/type';
import { cn, cond } from 'utils/styles';
import { GENERATE_ASSIGNMENT_FOR_USER } from 'gql/course/mutation';
import { PATH } from 'utils/constants/routes';
import {
  GET_UNCOMPLETED_ASSIGNMENT_IDS,
  GET_USER_LEARNING_DATA,
} from 'gql/course/query';
import { UserLearningData } from 'gql/course/type';
import { useScrollToFooter } from 'utils/hooks';
import css from './CourseLoading.module.scss';

type LocationStateType = { language: LanguageType; difficulty: DifficultyType };

const CourseLoading = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [currentIdx, setCurrentIdx] = useState(0);
  const randomChapter = CHAPTERS[Math.floor(Math.random() * CHAPTERS.length)];

  if (!state) {
    return <Navigate to={PATH.HOME.base} />;
  }

  const { difficulty, language } = state as LocationStateType;

  const { data: uncompletedAssignmentData } = useQuery<{
    uncompletedAssignmentIds: number[];
  }>(GET_UNCOMPLETED_ASSIGNMENT_IDS, {
    context: { endpoint: 'course' },
    fetchPolicy: 'network-only',
    onCompleted: ({ uncompletedAssignmentIds }) => {
      if (uncompletedAssignmentIds.length === 0) {
        getUserLearningData();
      } else {
        navigate(PATH.CONTENT.base);
      }
    },
  });

  const [getUserLearningData, { data: userData }] = useLazyQuery<{
    userLearningData: UserLearningData;
  }>(GET_USER_LEARNING_DATA, {
    context: { endpoint: 'course' },
    onCompleted: ({ userLearningData }) => {
      const { hasActiveSubscription } = userLearningData;

      handleGenerate(hasActiveSubscription);
    },
  });

  const [generateAssignmentForUser, { data, loading, error }] = useMutation(
    GENERATE_ASSIGNMENT_FOR_USER,
    {
      context: { endpoint: 'course' },
    },
  );

  const handleSubmit = () => {
    if (error && userData) {
      const { hasActiveSubscription } = userData.userLearningData;

      handleGenerate(hasActiveSubscription);

      return;
    }

    navigate(PATH.CONTENT.base);
  };

  const handleGenerate = (hasSubscription: boolean) => {
    if (loading) return;

    const numberOfAssignments = hasSubscription ? 5 : 5;
    const trial = hasSubscription ? 2 : 2;

    generateAssignmentForUser({
      variables: {
        programmingLanguage: language.toUpperCase(),
        difficulty: difficulty.toUpperCase(),
        chapter: randomChapter,
        numberOfAssignments,
        number: trial,
      },
    });
  };

  useScrollToFooter(data);
  useScrollToFooter(error);

  if (!uncompletedAssignmentData) return null;

  const isLoading = !error && (loading || !data);
  const title = isLoading
    ? '문제를 만드는 중이에요'
    : error
    ? '이런! 문제가 발생했어요'
    : '문제가 완성되었어요!';
  const guide = isLoading
    ? '잠시만 기다려 주세요!'
    : error
    ? '아래 버튼을 눌러 다시 시도해 주세요'
    : '버튼을 눌러 시작해 보세요';

  return (
    <StepWrapper
      submitText={error ? '다시 만들기' : '시작하기'}
      onSubmit={handleSubmit}
      disabled={isLoading}
    >
      <section className={css.container}>
        <div className={css.textWrapper}>
          <Icon name="codeDuckEyebrow" size={144} />
          <Icon
            className={cn(css.glasses, cond(isLoading, css.animation))}
            name="glasses"
            size={80}
          />
          <h1 className={css.title}>{title}</h1>
          <p className={css.greyText}>{guide}</p>
        </div>
        <div className={css.cardContainer}>
          {isLoading ? (
            <Swiper
              className={css.swiper}
              modules={[Autoplay]}
              autoplay={{
                delay: 1000,
                reverseDirection: true,
              }}
              onSlideChange={({ realIndex }) => setCurrentIdx(realIndex)}
              allowTouchMove={false}
              direction="vertical"
              slidesPerView={4}
              loop
            >
              {CARDS.map((_, idx) => {
                const isTop = idx === currentIdx;
                const isEven = idx % 2 === 0;

                return (
                  <SwiperSlide key={idx}>
                    <div
                      className={cn(
                        css.card,
                        cond(isTop && isEven, css.slideInLeft),
                        cond(isTop && !isEven, css.slideInRight),
                      )}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            CARDS.map((_, idx) => (
              <div
                key={idx}
                className={cn(css.card, cond(!error, css.axure))}
              />
            ))
          )}
        </div>
      </section>
    </StepWrapper>
  );
};

export default CourseLoading;

const CARDS = [1, 2, 3, 4];
const CHAPTERS = [
  '변수',
  '숫자 데이터 타입',
  '문자열 데이터 타입',
  'boolean 데이터 타입',
  '연산자',
  '함수',
  '객체',
  '조건문(if문)',
  '배열 데이터 타입',
  '반복문(for loop)',
  'while문',
];
