import React, { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'Components/core/Icon/Icon';
import { cn, cond } from 'utils/styles';
import css from './Button.module.scss';

export type BtnVariantType =
  | 'primary'
  | 'lineBase'
  | 'lineGrey'
  | 'ghost'
  | 'highlight';

export interface CoreButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'medium' | 'large' | 'full';
  variant?: BtnVariantType;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  margin?: string;
  width?: string;
  height?: string;
  animation?: boolean;
  isSelected?: boolean;
  check?: boolean;
  className?: string;
  to?: string;
  href?: string;
}

const Button = (props: CoreButtonProps) => {
  const {
    children,
    size = 'medium',
    variant = 'primary',
    type = 'button',
    disabled,
    margin,
    width,
    height,
    animation = false,
    isSelected = false,
    check = true,
    className = '',
    to,
    href,
    onClick,
    ...others
  } = props;

  if (to) {
    return (
      <Link
        data-testid="button"
        to={to}
        className={cn(css.base, css[size], css[variant], className)}
        style={{ margin, width, height }}
      >
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <a
        data-testid="button"
        href={href}
        className={cn(css.base, css[size], css[variant], className)}
        style={{ margin, width }}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <button
      data-testid="button"
      className={cn(
        css.base,
        //TODO: 모든 variant를 커버할 수 있도록 수정 - 2022.01.09 전하은
        css[isSelected ? 'primary' : variant],
        css[size],
        className,
        cond(isSelected, css.isSelected),
        cond(isSelected && animation, css.animation),
      )}
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{ margin, width, height }}
      {...others}
    >
      {isSelected && check && (
        <>
          <Icon name="LNBProcessCheck" />
          <div className={css.iconCover} />
        </>
      )}
      <span className={css.btnText}>{children}</span>
    </button>
  );
};

export default Button;
