import React from 'react';
import { getKakaoAuth } from './kakaoLogin.hook';
import { ReactComponent as KakaoLoginButton } from 'assets/common/login_kakao_offical.svg';
import KakaoLogo from 'assets/common/Logo_kakao_simple.png';
import css from './KakaoLogin.module.scss';

interface KakaoLoginProps {
  type?: 'square' | 'circle';
}

const KakaoLogin = ({ type = 'square' }: KakaoLoginProps) => {
  return (
    <button className={css[type + 'Btn']} onClick={getKakaoAuth}>
      {type === 'square' && <KakaoLoginButton />}
      {type === 'circle' && (
        <img className={css.circleLogo} src={KakaoLogo} alt="Kakao Logo" />
      )}
    </button>
  );
};

export default KakaoLogin;
