import React from 'react';
import Icon from 'Components/core/Icon/Icon';
import { cn, cond } from 'utils/styles';
import { LanguageType } from '../type';
import { LANGUAGES } from '../data';
import css from './LanguageSelect.module.scss';

interface Props {
  language: LanguageType;
  onSelect: (language: LanguageType, disabled?: boolean) => void;
}

const LanguageSelect = ({ language: selectedLanguage, onSelect }: Props) => {
  return (
    <div className={css.container}>
      {LANGUAGES.map(({ language, icon, recommended, disabled }) => {
        const isSelected = selectedLanguage === language;

        return (
          <div
            className={cn(css.card, cond(isSelected, css.selected))}
            key={language}
            onClick={() => onSelect(language, disabled)}
          >
            <div className={css.btnWrapper}>
              {recommended && (
                <span className={css.recommended}>입문 추천👍</span>
              )}
              {isSelected && (
                <Icon className={css.checkIcon} name="checkBlue" />
              )}
            </div>
            <Icon className={css.languageIcon} name={icon} />
            <span className={css.language}>{language}</span>
          </div>
        );
      })}
    </div>
  );
};

export default LanguageSelect;
