import React, { PropsWithChildren } from 'react';
import Button from 'Components/core/Button';
import { cn } from 'utils/styles';
import css from './StepWrapper.module.scss';

interface Props {
  submitText: string;
  align?: 'start' | 'end';
  disabled?: boolean;
  onSubmit: () => void;
}

const StepWrapper = ({
  submitText,
  align = 'start',
  disabled = false,
  children,
  onSubmit,
}: PropsWithChildren<Props>) => {
  return (
    <div className={css.container}>
      <div className={cn(css.contentWrapper, css[align])}>{children}</div>
      <Button
        className={css.button}
        size="full"
        disabled={disabled}
        onClick={onSubmit}
      >
        {submitText}
      </Button>
    </div>
  );
};

export default StepWrapper;
