import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from 'utils/hooks';
import { PATH } from 'utils/constants/routes';
import css from './Profile.module.scss';

const Profile = ({ data: profile }: { data?: UserProfile }) => {
  const navigate = useNavigate();

  // const { data } = useQuery<CartCountType>(GET_TOTAL_CART, {
  //   context: { endpoint: 'payment' },
  //   onError: () => {
  //     alertVar({
  //       show: true,
  //       type: 'error',
  //       dialog: AlertMessage.common.error.unknown,
  //       hasHelpEmailInfo: true,
  //     });
  //   },
  // });

  return (
    <>
      {profile && (
        // TODO: 데스크탑 디자인 완료되면 반영하면서 수정할 것(230714 래영)
        <div className={css.user} onClick={() => navigate(PATH.MYPAGE.base)}>
          <span className={css.greyText}>안녕하세요, </span>
          <span>{profile.userProfile.name || 'User'}</span>
          <span className={css.greyText}>님!</span>
        </div>
      )}
      {/* <div className={css.btnWrapper}>
        <div className={css.btn} onClick={() => navigate(PATH.CART.base)}>
          <Icon
            size={22}
            name={data?.totalCartsCounts ? 'cartActive' : 'emptyShopCartBold'}
          />
          {!!data?.totalCartsCounts && (
            <p className={css.countBadge}>{data.totalCartsCounts}</p>
          )}
        </div>
        <div
          className={cn(css.btn, css.round)}
          onClick={() => navigate(PATH.DEPRECATED_MYPAGE.favorite)}
        >
          <Icon name="my" size={25} />
        </div>
      </div> */}
    </>
  );
};

export default Profile;
