/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Icon from 'Components/core/Icon/Icon';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import LoginModal from 'Pages/CurriculumRouter/CurriculumDetail/LoginModal';
import { CourseType } from 'gql/course/type';
import { productInfoType } from 'utils/hooks';
import { Product } from '../../CurriculumDetail/type';
import css from './Purchase.module.scss';

const { dataLayer }: any = window;

interface Props {
  courseData: CourseType;
  actions: {
    handleAddToCart: (productInfo: productInfoType) => void;
    changeHeart: (data: { id: number; wishListed: boolean }) => void;
    handleShare: () => void;
  };
}

const PurchaseSection = ({ courseData, actions }: Props) => {
  const {
    id: productId,
    price,
    wishListed,
    title,
    purchased,
  } = courseData.course;
  const { handleAddToCart, changeHeart, handleShare } = actions;
  const { isOpenModal, switchModal } = useSwitchModal();

  const goToCart = () => {
    handleAddToCart({
      name: title,
      price,
      product: Product.COURSE,
      productId,
    });
  };

  const handleHeart = () => {
    dataLayer.push({
      event: 'like',
      class_name: courseData.course.title,
    });

    changeHeart({
      id: productId,
      wishListed,
    });
  };

  return (
    <>
      <div className={css.buttonWrapper}>
        <div className={css.iconWrapper} onClick={handleHeart}>
          <Icon name={wishListed ? 'heartFilled' : 'heart'} />
          <p>찜하기</p>
        </div>
        <div className={css.iconWrapper} onClick={handleShare}>
          <Icon name="share" />
          <p>공유하기</p>
        </div>
        {!purchased && (
          <div className={css.iconWrapper} onClick={goToCart}>
            <Icon name="shopCart" />
            <p>장바구니</p>
          </div>
        )}
      </div>
      {isOpenModal && <LoginModal switchModal={switchModal} />}
    </>
  );
};

export default PurchaseSection;
