import React from 'react';
import css from './Callout.module.scss';

interface Props {
  emoji: string;
  content: React.ReactNode[] | string;
}

const Callout: React.FC<Props> = ({ emoji, content }) => {
  return (
    <span className={css.callout}>
      <span className={css.iconBox}>{emoji}</span>
      <span>{content}</span>
    </span>
  );
};

export default Callout;
