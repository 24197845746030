import React from 'react';
import { cn } from 'utils/styles';
import css from './ProgressBar.module.scss';

interface Props {
  className?: string;
  progress: number;
  variant?: 'thin' | 'medium' | 'thick';
  hasPercentage?: boolean;
  color?: string;
  width?: string;
}

const ProgressBar = ({
  className = '',
  progress = 0,
  variant = 'thin',
  hasPercentage = false,
  color = '#3bc328',
  width,
}: Props) => {
  const [progressState, setProgressState] = React.useState(0);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setProgressState(progress);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [progress]);

  return (
    <>
      <div
        className={cn(css.progressBar, css[variant], className)}
        style={{ width }}
      >
        <div
          className={cn(css.progress, css[variant])}
          style={{ width: `${progressState}%`, backgroundColor: color }}
        />
      </div>
      {hasPercentage && (
        <span className={css.text} data-progress={progress}>
          {Math.floor(progress)}%
        </span>
      )}
    </>
  );
};

export default ProgressBar;
