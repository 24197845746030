import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'Components/core/Button';
import { PurchaseList } from 'Components/core/PaymentModal/type';
import { ApprovalPaymentsType } from 'gql/payment/type';
import client from 'gql/settings';
import { PATH } from 'utils/constants/routes';
import { STUDENT_COURSES, Target } from 'utils/constants/temp';
import {
  CREATE_BILLING_INFORMATION,
  CREATE_BILLING_PAYMENT,
  CREATE_INSTANT_PURCHASE,
  GET_APPROVAL_PAYMENTS,
} from 'gql/payment/mutation';
import { GET_HAS_PURCHASED, GET_TOTAL_CART } from 'gql/payment/query';
import { GET_SERVICE_PLAN } from 'gql/course/query';
import { ServicePlanType } from 'gql/course/type';
import { useGetUserProfile } from 'utils/hooks';
import CartCheck from 'assets/ic_cart_check.svg';
import css from './TossSuccess.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { dataLayer, kakaoPixel }: any = window;

const product = 'SERVICE_PLAN';

const TossSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isPurchased, setIsPurchased] = useState(false);
  const paymentMethod = searchParams.get('payMethod');
  const orderName = searchParams.get('orderName');
  const type = searchParams.get('type') as 'payment' | 'billing' | null;
  const authKey = searchParams.get('authKey');
  const purchaseList: PurchaseList[] = JSON.parse(
    sessionStorage.getItem('purchaseList') || '[]',
  );
  const { data: userProfile } = useGetUserProfile();

  const { data } = useQuery<{ servicePlan: ServicePlanType[] }>(
    GET_SERVICE_PLAN,
    {
      context: { endpoint: 'course' },
      variables: {
        servicePlanType: 'SUBSCRIPTION',
      },
      skip: type !== 'billing',
    },
  );

  const [getHasPurchased] = useLazyQuery<{ hasPurchased: boolean }>(
    GET_HAS_PURCHASED,
    {
      context: { endpoint: 'payment' },
    },
  );

  const [getApprovalPayment] = useMutation<ApprovalPaymentsType>(
    GET_APPROVAL_PAYMENTS,
    {
      refetchQueries: [
        {
          query: GET_TOTAL_CART,
          context: { endpoint: 'payment' },
        },
        'totalCartsCounts',
      ],
    },
  );

  const [createBillingInformation] = useMutation(CREATE_BILLING_INFORMATION, {
    context: { endpoint: 'payment' },
    variables: {
      authKey,
    },
  });

  const [createInstantPurchase] = useMutation(CREATE_INSTANT_PURCHASE, {
    context: {
      endpoint: 'payment',
    },
  });

  const [createBillingPayment] = useMutation(CREATE_BILLING_PAYMENT, {
    context: {
      endpoint: 'payment',
    },
    onCompleted: ({ createBillingPayment }) => {
      const { status } = createBillingPayment;

      if (status === 'DONE') setIsPurchased(true);
    },
  });

  const movePage = () => {
    if (authKey) {
      navigate(PATH.LEVEL_SELECT.language);

      return;
    }

    if (prevPath) {
      navigate(prevPath);
    } else {
      navigate(PATH.LEVEL_SELECT.language);
    }
  };

  const handlePayment = () => {
    client.refetchQueries({ include: [GET_TOTAL_CART] });

    if (!searchParams.has('paymentKey')) {
      setIsPurchased(true);

      return;
    }

    getApprovalPayment({
      variables: {
        paymentId: searchParams.get('paymentKey'),
        orderId: searchParams.get('orderId'),
        paymentMethod: paymentMethod,
        orderName: decodeURIComponent(orderName ? orderName : ''),
        amount: Number(searchParams.get('amount')),
        ...(searchParams.has('coupon') && {
          couponCode: searchParams.get('coupon'),
        }),
      },
      context: {
        endpoint: 'payment',
      },
      onError(error) {
        alert(error.message);
        navigate(-1);
      },
      onCompleted: () => {
        dataLayer.push({
          event: 'Purchase',
          price: Number(searchParams.get('amount')),
          productId: purchaseList[0].productId,
          productName: orderName,
          itemsCount: purchaseList.length,
        });

        dataLayer.push({
          event: 'purchase',
          ecommerce: {
            currency: 'KRW',
            value: searchParams.get('amount')?.toLocaleString(),
            transaction_id: searchParams.get('orderId'),
            coupon: searchParams.get('coupon') ? 'yes' : 'no',
            quantity: purchaseList.length,
            items: purchaseList.map(({ productId, name, price }) => {
              const target = STUDENT_COURSES.includes(productId)
                ? Target.student
                : Target.adult;

              return {
                item_name: name,
                item_id: productId,
                price,
                item_brand: 'justcode',
                target_audience: target,
              };
            }),
          },
        });

        kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_ID).purchase({
          total_quantity: purchaseList.length,
          total_price: searchParams.get('amount')?.toLocaleString(),
          products: purchaseList.map(({ productId, name, price }) => ({
            id: productId,
            name,
            quantity: '1',
            price,
          })),
        });

        sessionStorage.removeItem('purchaseList');
        sessionStorage.removeItem('formData');
        sessionStorage.removeItem('payMethod');
        setIsPurchased(true);
      },
    });
  };

  const handleBilling = async () => {
    if (!data) return;

    const { id: productId, name, price } = data.servicePlan[0];

    try {
      const { data: purchasedData } = await getHasPurchased({
        variables: {
          productId,
          product,
        },
      });
      const { data: instantPurchaseData } = await createInstantPurchase({
        variables: {
          name,
          price: purchasedData?.hasPurchased ? price : 0,
          product,
          productId,
        },
      });
      const { data: billingInformationData } = await createBillingInformation();

      createBillingPayment({
        variables: {
          billingInformationId:
            billingInformationData.createBillingInformation.billingId,
          orderId: instantPurchaseData.createInstantPurchase.id,
          productId,
          product,
          orderName: name,
          amount: purchasedData?.hasPurchased ? price : 0,
          customerName:
            userProfile?.userProfile.realName ||
            userProfile?.userProfile.name ||
            'justcoder',
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      alert(err.message);
      navigate(`${PATH.TOSS.fail}?type=${type}`);
    }
  };

  useEffect(() => {
    if (type === 'billing') {
      data && handleBilling();
    } else {
      handlePayment();
    }
  }, [data]);

  useEffect(() => {
    if (!isPurchased) return;

    const timer = setTimeout(() => {
      movePage();
    }, 3000);

    return () => clearTimeout(timer);
  }, [isPurchased]);

  if (!isPurchased) return <></>;
  const prevPath = sessionStorage.getItem('current-location');

  return (
    <div className={css.container}>
      <img alt="결제 성공" src={CartCheck} />
      <p className={css.title}>결제 완료</p>
      <p className={css.description}>
        결제에 성공하였습니다.
        <br />
        잠시 후 {prevPath ? '이전 페이지' : '학습 페이지'}로 이동합니다.
      </p>
      <div className={css.btnWrapper}>
        <Button size="large" onClick={movePage}>
          이동
        </Button>
      </div>
    </div>
  );
};

export default TossSuccess;
