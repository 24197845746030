import { useRef } from 'react';
import { AlertState, alertVar } from 'store/Alert';
import { noop } from 'utils';

export const usePromiseAlert = (props: AlertState) => {
  const { onSuccess, onClose } = props;
  const resolveRef = useRef<(value?: unknown) => void>(noop);

  const showAlert = () => {
    return new Promise(resolve => {
      resolveRef.current = resolve;

      alertVar({
        ...props,
        show: true,
        onSuccess: () => {
          onSuccess?.();
          resolve(true);
        },
        onClose: () => {
          resolve(false);
          onClose?.();
        },
      });
    });
  };

  return showAlert;
};
