import { IconName } from 'Components/core/Icon/Icon';
import { DifficultyType, LanguageType } from './type';

export const LANGUAGES: {
  language: LanguageType;
  icon: IconName;
  recommended?: boolean;
  disabled?: boolean;
}[] = [
  { language: 'Python', icon: 'python', recommended: true },
  { language: 'JavaScript', icon: 'javaScript' },
  { language: 'Java', icon: 'java' },
  { language: 'C', icon: 'c', disabled: true },
];

export const DIFFICULTIES: {
  difficulty: DifficultyType;
  text: string;
  description: string;
  example: {
    [language in LanguageType]: {
      question: string;
      code: string;
      disabled?: boolean;
    };
  };
}[] = [
  {
    difficulty: 'easy',
    text: '쉬움',
    description: '기본적인 문법을 학습하고 싶으신 분들께 추천드리는 문제예요.',
    example: {
      JavaScript: {
        question:
          '문장을 만들 때에는 쌍따옴표("")를 사용해야 해요. 빈칸에 알맞은 코드를 골라 문장을 만들어 보세요.',
        code: 'const name = [[blank]]김코드[[blank]];',
      },
      Python: {
        question:
          '문장을 만들 때에는 쌍따옴표("")를 사용해야 해요. 빈칸에 알맞은 코드를 골라 문장을 만들어 보세요.',
        code: 'name = [[blank]]김코드[[blank]]',
      },
      C: {
        question:
          '문장을 만들 때에는 쌍따옴표("")를 사용해야 해요. 빈칸에 알맞은 코드를 골라 문장을 만들어 보세요.',
        code: `#include <stdio.h>
int main() {
  printf("%d", 2 + 2 * 3);
  return 0;
}`,
      },
      Java: {
        question:
          '문장을 만들 때에는 쌍따옴표("")를 사용해야 해요. 빈칸에 알맞은 코드를 골라 문장을 만들어 보세요.',
        code: 'String name = [[blank]]김코드[[blank]]',
      },
    },
  },
  {
    difficulty: 'normal',
    text: '중간',
    description: '이미 기본적인 문법을 알고 계신 분이 풀 수 있는 문제예요.',
    example: {
      JavaScript: {
        question:
          '의도한 결과가 출력될 수 있도록 아래 빈 칸에 들어갈 알맞은 코드를 선택해 주세요.',
        code: `const elements = ["Fire", "Air", "Water"];
const elementsWithComma = elements.[[blank]]("[[blank]]");
        
console.log(elementsWithComma);
// "Fire,Air,Water"`,
        disabled: true,
      },
      Python: {
        question:
          '의도한 결과가 출력될 수 있도록 아래 빈 칸에 들어갈 알맞은 코드를 선택해 주세요.',
        code: `def generator(n):
    i = 0
    while i < n:
        if i % 2 == 0:
            yield i
        i += 1
for x in generator([[blank]]):
    print(x)
# 0
# 2
# 4
# 6
# 8`,
        disabled: true,
      },
      C: {
        question: '다음 코드의 실행 결과는 무엇일까요?',
        code: `#include <stdio.h>
int main() {
    int x = 10;
    int *p = &x;
    printf("%d", *p);
    return 0;
}`,
        disabled: true,
      },
      Java: {
        question: '',
        code: '',
        disabled: true,
      },
    },
  },
  {
    difficulty: 'hard',
    text: '어려움',
    description: '심화 문제를 풀고 싶으신 분들께 추천드리는 문제예요.',
    example: {
      JavaScript: {
        question:
          '다음 코드에서 console.log()에 순서대로 출력되는 값으로 올바른 것은?',
        code: `setTimeout(() => {
  console.log(1);
}, 100); 
console.log(2);
for (let i = 0; i < 3; i++) {
  loop();
}
setTimeout(() => {
  console.log(3);
}, 0); 
console.log(4);
function loop() {
  console.log(5);
}`,
        disabled: true,
      },
      Python: {
        question: '',
        code: '',
        disabled: true,
      },
      C: {
        question: '',
        code: '',
        disabled: true,
      },
      Java: {
        question: '',
        code: '',
        disabled: true,
      },
    },
  },
];
