/**
 * 아이콘을 import 할때에는 반드시 PascalCamelCase로 작성한다 (2022.10.22 YunKukPark)
 */
import { ReactComponent as CurriculumDone } from 'assets/icon/svg/lg/curriculum_done.svg';
import { ReactComponent as CurriculumReview } from 'assets/icon/svg/lg/curriculum_review.svg';
import { ReactComponent as Deny } from 'assets/icon/svg/lg/deny.svg';
import { ReactComponent as FileAdd } from 'assets/icon/svg/lg/file_add.svg';
import { ReactComponent as HomeworkUpload } from 'assets/icon/svg/lg/homework_upload.svg';
import { ReactComponent as MoviePause } from 'assets/icon/svg/lg/movie_pause.svg';
import { ReactComponent as MoviePlay } from 'assets/icon/svg/lg/movie_play.svg';
import { ReactComponent as MovieStop } from 'assets/icon/svg/lg/movie_stop.svg';
import { ReactComponent as Result } from 'assets/icon/svg/lg/result.svg';

export const LargeIcon = {
  CurriculumDone,
  CurriculumReview,
  Deny,
  FileAdd,
  HomeworkUpload,
  MoviePlay,
  MovieStop,
  MoviePause,
  Result,
};
