import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { sleep } from 'utils/index';

function ScrollToTop() {
  const { pathname } = useLocation();
  const [shouldScrollToTop, setShouldScrollToTop] = useState(true);

  useEffect(() => {
    const handlePopState = () => {
      setShouldScrollToTop(false);
    };

    const handleBeforeUnload = () => {
      setShouldScrollToTop(true);
    };

    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // TOFIX: 화면이 그려진 다음 동기적으로 처리하도록 수정해야 할 듯 (230310 래영)
  useEffect(() => {
    if (shouldScrollToTop) {
      sleep(200);
      window.scrollTo(0, 0);
    } else {
      setShouldScrollToTop(true);
    }
  }, [pathname]);

  return null;
}

export default ScrollToTop;
