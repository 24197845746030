export interface PurchaseList {
  id?: number;
  productId: number;
  price: number;
  name: string;
}

export type FormNameType = 'name' | 'phoneNumber' | 'email';

export type UserDataType = {
  id: number;
  title: string;
  name: FormNameType;
  content: string | undefined;
};

export type PaymentMethodType = 'card' | 'kakao' | 'naver' | 'toss';

export const payMethods: { [payment in PaymentMethodType]: string } = {
  card: 'CARD',
  toss: 'TOSSPAY',
  kakao: 'KAKAOPAY',
  naver: 'NAVERPAY',
} as const;

export enum PaymentMethod {
  CARD = 'CARD',
  EASY_PAY = 'EASY_PAY',
  FREE = 'FREE',
}
