import React, { CSSProperties } from 'react';
import {
  FileTabs,
  SandpackCodeEditor,
  SandpackPreview,
  SandpackProvider,
  useSandpack,
} from '@codesandbox/sandpack-react';
import Button from 'Components/core/Button';
import Icon from 'Components/core/Icon/Icon';
import { EDITOR_THEME } from 'utils/fingereditor';
import css from './MarkdownCodeEditor.module.scss';

interface Props {
  files: Record<string, string>;
}

const MarkdownCodeEditor = ({ files }: Props) => {
  return (
    <SandpackProvider
      className={css.container}
      customSetup={{ entry: '/index.html' }}
      options={{ initMode: 'lazy', autorun: false, recompileMode: 'delayed' }}
      files={{
        ...files,
        'sandbox.config.json': {
          code: '{ "template": "parcel" }',
          hidden: true,
        },
      }}
      theme={EDITOR_THEME}
    >
      <CodeEditor />
    </SandpackProvider>
  );
};

// useSandpack을 사용하기 위해 컴포넌트 분리
const CodeEditor = () => {
  const { sandpack, dispatch } = useSandpack();
  const { status, runSandpack } = sandpack;

  const onClickRunButton = () => runSandpack();
  const handleRefresh = () => dispatch({ type: 'refresh' });

  return (
    <>
      <FileTabs className={css.fileTabs} />
      <SandpackCodeEditor
        style={EDITOR_STYLE}
        showLineNumbers
        showInlineErrors
        showRunButton={false}
        showTabs={false}
      />
      <div className={css.controller}>
        <Button
          size="large"
          className={css.runButton}
          onClick={onClickRunButton}
        >
          <div className={css.buttonText}>
            <Icon name="resultPlay" />
            <span>코드 실행</span>
          </div>
        </Button>
        <button className={css.refreshButton} onClick={handleRefresh}>
          <Icon name="reset" />
        </button>
      </div>
      {status === 'running' && (
        <SandpackPreview
          className={css.preview}
          showOpenInCodeSandbox={false}
          showRefreshButton={false}
        />
      )}
      {/* <SandpackConsole /> */}
    </>
  );
};

const EDITOR_STYLE: CSSProperties = {
  borderRadius: '8px',
};

export default MarkdownCodeEditor;
