import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'Components/core/Button';
import KakaoLogin from 'Components/core/KakaoLogin';
import { cn, cond } from 'utils/styles';
import coffee from 'assets/coffee.svg';
import css from './Login.module.scss';

interface Props {
  isWrapped?: boolean;
  prevAction?: () => void;
}

const Login = ({ isWrapped = false, prevAction }: Props) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-2);
  };

  return (
    <div className={cn(css.container, cond(isWrapped, css.wrapped))}>
      <img className={css.img} src={coffee} alt="커피" />
      <div className={css.contentWrapper}>
        <h1 className={css.title}>
          카카오로 3초만에 로그인하고
          <br />
          무료로 계속 이어가세요!
        </h1>
        <p className={css.description}>
          지금 바로 로그인하고,
          <br />
          코드를 완성해볼까요?
        </p>
      </div>
      <div className={css.btnWrapper}>
        <KakaoLogin />
        <Button
          variant="lineGrey"
          size="full"
          width="280px"
          height="40px"
          onClick={prevAction || goBack}
        >
          뒤로
        </Button>
      </div>
    </div>
  );
};

export default Login;
