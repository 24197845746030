/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-empty-function */
declare global {
  interface Window {
    ChannelIO: any;
    ChannelIOInitialized: any;
    attachEvent: any;
  }
}

class ChannelService {
  constructor() {
    this.loadScript();
  }

  loadScript() {
    (function () {
      const w = window;
      if (w.ChannelIO) {
        return w.console.error('ChannelIO script included twice.');
      }
      const ch: {
        (): void;
        q: any[];
        c(args: any): void;
      } = function () {
        ch.c(arguments);
      };
      ch.q = [];
      ch.c = function (args: any) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        const x = document.getElementsByTagName('script')[0];
        if (x.parentNode) {
          x.parentNode.insertBefore(s, x);
        }
      }
      if (document.readyState === 'complete') {
        l();
      } else {
        w.addEventListener('DOMContentLoaded', l);
        w.addEventListener('load', l);
      }
    })();
  }

  boot() {
    window.ChannelIO('boot', {
      pluginKey: process.env.REACT_APP_CHANNEL_PLUGIN_KEY,
    });
  }

  shutdown() {
    window.ChannelIO('shutdown');
  }

  showMessenger() {
    window.ChannelIO('showMessenger');
  }

  hideMessenger() {
    window.ChannelIO('hideMessenger');
  }

  openChat(chatId: any, message: any) {
    window.ChannelIO('openChat', chatId, message);
  }

  track(eventName: any, eventProperty: any) {
    window.ChannelIO('track', eventName, eventProperty);
  }

  onShowMessenger(callback: any) {
    window.ChannelIO('onShowMessenger', callback);
  }

  onHideMessenger(callback: any) {
    window.ChannelIO('onHideMessenger', callback);
  }

  onBadgeChanged(callback: any) {
    window.ChannelIO('onBadgeChanged', callback);
  }

  onChatCreated(callback: any) {
    window.ChannelIO('onChatCreated', callback);
  }

  onFollowUpChanged(callback: any) {
    window.ChannelIO('onFollowUpChanged', callback);
  }

  onUrlClicked(callback: any) {
    window.ChannelIO('onUrlClicked', callback);
  }

  clearCallbacks() {
    window.ChannelIO('clearCallbacks');
  }

  updateUser(userInfo: any, callback: any) {
    window.ChannelIO('updateUser', userInfo, callback);
  }

  addTags(tags: any, callback: any) {
    window.ChannelIO('addTags', tags, callback);
  }

  removeTags(tags: any, callback: any) {
    window.ChannelIO('removeTags', tags, callback);
  }

  setPage(page: any) {
    window.ChannelIO('setPage', page);
  }

  resetPage() {
    window.ChannelIO('resetPage');
  }

  showChannelButton() {
    window.ChannelIO('showChannelButton');
  }

  hideChannelButton() {
    window.ChannelIO('hideChannelButton');
  }

  setAppearance(appearance: any) {
    window.ChannelIO('setAppearance', appearance);
  }
}

export default new ChannelService();
