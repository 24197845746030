import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isValidURL } from 'utils/validation/checkValid';

export interface Props {
  link: string;
  children: React.ReactNode;
  isReference: boolean;
}

const LinkToSubContentBox = ({ link, children, isReference }: Props) => {
  const navigate = useNavigate();
  const isURL = isValidURL(link);

  return (
    <>
      {isURL ? (
        <a href={link} target="_blank" rel="noreferrer" className="linkBox">
          {children}
        </a>
      ) : (
        <span
          onClick={() => navigate(link)}
          className={isReference ? 'reference' : 'linkBox'}
        >
          {children}
        </span>
      )}
    </>
  );
};

export default LinkToSubContentBox;
