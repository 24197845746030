import React from 'react';
import css from './LinkHavingComment.module.scss';

interface Props {
  comment: string;
  children: React.ReactNode;
}

const LinkHavingComment = ({ comment, children }: Props) => {
  return (
    <div className={css.container}>
      {children}
      <div className={css.commentWrapper}>
        <img alt="comment icon" src="/images/comment.png" />
        <span className={css.comment}>{comment}</span>
      </div>
    </div>
  );
};

export default LinkHavingComment;
