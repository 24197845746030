import React, { useState } from 'react';
import Icon, { IconName } from 'Components/core/Icon/Icon';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import PaymentModal from 'Components/core/PaymentModal';
import LoginModal from 'Pages/CurriculumRouter/CurriculumDetail/LoginModal';
import { Course } from 'Pages/CurriculumRouter/DeprecatedSession/type';
import { TOKEN } from 'utils/constants';
import { productInfoType } from 'utils/hooks/useCart';
import { cond } from 'utils/styles';
import { Product } from '../../CurriculumDetail/type';
import css from './Purchase&.module.scss';

interface Props {
  courseData: Course;
  actions: {
    handleAddToCart: (productInfo: productInfoType) => void;
    changeHeart: (data: { id: number; wishListed: boolean }) => void;
  };
}

const ContentsPurchase = ({ courseData, actions }: Props) => {
  const { id: productId, price, wishListed, title, purchased } = courseData;
  const { handleAddToCart, changeHeart } = actions;

  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const { isOpenModal, switchModal } = useSwitchModal();

  const onClickPayment = () => {
    if (!localStorage.getItem(TOKEN)) {
      switchModal();
      return;
    }

    setIsOpenPaymentModal(true);
  };

  const goToCart = () => {
    handleAddToCart({
      name: title,
      price,
      product: Product.COURSE,
      productId,
    });
  };

  const handleHeart = () => {
    changeHeart({
      id: productId,
      wishListed,
    });
  };

  const closeModal = () => setIsOpenPaymentModal(false);

  if (purchased) return null;

  const BTN_LIST = [
    {
      title: '찜하기',
      onClick: handleHeart,
      iconName: wishListed ? 'heartActive' : 'heartDefault',
      isEmphasized: false,
    },
    {
      title: '장바구니',
      onClick: goToCart,
      iconName: 'emptyShopCartBold',
      isEmphasized: false,
    },
    {
      title: '바로 구매',
      onClick: onClickPayment,
      iconName: 'wonPrice',
      isEmphasized: true,
    },
  ];

  return (
    // Contents 의 자식요소에게 border를 넣어주기 위해 <>가 아닌 <div> 감싸줌 Yunkuk
    // Contents.module.scss .playground 참고
    <div>
      <div className={css.buttonWrapper}>
        {BTN_LIST.map(({ title, onClick, iconName, isEmphasized }, idx) => (
          <div key={idx} className={css.iconWrapper}>
            <div className={css.icon} onClick={onClick}>
              <Icon size={24} name={iconName as IconName} />
              <p className={cond(isEmphasized, css.emphasized)}>{title}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={css.salesPitch}>
        <p>구매하시면, 모든 과정을 완료하고</p>
        <p>결과물을 완성할 수 있습니다.</p>
      </div>
      {isOpenPaymentModal && (
        <PaymentModal
          purchaseList={[{ productId, name: title, price }]}
          onClose={closeModal}
        />
      )}
      {isOpenModal && <LoginModal switchModal={switchModal} />}
    </div>
  );
};

export default ContentsPurchase;
