import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { Kakao }: any = window;

export const shareToKakao = (
  title: string,
  introduction: string,
  thumbnail: string,
) => {
  Kakao?.Share?.sendDefault({
    objectType: 'feed',
    content: {
      title: title,
      description: introduction,
      imageUrl: thumbnail,
      link: {
        mobileWebUrl: window.location.href,
        webUrl: window.location.href,
      },
    },
    buttons: [
      {
        title: '웹으로 보기',
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
    ],
  });
};

export const shareToFacebook = (link?: string) => {
  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${
      link || window.location.href
    }`,
  );
};

export const copyToClipboard = async (link?: string, showAlert = true) => {
  try {
    await navigator.clipboard.writeText(link || window.location.href);
    showAlert &&
      alertVar({
        show: true,
        dialog: AlertMessage.common.info.copiedUrl,
      });
  } catch (error) {
    showAlert &&
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.copiedError,
      });
  }
};
