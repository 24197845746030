import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePromiseAlert } from 'Components/core/ModalPortal/usePromiseAlert';
import { AlertMessage } from 'lib/constant/alertMessage';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import Content from './Content';
import LNB from './LNB';
import css from './DeprecatedMypage.module.scss';

function DeprecatedMypage() {
  const navigate = useNavigate();
  const showModal = usePromiseAlert({
    type: 'info',
    dialog: AlertMessage.common.warn.needLogin,
  });
  const isLoggedIn = !!localStorage.getItem(TOKEN);

  useEffect(() => {
    if (!isLoggedIn) {
      showModal().then(() => {
        sessionStorage.setItem('current-location', location.pathname);
        navigate(PATH.LOGIN.base);
      });
    }
  }, [localStorage.getItem(TOKEN)]);

  if (!isLoggedIn) return <></>;

  return (
    <div className={css.container}>
      <LNB />
      <Content />
    </div>
  );
}

export default DeprecatedMypage;
