import { gql } from '@apollo/client';

export const GET_CART = gql`
  query {
    myCart {
      id
      userId
      orders {
        id
        productId
        name
        price
      }
    }
  }
`;

export const GET_TOTAL_CART = gql`
  query {
    totalCartsCounts
  }
`;

export const APPLY_COUPON = gql`
  query coupon($originalPrice: Float!, $code: String!) {
    coupon(originalPrice: $originalPrice, code: $code) {
      id
      name
      description
      discountedPrice
    }
  }
`;

export const GET_PAYMENT_HISTORY = gql`
  query {
    paymentHistory {
      orderId
      orderName
      amount
      orders {
        id
        productId
        name
        price
      }
      method
      approvedAt
      receiptUrl
      refundable
    }
  }
`;

export const GET_CUSTOMER_KEY = gql`
  query customerKey {
    customerKey {
      customerKey
    }
  }
`;

export const GET_HAS_PURCHASED = gql`
  query hasPurchased($productId: Float!, $product: Product!) {
    hasPurchased(productId: $productId, product: $product)
  }
`;
