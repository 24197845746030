import React from 'react';
import { useLocation } from 'react-router-dom';
import { TABS } from '../data';
import css from './Content.module.scss';

function Content() {
  const { pathname } = useLocation();

  return (
    <div className={css.container}>
      <p className={css.mypageText}>마이페이지</p>
      <h1 className={css.title}>
        {TABS.find(({ path }) => path === pathname)?.title}
      </h1>
      <div className={css.content}>
        {TABS.find(({ path }) => path === pathname)?.content}
      </div>
    </div>
  );
}

export default Content;
