import React from 'react';
import Pagination from 'Components/core/Pagination/Pagination';
import { ContentType } from 'Pages/CurriculumRouter/DeprecatedSession/type';

interface Props {
  sequence: number;
  latestInProgressContentSequence: number;
  contentList: ContentType[];
  goToContentWithSequence: (seq: number) => void;
}

const ContentsNavigator = ({
  sequence,
  latestInProgressContentSequence,
  contentList,
  goToContentWithSequence,
}: Props) => {
  const completedPageList = contentList.reduce((acc, { status, sequence }) => {
    if (status === 'COMPLETED') {
      acc.push(sequence);
    }

    return acc;
  }, [] as number[]);

  const disabledPageList = contentList.reduce(
    (accumulator, { status, sequence }) => {
      if (
        status !== 'COMPLETED' &&
        sequence !== latestInProgressContentSequence
      ) {
        accumulator.push(sequence);
      }
      return accumulator;
    },
    [] as number[],
  );

  return (
    <Pagination
      currentPage={sequence}
      totalPage={contentList.length}
      pageCountPerView={5}
      completedPageList={completedPageList}
      disabledPageList={disabledPageList}
      onChange={goToContentWithSequence}
    />
  );
};

export default ContentsNavigator;
