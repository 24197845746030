export const removeHyphen = (str: string | undefined) =>
  str?.replaceAll('-', '');

export const hyphenateMobileNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return;

  const regExp = /^(\d{2,3})(\d{3,4})(\d{4})$/;
  return phoneNumber.replace(regExp, '$1-$2-$3');
};

export const jwtDecode = (token: string | undefined) => {
  if (!token) return null;

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const trim = (str: string) => str.trim();

/**
 *
 * @param input
 * <codeeditor>
 * \`\`\`index.jsx
 * const a = 1;
 * \`\`\`
 * \`\`\`style.css
 * .a { display: block; }
 * \`\`\`
 * \`\`\`index.html
 * <html>
 *   <body>
 *     <h1 class="a">소통해요</h1>
 *   </body>
 * </html>
 * \`\`\`
 * </codeeditor>
 * @returns {'index.html': '...', 'index.jsx': '...', 'style.css': '...'}
 */
export const parseCode = (input: string) => {
  const files: Record<string, string> | undefined = {};

  const regex = /```([\w-.w]+)[\s\S]*?```/g;
  let match;
  while ((match = regex.exec(input)) !== null) {
    const fileContent = match[0]
      .replace(/```[\w-.w]+/, '')
      .replace(/```/, '')
      .trim();
    const fileName = match[1];

    files[fileName] = fileContent;
  }

  return Object.keys(files).length > 0 ? files : {};
};

export const escapeHTML = (text: string) => {
  return text.replace(/[&<>'"]/g, entity => {
    switch (entity) {
      case '&':
        return '&amp;';
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      default:
        return entity;
    }
  });
};

export const convertBadQuotes = (text: string) => {
  // eslint-disable-next-line quotes
  return text.replace(/[“”]/g, '"').replace(/[‘’]/g, "'");
};
