import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'Components/core/Icon/Icon';
import WecodeLogo from 'Components/core/Icon/Logo/Wecode';
import { ModalPortal } from 'Components/core/ModalPortal/Modal';
import { TOKEN } from 'utils/constants';
import { useLockBodyScroll } from 'utils/hooks';
import BeforeLoginContent from '../BeforeLoginContent';
import { GNB_ITEMS } from '../data';
import css from './MenuModal.module.scss';

interface Props {
  switchModal: () => void;
}

const MenuModal = ({ switchModal }: Props) => {
  const isLogin = !!localStorage.getItem(TOKEN);
  const navigate = useNavigate();

  useLockBodyScroll();

  return (
    <ModalPortal>
      <section className={css.container} onClick={switchModal}>
        <article onClick={e => e.stopPropagation()} className={css.modal}>
          <nav className={css.nav}>
            <div className={css.logoContainer}>
              <WecodeLogo onClick={switchModal} beta />
            </div>
            <Icon name="close" onClick={switchModal} />
          </nav>
          <div className={css.educationWrapper}>
            {GNB_ITEMS.map(({ id, path, content, shouldLogin }) => {
              if (!isLogin && shouldLogin) return;

              return (
                <button
                  className={css.educationItem}
                  type="button"
                  key={id}
                  onClick={() => {
                    navigate(path);
                    switchModal();
                  }}
                >
                  {content}
                </button>
              );
            })}
          </div>
          {!isLogin && <BeforeLoginContent />}
        </article>
      </section>
    </ModalPortal>
  );
};

export default MenuModal;
