import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { cond, cn } from 'utils/styles';
import { TABS } from '../data';
import css from './LNB.module.scss';

function LNB() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className={css.container}>
      <h1 className={css.title}>My Page</h1>
      {TABS.map(({ id, title, path }) => (
        <span
          key={id}
          className={cn(css.tab, cond(pathname === path, css.current))}
          onClick={() => navigate(path)}
        >
          {title}
        </span>
      ))}
    </div>
  );
}

export default LNB;
