import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'Components/core/Button';
import ErrorPage from 'Components/core/ErrorPage';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import PaymentModal from 'Components/core/PaymentModal';
import { GET_COURSE_DETAIL_PAGE } from 'gql/course/query';
import { CourseType, FreemiumStatus } from 'gql/course/type';
import { PATH } from 'utils/constants/routes';
import { KAKAO_OPEN_CHAT } from 'utils/constants/links';
import { TOKEN } from 'utils/constants';
import Banner from './Banner';
// import CurriculumIndex from './CurriculumIndex';
import FAQ from './FAQ';
import Content from '../Components/MarkdownContent';
import LoginModal from './LoginModal';
import InformationModal from './InformationModal/InformationModal';
import phoneCoding from 'assets/device_img_01@2x.png';
import css from './CurriculumDetail.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { kakaoPixel }: any = window;

const CurriculumDetail = () => {
  const navigate = useNavigate();
  const { isOpenModal, switchModal } = useSwitchModal();
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [isOpenFakeSellingModal, setIsOpenFakeSellingModal] = useState(false);
  const { courseId } = useParams();
  const isLoggedIn = localStorage.getItem(TOKEN);

  const { loading, error, data } = useQuery<CourseType>(
    GET_COURSE_DETAIL_PAGE,
    {
      variables: { id: Number(courseId) },
      context: {
        endpoint: 'course',
      },
      fetchPolicy: 'network-only',
      onCompleted: ({ course: { courseContents, purchased } }) => {
        const isPurchasedFakeSelling = courseContents.length === 0 && purchased;

        setIsOpenFakeSellingModal(isPurchasedFakeSelling);
      },
    },
  );

  const getBtnText = (
    status: FreemiumStatus | 'FAKE',
    purchased: boolean,
    isFree: boolean,
  ) => {
    switch (status) {
      case 'FREE':
      case 'LOGIN_REQUIRED':
        return purchased ? '학습하기' : '무료로 시작하기';

      case 'AUTHORIZATION_REQUIRED':
      case 'FAKE':
        if (purchased) {
          return status === 'FAKE' ? '안내 확인하기' : '학습하기';
        } else {
          return isFree ? '무료로 시작하기' : '결제하기';
        }

      default:
        throw new Error(`Invalid status: ${status}`);
    }
  };

  const goToCourse = () => {
    if (isFakeCourse && purchased) {
      setIsOpenFakeSellingModal(true);
      return;
    }

    const shouldLogin =
      courseContents[0].freemiumStatus === FreemiumStatus.LOGIN_REQUIRED &&
      !isLoggedIn;

    if (shouldLogin) {
      switchModal();
      return;
    }

    navigate(`${PATH.COURSE.base}/${courseId}`);
  };

  const handlePayment = () => {
    if (!isLoggedIn) {
      switchModal();
      return;
    }

    setIsOpenPaymentModal(true);
  };

  useEffect(() => {
    kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_ID).viewContent({
      id: courseId,
    });
  }, []);

  if (error) return <ErrorPage />;
  if (loading || !data) return null;

  const {
    id: productId,
    title,
    price,
    description,
    courseContents,
    purchased,
  } = data.course;

  const isFakeCourse = courseContents.length === 0;
  const shouldBuy =
    (isFakeCourse ||
      courseContents[0].freemiumStatus ===
        FreemiumStatus.AUTHORIZATION_REQUIRED) &&
    (!isLoggedIn || !purchased);
  const btnText = getBtnText(
    isFakeCourse ? 'FAKE' : courseContents[0].freemiumStatus,
    purchased,
    price === 0,
  );
  const FAKE_SELLING_INFORMATION = `  
${title} 클래스를 선택해주셔서 감사합니다. 현재 해당 클래스는 수강인원을 모집 중입니다. 클래스가 준비되는 대로 담당 매니저가 먼저 알려드리겠습니다. 오픈 예정일은 2023년 6월 30일 금요일입니다.
<br/><br/>
저희 클래스를 기대하셨을 텐데 불편을 드려 죄송합니다. 클래스가 준비되기 전에, 다른 유료 클래스를 바로 수강할 수 있도록 혜택 쿠폰을 드리겠습니다.<br/>물론, 환불도 언제든 가능합니다. 환불을 원하시면 채널톡을 통해 메시지를 보내주세요. 신속히 처리해 드리겠습니다.
<br/><br/>
다시 한번, 선택해 주셔서 감사합니다. 
<br/><br/>
<li>채널톡 문의 시간 : 평일 오전 10시 ~ 오후 10시</li>
<li style="">무료 쿠폰 발급 안내 : 카카오톡 채널 <strong><u>저스트코드</u></strong>를 통해 메시지를 남겨주시면, 쿠폰을 발급해 드립니다.</li>`;

  return (
    <>
      <Banner
        shouldBuy={shouldBuy}
        btnText={btnText}
        onClick={goToCourse}
        {...data}
      />
      <div className={css.contentWrapper}>
        <Content content={description} />
        {/* 목차 임시 제거 (230418 래영) */}
        {/* <CurriculumIndex contents={courseContents} /> */}
        <FAQ />
      </div>
      <div className={css.startBanner}>
        <p className={css.startBannerTitle}>코딩, 바로 시작하세요!</p>
        <p className={css.startBannerDescription}>
          복잡한 프로그램 설치 환경 설정은 NO!
          <br />
          이제 버튼만 누르면 코딩할 수 있어요.
        </p>
        <Button
          className={css.startBannerBtn}
          size="large"
          onClick={shouldBuy ? handlePayment : goToCourse}
        >
          {btnText}
        </Button>
        <img className={css.startBannerImg} src={phoneCoding} alt="간편코딩" />
      </div>
      <div className={css.openChatBanner}>
        <p className={css.openChatBannerTitle}>개발자에게 물어보세요.</p>
        <p className={css.openChatBannerDescription}>
          저스트코드 개발자가 해답을 함께 찾아드릴게요!
          <br />
          카카오톡 오픈 채팅방에 참여하여 질문해 주세요.
        </p>
        <a
          className={css.openChatBannerBtn}
          href={KAKAO_OPEN_CHAT}
          target="_blank"
          rel="noreferrer"
        >
          저스트코드 코딩 질문방
        </a>
        <p className={css.openChatBannerPassword}>비밀번호는 justcode예요!</p>
      </div>
      {isOpenPaymentModal && (
        <PaymentModal
          purchaseList={[{ productId, name: title, price }]}
          onClose={() => setIsOpenPaymentModal(false)}
        />
      )}
      {isOpenModal && <LoginModal switchModal={switchModal} />}
      {isOpenFakeSellingModal && (
        <InformationModal
          title="모집 중인 클래스입니다."
          text={FAKE_SELLING_INFORMATION}
          switchModal={() => setIsOpenFakeSellingModal(prev => !prev)}
        />
      )}
    </>
  );
};

export default CurriculumDetail;
