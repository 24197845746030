/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQuery } from '@apollo/client';
import Button from 'Components/core/Button';
import { PATH } from 'utils/constants/routes';
import { GET_ALL_COURSE_INFO } from 'gql/course/query';
import { ClassCourseType } from 'gql/course/type';
import Promotion from './Promotion/Promotion';
import css from './ClassPage.module.scss';
import { SLIDE_DATA } from './data';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/autoplay';

const { dataLayer }: any = window;

export enum ClassStatus {
  UP_COMING = 'UPCOMING',
  BASIC = 'BASIC',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
  FAKE = 'FAKE',
}

const getClassList = (theme: string) => {
  const queryData = useQuery<ClassCourseType>(GET_ALL_COURSE_INFO, {
    variables: { theme },
    context: { endpoint: 'course' },
  });
  return queryData;
};

const ClassPage = () => {
  const { data: fakeList } = getClassList(ClassStatus.FAKE);
  const { data: basicList } = getClassList(ClassStatus.BASIC);
  const { data: beginnerList } = getClassList(ClassStatus.BEGINNER);
  const { data: upComingList } = getClassList(ClassStatus.UP_COMING);

  const navigate = useNavigate();

  if (!fakeList || !basicList || !beginnerList || !upComingList) return null;

  return (
    <div>
      <div style={{ maxWidth: '100vw' }}>
        <header className={css.landing} />
        <section className={css.editorSection}>
          <div className={css.wrapper}>
            <Swiper
              pagination={{ clickable: true }}
              autoplay={{
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              modules={[Pagination, Autoplay]}
              loop
            >
              {SLIDE_DATA.map(({ id, lg, md, sm, name }, index) => (
                <SwiperSlide key={index}>
                  <div className={css.sliderItem}>
                    <picture
                      className={css.imageWrapper}
                      onClick={() => {
                        dataLayer.push({
                          event: 'class_view',
                          class_name: name,
                          inflow: 'classpage_promotion',
                        });
                        navigate(`${PATH.CURRICULUM.detailBase}/${id}`);
                      }}
                    >
                      <source media="(max-width: 525px)" srcSet={sm} />
                      <source media="(max-width: 1023px)" srcSet={md} />
                      <img
                        className={css.slideImg}
                        src={lg}
                        alt={`slide${index}`}
                      />
                    </picture>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      </div>
      <div className={css.btnWrapper}>
        <Button
          className={css.myCourseBtn}
          variant="lineGrey"
          onClick={() => navigate(PATH.DEPRECATED_MYPAGE.myCourse)}
        >
          내 강의 보기
        </Button>
      </div>
      <section className={css.section}>
        <Promotion
          title="나에게 딱 필요한 코딩 배우기 🍭"
          subtitle="다양하게 접해봐요!"
          list={fakeList.allCourseInfo}
          titleColor="white"
        />
        <Promotion
          title="처음부터 확실하게 배우기 🤘🏻"
          subtitle="기초부터 탄탄하게!"
          list={basicList.allCourseInfo}
          titleColor="white"
        />
        <Promotion
          title="입문자용 코딩 배우기 🙌🏻"
          subtitle="코딩이 처음이라면 이 코스는 어떠세요?"
          list={beginnerList.allCourseInfo}
          titleColor="white"
        />
        <Promotion
          title="오픈 예정<br class='onlyMobile'/> NEW 코딩 콘텐츠 🎉"
          subtitle="곧 만나보실 수 있어요!"
          list={upComingList.allCourseInfo}
          titleColor="white"
          disabled
        />
      </section>
    </div>
  );
};

export default ClassPage;
