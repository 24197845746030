import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'Components/core/Button';
import Icon from 'Components/core/Icon/Icon';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import css from './Logout.module.scss';

function Logout() {
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem(TOKEN);
    alertVar({
      show: true,
      dialog: AlertMessage.auth.positive.logout,
    });

    navigate(PATH.HOME.base);
  };

  return (
    <div className={css.container}>
      <Icon name="smile" />
      <p className={css.title}>지금 로그아웃 하시겠습니까?</p>
      <div className={css.description}>내일 다시 만나요!</div>
      <Button size="large" onClick={logOut}>
        로그아웃
      </Button>
    </div>
  );
}

export default Logout;
