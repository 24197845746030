import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_PAYMENT_HISTORY } from 'gql/payment/query';
import { PaymentHistoryType } from 'gql/payment/type';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { PATH } from 'utils/constants/routes';
import Payment from './Payment';

import RefundModal from './RefundModal';
import NoContent from '../NoContent';
import css from './PurchaseHistory.module.scss';

function PurchaseHistory() {
  const navigate = useNavigate();
  const [isRefundModal, setIsRefundModal] = useState(false);

  const { data, loading } = useQuery<{
    paymentHistory: PaymentHistoryType[];
  }>(GET_PAYMENT_HISTORY, {
    context: { endpoint: 'payment' },
    fetchPolicy: 'network-only',
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  if (loading || !data) return null;

  return (
    <div className={css.container}>
      {data.paymentHistory.length === 0 ? (
        <NoContent
          icon="emptyPurchased"
          title="구매 내역을 볼 수 있어요."
          subText="코딩은 더 많은 가능성을 선사합니다."
          btnText="강의 골라보기"
          onClick={() => navigate(PATH.CLASS.base)}
          width="96px"
        />
      ) : (
        data.paymentHistory.map(paymentData => {
          return (
            <Payment
              key={paymentData.orderId}
              paymentData={paymentData}
              openModal={() => setIsRefundModal(true)}
            />
          );
        })
      )}
      {isRefundModal && (
        <RefundModal closeModal={() => setIsRefundModal(false)} />
      )}
    </div>
  );
}

export default PurchaseHistory;
