import React from 'react';
import { FingerEditor } from '@wecode-codingbootcamp/finger-editor';
import Icon from 'Components/core/Icon/Icon';
import { cn, cond } from 'utils/styles';
import { createMarkup } from 'utils/constants/createMarkup';
import { DifficultyType, LanguageType } from '../type';
import { DIFFICULTIES } from '../data';
import css from './DifficultySelect.module.scss';

interface Props {
  difficulty: DifficultyType;
  language: LanguageType;
  onSelect: (difficulty: DifficultyType, disabled?: boolean) => void;
}

const DifficultySelect = ({
  difficulty: selectedDifficulty,
  language,
  onSelect,
}: Props) => {
  const {
    text,
    description,
    example: selectedExample,
  } = DIFFICULTIES.find(({ difficulty }) => selectedDifficulty === difficulty)!;

  const { code, question } = selectedExample[language];

  return (
    <>
      <section className={css.selectionContainer}>
        {DIFFICULTIES.map(({ difficulty, text, example }) => {
          const isSelected = selectedDifficulty === difficulty;
          const { disabled } = example[language];

          return (
            <div
              className={cn(
                css.difficultyCardWrapper,
                cond(isSelected, css.selected),
              )}
              key={difficulty}
              onClick={() => onSelect(difficulty, disabled)}
            >
              <div className={cn(css.difficultyCard, css[difficulty])}>
                {isSelected && (
                  <Icon className={css.checkIcon} name="checkBlue" />
                )}
                <span className={css.difficulty}>{text}</span>
              </div>
            </div>
          );
        })}
      </section>
      <section className={css.exampleContainer}>
        <div className={css.questionWrapper}>
          <span className={cn(css.difficultyTag, css[selectedDifficulty])}>
            {text}
          </span>
          <span
            className={css.question}
            dangerouslySetInnerHTML={createMarkup(question)}
          />
        </div>
        <div className={css.exampleCode}>
          <FingerEditor
            className="notSubmitted"
            assignmentCode={code}
            submittedAnswers={[]}
            showLineNumbers={false}
            // TODO: 언어별 syntax highlighting할 수 있도록 FingerEditor에서 sandpack version upgrade해야 함 (230703 래영)
            filename="example.js"
          />
        </div>
        <div className={css.descriptionWrapper}>
          <Icon className={css.helpLogo} name="questionHelp" />
          <div className={css.exampleDescription}>{description}</div>
        </div>
      </section>
    </>
  );
};

export default DifficultySelect;
