import { PurchaseList } from 'Components/core/PaymentModal/type';
import { STUDENT_COURSES, Target } from './constants/temp';

export const getPurchaseAudience = (purchaseList: PurchaseList[]) => {
  const isStudentCourse = purchaseList.every(({ productId }) =>
    STUDENT_COURSES.includes(productId),
  );
  const isAdultCourse = purchaseList.every(
    ({ productId }) => !STUDENT_COURSES.includes(productId),
  );

  const audience = isStudentCourse
    ? Target.student
    : isAdultCourse
    ? Target.adult
    : Target.others;

  return audience;
};
