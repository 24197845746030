/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'Components/core/Button';
import { Input, LabeledInput } from 'Components/core/Forms';
import Icon, { IconName } from 'Components/core/Icon/Icon';
import Modal from 'Components/core/ModalPortal/Modal';
import { Product } from 'Pages/CurriculumRouter/CurriculumDetail/type';
import { UPDATE_USER_INFO } from 'gql/auth/mutation';
import { CREATE_INSTANT_PURCHASE, CREATE_PAYMENT } from 'gql/payment/mutation';
import { APPLY_COUPON } from 'gql/payment/query';
import { CREATE_ORDER_GROUP } from 'gql/payment/mutation';
import { CouponType } from 'gql/payment/type';
import { AlertMessage } from 'lib/constant/alertMessage';
import { Validations } from 'lib/constant/validation';
import { alertVar } from 'store/Alert';
import { PATH } from 'utils/constants/routes';
import { hyphenateMobileNumber, removeHyphen } from 'utils/formats';
import { useGetUserProfile } from 'utils/hooks';
import { cn } from 'utils/styles';
import { STUDENT_COURSES, Target } from 'utils/constants/temp';
import { getPurchaseAudience } from 'utils/getPurchaseAudience';
import useTossPayment from 'utils/hooks/useTossPayment';
import {
  FormNameType,
  PaymentMethodType,
  payMethods,
  PurchaseList,
  UserDataType,
  PaymentMethod,
} from './type';
import errorIcon from 'assets/common/error_icon.svg';
import css from './PaymentModal.module.scss';

const { dataLayer, kakaoPixel }: any = window;

interface Props {
  purchaseList: PurchaseList[];
  onClose: () => void;
}

const PaymentModal = ({ purchaseList, onClose }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [payMethod, setPayMethod] = useState<string>();
  const [isAgree, setIsAgree] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const getPayment = useTossPayment();
  const { data: profile } = useGetUserProfile();

  const { name, realName, email, phoneNumber } = profile?.userProfile || {};

  const [formData, setFormData] = useState({
    name: realName || name || 'justcoder',
    email,
    phoneNumber: removeHyphen(phoneNumber),
    coupon: '',
  });

  const [applyCoupon, { data: couponData, error: couponError }] = useLazyQuery<{
    coupon: CouponType;
  }>(APPLY_COUPON, {
    context: { endpoint: 'payment' },
    onCompleted: () => setIsCouponValid(true),
    onError: () => setIsCouponValid(false),
  });

  const [editProfile] = useMutation(UPDATE_USER_INFO, {
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  const [createPayment] = useMutation(CREATE_PAYMENT, {
    context: { endpoint: 'payment' },
    onCompleted: ({ createPayment: { orderId } }) => {
      dataLayer.push({
        event: 'Purchase',
        price: 0,
        productId: purchaseList[0].productId,
        productName: title,
        itemsCount: purchaseList.length,
      });

      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currency: 'KRW',
          value: isCouponApplicable
            ? couponData.coupon.discountedPrice.toLocaleString()
            : totalPrice.toLocaleString(),
          transaction_id: orderId,
          coupon: isCouponApplicable ? 'yes' : 'no',
          quantity: purchaseList.length,
          items: purchaseList.map(({ productId, name, price }) => {
            const target = STUDENT_COURSES.includes(productId)
              ? Target.student
              : Target.adult;

            return {
              item_name: name,
              item_id: productId,
              price,
              item_brand: 'justcode',
              target_audience: target,
            };
          }),
        },
      });

      kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_ID).purchase({
        total_quantity: purchaseList.length,
        total_price: isCouponApplicable
          ? couponData.coupon.discountedPrice.toLocaleString()
          : totalPrice.toLocaleString(),
        products: purchaseList.map(({ productId, name, price }) => ({
          id: productId,
          name,
          quantity: '1',
          price,
        })),
      });

      const audience = getPurchaseAudience(purchaseList);

      navigate(`${PATH.TOSS.success}?target_audience=${audience}&type=payment`);
    },
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  const [createInstantPurchase] = useMutation(CREATE_INSTANT_PURCHASE, {
    context: {
      endpoint: 'payment',
    },
    onCompleted: ({ createInstantPurchase: { id } }) => {
      if (isZeroPrice) {
        createPayment({
          variables: {
            paymentId: id,
            orderId: id,
            orderName: title,
            paymentMethod: PaymentMethod.FREE,
            amount: 0,
            ...(isCouponApplicable && { couponCode: formData.coupon }),
          },
        });
      } else {
        getPayment({
          name: formData.name,
          title,
          price: isCouponApplicable
            ? couponData.coupon.discountedPrice
            : totalPrice,
          audience,
          payMethod: payMethod,
          orderId: id,
          ...(isCouponApplicable && { couponCode: formData.coupon }),
        });
      }
    },
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  const [createOrderGroup] = useMutation(CREATE_ORDER_GROUP, {
    context: { endpoint: 'payment' },
    variables: {
      orderIds: purchaseList.map(({ id }) => id),
    },
    onCompleted: ({ createOrderGroup: { id } }) => {
      if (isZeroPrice) {
        createPayment({
          variables: {
            paymentId: id,
            orderId: id,
            orderName: title,
            paymentMethod: PaymentMethod.FREE,
            amount: 0,
            ...(isCouponApplicable && { couponCode: formData.coupon }),
          },
        });
      } else {
        getPayment({
          name: formData.name,
          title,
          price: isCouponApplicable
            ? couponData.coupon.discountedPrice
            : totalPrice,
          audience,
          payMethod,
          orderId: id,
          ...(isCouponApplicable && { couponCode: formData.coupon }),
        });
      }
    },
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  const handlePayment = () => {
    if (purchaseList.length === 1) {
      const { productId, name, price } = purchaseList[0];
      createInstantPurchase({
        variables: {
          name,
          price,
          product: Product.COURSE,
          productId,
        },
      });
    } else {
      createOrderGroup();
    }
  };

  const onChangeUserData = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const onClickPaymentBtn = () => {
    dataLayer.push({
      event: 'begin_checkout',
      coupon: isCouponApplicable ? 'yes' : 'no',
      payment_type: payMethod?.toLowerCase() || 'free',
      value: isCouponApplicable
        ? couponData.coupon.discountedPrice.toLocaleString()
        : totalPrice.toLocaleString(),
      class_name: title,
    });

    if (isChangedUserInfo) {
      editProfile({
        variables: {
          updateUserInput: {
            name: formData.name,
            email: formData.email,
            phoneNumber: hyphenateMobileNumber(formData.phoneNumber),
          },
        },
      });
    }

    sessionStorage.setItem('purchaseList', JSON.stringify(purchaseList));
    sessionStorage.setItem('formData', JSON.stringify(formData));
    sessionStorage.setItem('payMethod', JSON.stringify(payMethod));

    if (REDIRECT_EXCEPTIONS.some(path => pathname.includes(path))) {
      if (pathname === PATH.CART.base) {
        sessionStorage.removeItem('current-location');
      }
    } else {
      sessionStorage.setItem('current-location', pathname);
    }

    handlePayment();
    onClose();
  };

  useEffect(() => {
    dataLayer.push({
      event: 'click_checkout',
      class_name: title,
      price: totalPrice,
      count: purchaseList.length,
    });
  }, []);

  const userData: UserDataType[] = [
    { id: 0, name: 'name', title: '이름', content: formData.name ?? '' },
    { id: 1, name: 'email', title: '이메일', content: formData.email ?? '' },
    {
      id: 2,
      name: 'phoneNumber',
      title: '연락처',
      content: formData.phoneNumber ?? '',
    },
  ];

  const firstProductTitle = purchaseList[0].name;
  const title =
    purchaseList.length > 1
      ? `${firstProductTitle} 외 ${purchaseList.length - 1}개 강의`
      : firstProductTitle;
  const totalPrice = purchaseList.reduce((acc, { price }) => acc + price, 0);
  const isChangedUserInfo = !Object.entries({ name, email, phoneNumber }).every(
    ([key, value]) =>
      value?.replaceAll('-', '') === formData[key as FormNameType],
  );
  const isCouponApplicable = !!formData.coupon && isCouponValid && !!couponData;
  const isZeroPrice =
    !totalPrice ||
    (isCouponApplicable && couponData.coupon.discountedPrice === 0);
  const isAllValid =
    userData?.every(({ name, content = '' }) =>
      Validations[name].rule(content),
    ) &&
    (payMethod || isZeroPrice) &&
    isAgree;
  const couponErrorMsg = couponError?.graphQLErrors[0].message;
  const audience = getPurchaseAudience(purchaseList);

  return (
    <Modal switchModal={onClose}>
      <div className={css.modal}>
        <div className={css.modalHeader}>
          <p className={css.title}>결제</p>
          <Icon name="close" onClick={onClose} />
        </div>
        <div className={css.modalBody}>
          <div className={css.priceInfo}>
            <p className={css.courseTitle}>{title}</p>
            <p className={css.price}>
              {isCouponApplicable
                ? couponData.coupon.discountedPrice.toLocaleString()
                : totalPrice.toLocaleString()}
              원
            </p>
            {isCouponApplicable && (
              <p className={css.priceInfoText}>
                쿠폰 적용 전 {totalPrice.toLocaleString()}원<br />
                {couponData.coupon.name} 쿠폰이 적용되었습니다.
              </p>
            )}
          </div>
          <div className={css.userInfo}>
            {userData.map(({ id, name, title, content = '' }) => (
              <div key={id}>
                <LabeledInput
                  className={css.input}
                  label={title}
                  placeholder={placeholderMap[name]}
                  type={name === 'phoneNumber' ? 'tel' : 'text'}
                  name={name}
                  value={content}
                  onChange={onChangeUserData}
                  rules={[name]}
                />
              </div>
            ))}
          </div>
          <div className={css.coupon}>
            <p className={css.couponTitle}>쿠폰 등록</p>
            {isCouponApplicable ? (
              <div className={css.couponRes}>
                <div>
                  <p className={css.couponMsg}>
                    {couponData.coupon.name} 쿠폰이 적용되었습니다.
                  </p>
                  <p className={css.discountedPrice}>
                    {couponData.coupon.discountedPrice.toLocaleString()}원
                  </p>
                </div>
                <div className={css.btnWrapper}>
                  <Button
                    variant="lineGrey"
                    size="small"
                    width="69px"
                    onClick={() => setIsCouponValid(false)}
                    disabled={!formData.coupon}
                  >
                    적용 취소
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <form
                  className={css.couponForm}
                  onSubmit={e => {
                    e.preventDefault();
                    applyCoupon({
                      variables: {
                        originalPrice: totalPrice,
                        code: formData.coupon,
                      },
                    });
                  }}
                >
                  <Input
                    size="large"
                    placeholder="쿠폰 코드를 등록해주세요."
                    name="coupon"
                    value={formData.coupon}
                    invalided={!!couponError}
                    onChange={onChangeUserData}
                  />
                  <div className={css.btnWrapper}>
                    <Button
                      type="submit"
                      variant="lineGrey"
                      size="full"
                      width="69px"
                      disabled={!formData.coupon}
                    >
                      쿠폰 적용
                    </Button>
                  </div>
                </form>
                <div
                  className={cn(
                    css.errorWrapper,
                    couponError ? css.show : css.hide,
                  )}
                >
                  <img className={css.errorImage} alt="error" src={errorIcon} />
                  <p className={css.errorMessage}>{couponErrorMsg}</p>
                </div>
              </>
            )}
          </div>
        </div>
        {!isZeroPrice && (
          <div className={css.paymentMethod}>
            <p className={css.paymentMethodTitle}>결제 수단 선택</p>
            <Button
              variant="lineGrey"
              size="full"
              isSelected={payMethods.card === payMethod}
              check={false}
              onClick={() => setPayMethod(payMethods.card)}
            >
              신용 / 체크카드
            </Button>
            <div className={css.easyPayButtonBox}>
              {Object.keys(payMethods)
                .filter(method => method !== 'card')
                .map(payment => (
                  <Button
                    key={payment}
                    size="full"
                    variant="lineGrey"
                    isSelected={
                      payMethods[payment as PaymentMethodType] === payMethod
                    }
                    check={false}
                    onClick={() =>
                      setPayMethod(payMethods[payment as PaymentMethodType])
                    }
                  >
                    <Icon name={`${payment}Pay` as IconName} />
                  </Button>
                ))}
            </div>
          </div>
        )}
        <div className={css.modalFooter}>
          <div className={css.remark}>
            <p>주문하실 강의와 약관을 확인했고, 구매에 동의하시겠습니까?</p>
            <label className={css.agree}>
              <input
                type="checkbox"
                value={Number(isAgree)}
                onChange={() => setIsAgree(!isAgree)}
              />
              구매에 동의합니다.
            </label>
          </div>
          <div className={css.buttonGroup}>
            <Button
              className={css.btn}
              disabled={!isAllValid}
              size="full"
              onClick={onClickPaymentBtn}
            >
              결제
            </Button>
            <Button
              className={css.btn}
              variant="lineBase"
              size="full"
              onClick={onClose}
            >
              취소
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;

const placeholderMap = {
  name: '이름을 입력해주세요.',
  email: '사용하시는 메일을 적어주세요.',
  phoneNumber: '하이픈, ‘-’ 없이 숫자만 입력해주세요.',
} as const;

const REDIRECT_EXCEPTIONS = ['payment', 'cart', PATH.TEST_RESULT.base];
