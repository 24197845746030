import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'Components/core/Button';
import Icon from 'Components/core/Icon/Icon';
import { PATH } from 'utils/constants/routes';
import css from './ErrorPage.module.scss';

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className={css.container}>
      <Icon name="underConst" size={160} />
      <p className={css.title}>에러가 발생하였습니다.</p>
      <p className={css.description}>
        해당 문제가 계속된다면,{' '}
        <a
          href="https://wecode.channel.io"
          className={css.cs}
          target="_blank"
          rel="noreferrer"
        >
          고객센터
        </a>
        로 문의해주세요.
      </p>
      <div className={css.btnWrapper}>
        <Button size="large" onClick={() => navigate(PATH.HOME.base)}>
          홈으로
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
