import React from 'react';
import Button from 'Components/core/Button';
import Icon from 'Components/core/Icon/Icon';
import Modal from 'Components/core/ModalPortal/Modal';
import { createMarkup } from 'utils/constants/createMarkup';
import { usePrompt } from 'utils/hooks';
import css from './PromptModal.module.scss';

type Props = {
  message?: string;
  when: boolean;
};

const PromptModal = ({
  when,
  message = '저장하지 않은 정보가 사라집니다.</br> 나가시겠습니까?',
}: Props) => {
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(when);

  const handleClickNo = () => {
    cancelNavigation();
  };

  const handleClickYes = () => {
    confirmNavigation();
  };

  return (
    <>
      {showPrompt && (
        <Modal switchModal={cancelNavigation}>
          <div className={css.container}>
            <Icon name="warning" />
            <p
              className={css.message}
              dangerouslySetInnerHTML={createMarkup(message)}
            />
            <div className={css.btnWrap}>
              <Button variant="lineBase" size="large" onClick={handleClickNo}>
                취소
              </Button>
              <Button size="large" onClick={handleClickYes}>
                확인
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PromptModal;
