import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { cn, cond } from 'utils/styles';
import Icon, { IconName } from '../Icon/Icon';
import { CodeDuckStatus } from './type';
import css from './CodeDuck.module.scss';

interface Props {
  status?: CodeDuckStatus;
  progress?: number;
  progressColor?: string;
  hasIcon?: boolean;
  point?: number;
  icon?: IconName;
  iconAnimation?: boolean;
  size?: 'small' | 'medium' | 'large';
  barStyle?: 'butt' | 'round';
  barAnimation?: boolean;
  handleIcon?: () => void;
}

const CodeDuck = ({
  status = 'default',
  progress,
  progressColor = '#28c600',
  hasIcon,
  point = 0,
  icon,
  iconAnimation,
  size = 'small',
  barStyle = 'butt',
  barAnimation = false,
  handleIcon,
}: Props) => {
  const [progressValue, setProgressValue] = useState(
    barAnimation ? 0 : progress,
  );
  const statusPascal = status.charAt(0).toUpperCase() + status.slice(1);

  useEffect(() => {
    setProgressValue(progress);
  }, []);

  return (
    <div className={cn(css.container, css[size])}>
      {progressValue != null && (
        <CircularProgressbar
          className={css.progress}
          value={progressValue}
          styles={buildStyles({
            pathColor: progressColor,
            trailColor: '#f3f3f3',
            strokeLinecap: barStyle,
            pathTransitionDuration: 1,
          })}
          strokeWidth={9}
        />
      )}
      <Icon
        className={cn(css.duckIcon, css[size])}
        name={`codeDuck${statusPascal}` as IconName}
      />
      {!!point && (
        <div className={css.starWrapper}>
          {Array.from({ length: point }).map((_, i) => (
            <Icon key={i} name="star" />
          ))}
        </div>
      )}
      {hasIcon && icon && (
        <Icon
          className={cn(css.hint, cond(!!iconAnimation, css.animation))}
          name={icon}
          onClick={handleIcon}
        />
      )}
    </div>
  );
};

export default CodeDuck;
