import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { FingerEditor } from '@wecode-codingbootcamp/finger-editor';
import { useLazyQuery } from '@apollo/client';
import Icon, { IconName } from 'Components/core/Icon/Icon';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import DoneModal from 'Pages/CurriculumRouter/Contents/DoneModal';
import { PATH } from 'utils/constants/routes';
import { cn, cond } from 'utils/styles';
import {
  GET_UNCOMPLETED_ASSIGNMENT_IDS,
  GET_USER_LEARNING_DATA,
} from 'gql/course/query';
import { UserLearningData } from 'gql/course/type';
import { TOKEN } from 'utils/constants';
import { GET_HAS_PURCHASED } from 'gql/payment/query';
import contentSample from 'assets/page/main/content_sample.png';
import speechBubble from 'assets/page/main/speech_bubble.svg';
import checkTriple from 'assets/page/main/check_triple.svg';
import chatExample from 'assets/page/main/chat_example.png';
import codeduckStart from 'assets/page/main/codeduck_start.svg';
import css from './Home.module.scss';

const SLIDE_WIDTH = 260;

const Home = () => {
  const navigate = useNavigate();
  const { isOpenModal, switchModal } = useSwitchModal();
  const [slidesPerView, setSlidesPerView] = useState(
    window.innerWidth / SLIDE_WIDTH,
  );
  const isLoggedIn = !!localStorage.getItem(TOKEN);

  const [getUncompletedAssignmentIds] = useLazyQuery<{
    uncompletedAssignmentIds: number[];
  }>(GET_UNCOMPLETED_ASSIGNMENT_IDS, {
    context: { endpoint: 'course' },
    fetchPolicy: 'network-only',
  });

  const [getUserLearningData] = useLazyQuery<{
    userLearningData: UserLearningData;
  }>(GET_USER_LEARNING_DATA, {
    context: { endpoint: 'course' },
    fetchPolicy: 'network-only',
  });

  const [getHasPurchased, { data: purchaseData }] = useLazyQuery<{
    hasPurchased: boolean;
  }>(GET_HAS_PURCHASED, {
    context: { endpoint: 'payment' },
    variables: {
      productId: 1,
      product: 'SERVICE_PLAN',
    },
  });

  const handleStartBtn = async () => {
    if (!isLoggedIn) {
      navigate(PATH.LOGIN.base);

      return;
    }

    try {
      const [userLearningData, uncompletedAssignmentIds] = await Promise.all([
        getUserLearningData(),
        getUncompletedAssignmentIds(),
      ]);

      const remainingCount =
        userLearningData.data?.userLearningData
          ?.remainingDailyAssignmentGenerationCount;
      const hasCreated = remainingCount != null && remainingCount <= 0;
      const hasAssignment =
        uncompletedAssignmentIds.data?.uncompletedAssignmentIds.length !== 0;

      if (hasCreated && !hasAssignment) {
        await getHasPurchased();

        switchModal();

        return;
      }

      const path = hasAssignment
        ? PATH.CONTENT.base
        : PATH.LEVEL_SELECT.language;

      navigate(path);
    } catch {
      alert('실패');
    }
  };

  useEffect(() => {
    const handleViewport = () => {
      setSlidesPerView(window.innerWidth / SLIDE_WIDTH);
    };

    handleViewport();

    window.addEventListener('resize', handleViewport);

    return () => window.removeEventListener('resize', handleViewport);
  }, []);

  return (
    <>
      <div className={css.container}>
        <section className={css.bannerSection}>
          <div>
            <h1 className={css.bannerTitle}>
              맞춤형 AI와 함께하는
              <br />
              나만을 위한 코딩 학습
            </h1>
            <Icon className={css.logo} name="justcodeLogo" />
          </div>
          <div className={css.bannerImgWrapper}>
            <img
              className={css.contentSample}
              src={contentSample}
              alt="콘텐트 샘플"
            />
          </div>
          <div>
            <button className={css.bannerBtn} onClick={handleStartBtn}>
              학습 시작하기
              <Icon name="arrowRightFill" />
            </button>
            <img
              className={css.speechBubble}
              src={speechBubble}
              alt="오늘의 문제"
            />
          </div>
        </section>
        <section className={css.eventSection}>
          <div className={css.eventTitleWrapper}>
            <span className={css.badge}>Event 🎉</span>
            <h2 className={cn(css.title, css.emphasis)}>
              신규 출시 기념
              <br />
              매일 10문제 무료 제공
            </h2>
          </div>
          <img src={checkTriple} alt="체크" />
          <p className={css.description}>
            새로운 저스트코드 출시를 기념하여
            <br />
            AI가 만든 맞춤 학습 문제를 매일 제공합니다!
          </p>
          <button className={css.button} onClick={handleStartBtn}>
            무료 체험 시작하기
          </button>
        </section>
        <section className={css.levelSelectSection}>
          <h2 className={css.title}>
            <strong className={css.emphasis}>기초</strong>부터{' '}
            <strong className={css.emphasis}>심화</strong>까지
            <br />
            {/* <strong className={css.emphasis}>언어</strong>와{' '}
          <strong className={css.emphasis}>난이도</strong>만 선택하면 */}
            {/* 언어와 난이도만 선택하면 */}
            {/* <br /> */}
            AI가 만들어주는
            <br />
            나만의 코딩 문제
          </h2>
          <div className={css.languageWrapper}>
            {LANGUAGES.map(({ language, icon, check }) => (
              <span
                key={language}
                className={cn(css.selectBtn, cond(!!check, css.check))}
              >
                <Icon name={icon} size={16} />
                {language}
                {check && <Icon name="checkBlue" size={16} />}
              </span>
            ))}
            <br />
            {DIFFICULTIES.map(({ difficulty, color, check }) => (
              <span
                key={difficulty}
                className={cn(
                  css.selectBtn,
                  css[color],
                  cond(!!check, css.check),
                )}
              >
                {difficulty}
                {check && <Icon name="checkBlue" size={16} />}
              </span>
            ))}
          </div>
          <Swiper
            className={css.swiper}
            modules={[Autoplay]}
            slidesPerView={slidesPerView}
            spaceBetween={16}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            preventInteractionOnTransition={true}
            speed={3000}
            loop
          >
            {/* TOFIX: 문제 개수가 적어 큰 화면에서 carousel 동작하지 않는 오류 때문에 복제한 부분 (230704 래영) */}
            {CODE_EXAMPLES.concat(CODE_EXAMPLES).map(
              ({ question, code }, idx) => (
                <SwiperSlide key={idx} style={{ width: SLIDE_WIDTH }}>
                  <div className={css.exampleContainer}>
                    <div className={css.questionWrapper}>
                      <span className={css.tag}>문제</span>
                      <span className={css.question}>{question}</span>
                    </div>
                    <div className={css.exampleCode}>
                      <FingerEditor
                        className="notSubmitted"
                        assignmentCode={code}
                        submittedAnswers={[]}
                        showLineNumbers={false}
                        filename="example.js"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </section>
        <section className={css.qnaSection}>
          <h2 className={css.title}>
            모르는 문제는 <strong className={css.emphasis}>AI 멘토</strong>에게
            <br />
            곧바로 질문해 보세요
          </h2>
          <img className={css.chatExample} src={chatExample} alt="채팅" />
        </section>
        <section className={css.startSection}>
          <h2 className={css.title}>
            지금 바로 나만을 위한
            <br />
            코딩 공부를 시작하세요!
          </h2>
          <img src={codeduckStart} alt="코더덕" />
          <button
            className={cn(css.button, css.linebase)}
            onClick={handleStartBtn}
          >
            지금 시작하기
          </button>
        </section>
      </div>
      {isOpenModal && (
        <DoneModal
          where="home"
          purchased={purchaseData?.hasPurchased}
          switchModal={switchModal}
        />
      )}
    </>
  );
};

export default Home;

const LANGUAGES: { language: string; icon: IconName; check?: boolean }[] = [
  { language: 'JavaScript', icon: 'javaScript' },
  { language: 'Python', icon: 'python', check: true },
  { language: 'TypeScript', icon: 'typeScript' },
  { language: 'Java', icon: 'java' },
  { language: 'C', icon: 'c' },
];

const DIFFICULTIES = [
  { difficulty: '쉬움', color: 'green' },
  { difficulty: '중간', color: 'yellow', check: true },
  { difficulty: '어려움', color: 'red' },
];

const CODE_EXAMPLES = [
  {
    question: '다음 코드의 실행 결과는 무엇일까요?',
    code: `nums = [10, 20, 30]
print(nums[-1])`,
  },
  {
    question: '숫자가 같은지 비교하려면 빈 칸에 어떤 내용을 넣어야 할까요?',
    code: '5 [[blank]] 2 + 3',
  },
  {
    question: '리스트의 모든 요소를 출력하기 위해 빈칸을 알맞게 채워주세요.',
    code: `my_list = [1, 2, 3]
for [[blank]] [[blank]] my_list:
    print(i)`,
  },
  {
    question: '콘솔에 어떤 값이 출력될까요?',
    code: `analysis = "Kim"
analysis = "code"
print(analysis)`,
  },
];
