import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';

export const useGetUserProfile = () => {
  const { pathname } = useLocation();
  const isException = exceptions.some(path => pathname.includes(path));

  const { data, loading, error, refetch } = useQuery<UserProfile>(
    GET_USER_PROFILE,
    {
      fetchPolicy: 'cache-and-network',
      skip: isException,
    },
  );

  useEffect(() => {
    if (isException) return;

    refetch();
  }, [pathname]);

  useEffect(() => {
    if (error?.message === 'NOT AUTHORIZED') {
      localStorage.removeItem(TOKEN);
    }
  }, [error]);

  return { data, loading, error };
};

const exceptions = [PATH.AUTH.kakao];

export const GET_USER_PROFILE = gql`
  query {
    userProfile {
      id
      name
      realName
      birthdate
      email
      phoneNumber
      profileImage
      gender
      createdAt
    }
  }
`;

export type UserProfile = {
  userProfile: {
    id: number;
    name?: string;
    realName?: string;
    birthdate?: string;
    email?: string;
    phoneNumber?: string;
    profileImage?: string;
    gender?: string;
    createdAt: string;
  };
};
