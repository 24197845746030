import React from 'react';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { CREATE_KAKAO_TOKEN } from 'gql/auth/mutation';
import { CreateKakaoLoginType } from 'gql/auth/type';
import { PATH } from 'utils/constants/routes';
import { convertQueryString } from 'utils/querys';
import { KAKAO_TOKEN_OAUTH_URL } from '../data';

export const useKakaoToken = () => {
  const [kakaoToken, setKakaoToken] = React.useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [getKakao, { data, loading }] = useMutation<CreateKakaoLoginType>(
    CREATE_KAKAO_TOKEN,
    {
      variables: { kakaoAuthToken: kakaoToken },
      onError() {
        alert('예상치 못한 에러가 발생했습니다. 다시 시도해주세요.');
        navigate(PATH.LOGIN.base);
      },
    },
  );

  React.useEffect(() => {
    if (!kakaoToken) {
      return;
    }
    getKakao();
  }, [kakaoToken]);

  React.useEffect(() => {
    const accessTokenUrl = location.search;

    if (!accessTokenUrl.includes('code')) {
      alert('다시 시도해주세요!');
      navigate(PATH.LOGIN.base);
    }

    const kakaoAccessTokenData = {
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_KAKAO_REST_KEY ?? '',
      redirect_uri: process.env.REACT_APP_KAKAO_REDIRECT_URI ?? '',
      code: searchParams.get('code') ?? '',
    };

    const getKakaoToken = async () => {
      try {
        const { data } = await axios.post(
          KAKAO_TOKEN_OAUTH_URL,
          convertQueryString(kakaoAccessTokenData),
        );
        setKakaoToken(data?.access_token);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error(error.message);
          alert('만료된 로그인 페이지입니다.');
          navigate(PATH.LOGIN.base);
        }
      }
    };
    getKakaoToken();
  }, []);

  return { data, loading };
};
