import React from 'react';
import Button from 'Components/core/Button';
import Icon from 'Components/core/Icon/Icon';
import Modal from '../Modal';
import css from './ImageTypeAlertModal.module.scss';

interface Props {
  switchModal: () => void;
}

const ImageTypeAlertModal = (props: Props) => {
  const { switchModal } = props;

  return (
    <Modal switchModal={switchModal}>
      <div className={css.container}>
        <Icon name="warning" />
        <p className={css.message}>
          JPG, JPEG, PNG, HEIC를
          <br /> 선택해주세요.
        </p>
        <div className={css.btnWrap}>
          <Button size="large" onClick={switchModal}>
            확인
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ImageTypeAlertModal;
