import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'utils/constants/routes';
import cartIcon from 'assets/empty_Cart.svg';
import css from './EmptyList.module.scss';

function EmptyList() {
  const navigate = useNavigate();
  return (
    <div className={css.container}>
      <img alt="카트" src={cartIcon} />
      <div className={css.textContent}>
        <p className={css.title}>장바구니가 비어있습니다.</p>
        <p className={css.description}>
          커리큘럼에서 수강할 강의를 골라주세요.
        </p>
      </div>
      <button className={css.btn} onClick={() => navigate(PATH.CLASS.base)}>
        커리큘럼 둘러보기
      </button>
    </div>
  );
}

export default EmptyList;
