import React from 'react';
import Icon from 'Components/core/Icon/Icon';
import css from 'Components/core/ModalPortal/Alert/Alert.module.scss';

const AlertHeader = ({
  title,
  switchModal,
}: {
  title: string;
  switchModal: () => void;
}) => {
  if (!title) return <></>;

  return (
    <header className={css.header}>
      <h4>{title}</h4>
      <Icon name="close" onClick={switchModal} />
    </header>
  );
};

export default AlertHeader;
