import React, { useState } from 'react';
import Icon from 'Components/core/Icon/Icon';
import { cn, cond } from 'utils/styles';
import css from './Pagination.module.scss';

interface Props {
  currentPage: number;
  totalPage: number;
  pageCountPerView?: number;
  completedPageList?: number[];
  disabledPageList?: number[];
  onChange: (page: number) => void;
}

const Pagination = ({
  currentPage,
  totalPage,
  pageCountPerView = 5,
  completedPageList,
  disabledPageList,
  onChange,
}: Props) => {
  const [pageGroup, setPageGroup] = useState(
    Math.ceil(currentPage / pageCountPerView) || 1,
  );
  const totalPageGroup = Math.ceil(totalPage / pageCountPerView);

  const handlePrevGroup = () => {
    if (pageGroup > 1) {
      setPageGroup(prev => prev - 1);
    }
  };

  const handleNextGroup = () => {
    if (pageGroup < totalPageGroup) {
      setPageGroup(prev => prev + 1);
    }
  };

  return (
    <div data-testid="pagination" className={css.container}>
      <button
        className={css.pageGroupBtn}
        data-testid="prevBtn"
        onClick={handlePrevGroup}
        disabled={pageGroup === 1}
      >
        <Icon
          name={
            pageGroup === 1 ? 'arrowLeftGreyDisabled' : 'arrowLeftGreyDefault'
          }
        />
      </button>
      <div className={css.btnWrapper}>
        {Array.from({ length: pageCountPerView }, (_, i) => {
          const page = i + 1 + (pageGroup - 1) * pageCountPerView;
          const isCompleted = !!completedPageList?.includes(page);
          const isCurrent = page === currentPage;
          const disabled = disabledPageList?.includes(page);

          if (page > totalPage) return;

          return (
            <button
              key={page}
              data-testid="pageBtn"
              className={cn(
                css.pageBtn,
                cond(isCompleted, css.completed),
                cond(isCurrent, css.current),
              )}
              onClick={() => onChange(page)}
              disabled={disabled}
            >
              {page}
            </button>
          );
        })}
      </div>
      <button
        className={css.pageGroupBtn}
        data-testid="nextBtn"
        onClick={handleNextGroup}
        disabled={pageGroup >= totalPageGroup}
      >
        <Icon
          name={
            pageGroup >= totalPageGroup
              ? 'arrowRightGreyDisabled'
              : 'arrowRightGreyDefault'
          }
        />
      </button>
    </div>
  );
};

export default Pagination;
