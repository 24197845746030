import axios from 'axios';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';

export const uploadImageToS3 = async (presignedUrl: string, file: File) => {
  try {
    await axios.put(presignedUrl, file, {
      headers: { 'Content-Type': file.type },
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error.message);
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.uploadImage,
      });
    }
  }
};
