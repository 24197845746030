/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';
import { GET_UNCOMPLETED_ASSIGNMENT_IDS } from 'gql/course/query';
import { useKakaoToken } from './KakaoRedirect.hook';

const { dataLayer }: any = window;

const KakaoRedirect = () => {
  const navigate = useNavigate();
  const prevPath = sessionStorage.getItem('current-location');

  const { data: loginData, loading } = useKakaoToken();

  const [getUncompletedAssignmentIds] = useLazyQuery<{
    uncompletedAssignmentIds: number[];
  }>(GET_UNCOMPLETED_ASSIGNMENT_IDS, {
    context: { endpoint: 'course' },
    fetchPolicy: 'network-only',
    onCompleted: ({ uncompletedAssignmentIds }) => {
      const hasAssignment = uncompletedAssignmentIds.length !== 0;
      const path = hasAssignment
        ? PATH.CONTENT.base
        : PATH.LEVEL_SELECT.language;

      navigate(prevPath || path);
    },
  });

  useEffect(() => {
    if (!loading && loginData) {
      const { accessToken, isNewUser } = loginData.createKakaoLogin;

      if (isNewUser) {
        dataLayer.push({
          event: 'signup_complete',
        });
      }

      localStorage.setItem(TOKEN, accessToken);

      getUncompletedAssignmentIds({
        context: {
          headers: {
            Authorization: accessToken,
          },
          endpoint: 'course',
        },
      });
    }
  }, [loginData, loading]);

  return null;
};

export default KakaoRedirect;
