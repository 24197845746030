interface ValidationType {
  rule: (value: string) => boolean;
  message: string;
}

type ValidationMapType = Record<string, ValidationType>;

export const Validations: ValidationMapType = {
  required: {
    rule: value => value.length > 0,
    message: '필수 입력 항목입니다.',
  },
  minLength8: {
    rule: value => value.length >= 8,
    message: '8자 이상 입력해주세요.',
  },
  atLeast1Letter1Number: {
    rule: value => /(?=.*[a-zA-Z])(?=.*\d)/.test(value),
    message: '문자와 숫자를 섞어주세요',
  },
  length11: {
    rule: value => value.length === 11,
    message: '휴대폰 번호는 11자리로 맞춰주세요.',
  },
  phoneNumber: {
    rule: value => value.length === 11 && /^[0-9]+$/.test(value),
    message: '휴대폰 번호는 11자리 숫자만 입력해주세요.',
  },
  onlyNumber: {
    rule: value => /^[0-9]+$/.test(value),
    message: '숫자로만 입력해주세요.',
  },
  onlyPositiveNumber: {
    rule: value => Number(value) > 0 && /^[0-9]+$/.test(value),
    message: '1 이상의 숫자로만 입력해주세요.',
  },
  email: {
    rule: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value),
    message: '이메일 형식으로 입력해주세요.',
  },
  name: {
    rule: value =>
      /^[가-힣a-zA-Z\s]+$/.test(value) && value.length === value.trim().length,
    message: '이름을 올바른 형태로 입력해주세요',
  },
  password: {
    rule: value =>
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value),
    message: '비밀번호는 8자 이상, 문자, 숫자, 특수문자를 섞어주세요.',
  },
} as const;
