import React, { useState } from 'react';
import Button from 'Components/core/Button';
import PaymentModal from 'Components/core/PaymentModal';
import { useGetUserProfile } from 'utils/hooks';
import { cn } from 'utils/styles';
import css from './PaymentBox.module.scss';

interface Props {
  purchaseList: {
    id: number;
    productId: number;
    price: number;
    name: string;
  }[];
  totalPrice: number;
  isEmpty: boolean;
  className?: string;
  selectedCount: number;
}

type FormNameType = 'name' | 'phoneNumber' | 'email';
export type UserDataType = {
  id: number;
  title: string;
  name: FormNameType;
  content: string | undefined;
};

function PaymentBox({
  purchaseList,
  totalPrice,
  isEmpty,
  className = '',
  selectedCount,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: profile } = useGetUserProfile();

  const { name, email, phoneNumber } = profile?.userProfile || {};

  const userData: UserDataType[] = [
    { id: 0, name: 'name', title: '이름', content: name ?? '' },
    { id: 1, name: 'email', title: '이메일', content: email ?? '' },
    {
      id: 2,
      name: 'phoneNumber',
      title: '연락처',
      content: phoneNumber ?? '',
    },
  ];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className={cn(css.container, className)}>
      <div className={css.infoBox}>
        <div className={css.infoHeader}>
          <p className={css.title}>수강하시는 분</p>
        </div>
        <div className={css.infoWrapper}>
          {userData.map(({ id, title, content }) => (
            <div key={id} className={css.infoItem}>
              <span className={css.name}>{title}</span>
              <span>{content}</span>
            </div>
          ))}
        </div>
      </div>
      {!isEmpty && (
        <div className={css.infoBox}>
          <p className={css.title}>결제금액</p>
          <p className={css.totalPrice}>{totalPrice.toLocaleString()}원</p>
          <p className={css.agreement}>
            회원 본인은 주문내용을 확인했으며, 구매조건 및 개인정보 취급방침과
            결제에 동의합니다.
          </p>
        </div>
      )}
      <Button
        size="large"
        width="100%"
        onClick={openModal}
        disabled={isEmpty || selectedCount === 0}
      >
        결제하기
      </Button>

      {isModalOpen && (
        <PaymentModal purchaseList={purchaseList} onClose={closeModal} />
      )}
    </div>
  );
}

export default PaymentBox;
