export enum Difficulty {
  beginner = 'beginner',
  intermediate = 'intermediate',
  advanced = 'advanced',
}

export enum Status {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum FreemiumStatus {
  FREE = 'FREE',
  LOGIN_REQUIRED = 'LOGIN_REQUIRED',
  AUTHORIZATION_REQUIRED = 'AUTHORIZATION_REQUIRED',
}

export enum UserPermission {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  RESTRICTED = 'RESTRICTED',
}

export enum LevelTestType {
  WITH_CODING_EXPERIENCE = 'WITH_CODING_EXPERIENCE',
  WITHOUT_CODING_EXPERIENCE = 'WITHOUT_CODING_EXPERIENCE',
}

export type ClassCourseInfo = {
  id: number;
  categoryId: number;
  title: string;
  price: number;
  thumbnail: string;
  images: {
    LARGE?: string;
    MEDIUM?: string;
    SMALL?: string;
    DETAIL?: string;
    GRAY?: string;
  };
  wishListed: boolean;
  difficulty: Difficulty;
  themeName: string;
};

export type ClassCourseType = {
  allCourseInfo: ClassCourseInfo[];
};

export type CourseInfo = {
  id: number;
  categoryId: number;
  title: string;
  difficulty: Difficulty;
  thumbnail: string;
  price?: number;
  introduction?: string;
  englishTitle?: string;
  description?: string;
  coverImage?: string;
  progress?: number;
  purchased?: boolean;
};

export type CourseResultType = {
  id: number;
  title: string;
  introduction: string;
  thumbnail: string;
  progress: number;
  totalDifficultyScore: number;
  totalCourseContentCount: number;
  totalUserAssignmentRetryCount: number;
};

export type CurriculumType = {
  curriculum: Curriculum;
  userLevelInfo: LevelInfo[];
};

export type Curriculum = {
  id: number;
  title: string;
  introduction: string;
};

export type LevelInfo = {
  id: number;
  title: string;
  thumbnail: string;
  introduction: string;
  progress: string;
};

export type ProductType = {
  course: {
    id: number;
    thumbnail: string;
    englishTitle: string;
    title: string;
    difficulty: Difficulty;
    lecturesCount: number;
    price: number;
    introduction: string;
  };
};

export type WishListType = {
  id: number;
  categoryId: number;
  title: string;
  difficulty: Difficulty;
  thumbnail: string;
  price: number;
  purchased?: boolean;
};

export type CourseCompletedType = {
  id: number;
  title: string;
  price: number;
  appId: string;
};

export interface GetBookmarkList {
  bookmarks: {
    userId: number;
    courseContentInfoList: ContentInfo[];
  };
}

interface ContentInfo {
  courseId: number;
  courseTitle: string;
  id: number;
  title: string;
}

export type UserAppType = {
  id: number;
  courseId: number;
  userData: Record<string, string>;
  assignmentSource: Record<string, string>;
  permission: UserPermission;
  isOwner: boolean;
};

export type UserAppPermissionType = {
  appId: string;
};

export type Thumbnail = {
  course: {
    thumbnail: string;
  };
};

export type CourseCardType = {
  id: number;
  title: string;
  thumbnail: string;
  price: number;
};

export type CourseType = {
  course: {
    id: number;
    title: string;
    introduction: string;
    description: string;
    difficulty: Difficulty;
    videosCount: number;
    lecturesCount: number;
    totalDurations: number;
    thumbnail: string;
    coverImage: string;
    courseContents: CourseContent[];
    price: number;
    originalPrice: number;
    discountPercentage?: number;
    status: Status;
    wishListed: boolean;
    purchased: boolean;
  };
};

export type CourseContent = {
  id: number;
  title: string;
  freemiumStatus: FreemiumStatus;
};

export type IncorrectAssignmentCount = {
  count: number;
};

export type Assignment = {
  id: number;
  content: string;
  code: string;
  codeFilename: string;
  assignmentCodeChoices: AssignmentCodeChoice[];
};

export type AssignmentCodeChoice = {
  id: number;
  name: string;
  feedback: string | null;
  isAnswer: boolean;
  sequence: number;
  assignmentId: number;
  codeInputType: CodeInputType;
};

export enum CodeInputType {
  BUTTON = 'BUTTON',
  QUIZ = 'QUIZ',
}

export type UserLearningData = {
  hasActiveSubscription: boolean;
  remainingDailyAssignmentGenerationCount: number | null;
};

export type ServicePlanType = {
  id: number;
  name: string;
  price: number;
};
