/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import { CREATE_WISH_LIST, DELETE_WISH_LIST } from 'gql/auth/mutation';
import { GET_COURSE_INFO } from 'gql/course/query';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';

interface Props {
  id: number;
  wishListed: boolean;
}

const { dataLayer, kakaoPixel }: any = window;

export const useWishList = (
  courseId: number,
  price: number,
  productName: string,
) => {
  const { isOpenModal, switchModal } = useSwitchModal();

  const [createHeart] = useMutation(CREATE_WISH_LIST, {
    context: { endpoint: 'course' },
    refetchQueries: [
      {
        query: GET_COURSE_INFO,
        context: { endpoint: 'course' },
        fetchPolicy: 'cache-first',
        variables: { id: courseId },
      },
      'course',
    ],
    onCompleted: () => {
      dataLayer.push({
        event: 'AddToWishlist',
        price,
        productId: courseId,
        productName,
      });

      kakaoPixel(process.env.REACT_APP_KAKAO_PIXEL_ID).addToWishList({
        id: courseId,
      });
    },
    onError(error) {
      if (error?.graphQLErrors[0]?.message === 'NOT_AUTHORIZED') {
        switchModal();
      }
    },
  });

  const [deleteHeart] = useMutation(DELETE_WISH_LIST, {
    context: { endpoint: 'course' },
    refetchQueries: [
      {
        query: GET_COURSE_INFO,
        context: { endpoint: 'course' },
        fetchPolicy: 'cache-first',
        variables: { id: courseId },
      },
      'course',
    ],
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  const changeHeart = ({ id, wishListed }: Props) => {
    if (wishListed) {
      deleteHeart({ variables: { courseIds: [id] } });
    } else {
      createHeart({ variables: { courseIds: [id] } });
    }
  };

  return { isOpenModal, switchModal, changeHeart };
};
