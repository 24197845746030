import { gql } from '@apollo/client';

export const GET_STUDENT_DEFAULT_DATA = gql`
  query getStudentDefaultData {
    student {
      id
      name
      email
      phoneNumber
      birthdate
      developerType
      batch {
        id
        batchSequence
      }
    }
  }
`;

export const GET_MYPAGE_PROFILE = gql`
  query {
    userProfile {
      name
      email
      phoneNumber
      profileImage
    }
  }
`;

export const GOOGLE_LOGIN = gql`
  query loginToGoogle {
    loginToGoogle {
      accessToken
      hasPassword
    }
  }
`;

export const SIGN_IN = gql`
  query SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      accessToken
    }
  }
`;

export const CONFIRM_MAIL_QUERY = gql`
  query confirmMailQuery($email: String!, $phoneNumber: String!) {
    sendEmail(email: $email, phoneNumber: $phoneNumber)
  }
`;

export const FIND_ID_FORM_DATA = gql`
  query findUser($userName: String!, $phoneNumber: String!) {
    findUser(userName: $userName, phoneNumber: $phoneNumber) {
      email
    }
  }
`;
