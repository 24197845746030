import { gql } from '@apollo/client';

export const GET_ALL_COURSE_INFO = gql`
  query allCourseInfo($theme: String) {
    allCourseInfo(theme: $theme) {
      id
      categoryId
      title
      price
      thumbnail
      images
      wishListed
      difficulty
      themeName
    }
  }
`;

export const GET_MY_CARD_INFO = gql`
  query getCourse($courseId: Float!) {
    course(id: $courseId) {
      progress
      appId
    }
  }
`;

export const GET_COURSE = gql`
  query course($id: Float!, $isLastContent: Boolean!) {
    course(id: $id) {
      id
      title
      introduction
      ogImage
      progress
      courseContents {
        id
        sequence
        title
        status
      }
      latestInProgressContentId
      price
      totalDurations
      wishListed
      purchased
      evaluated @include(if: $isLastContent)
      deployed @include(if: $isLastContent)
    }
  }
`;

export const GET_COURSE_CARD = gql`
  query course($id: Float!) {
    course(id: $id) {
      id
      title
      introduction
      thumbnail
      progress
    }
  }
`;

export const GET_COURSE_DETAIL_PAGE = gql`
  query getCourse($id: Float!) {
    course(id: $id) {
      id
      title
      introduction
      description
      difficulty
      videosCount
      lecturesCount
      totalDurations
      thumbnail
      coverImage
      courseContents {
        id
        title
        freemiumStatus
      }
      price
      originalPrice
      discountPercentage
      status
      wishListed
      purchased
    }
  }
`;

export const GET_COURSE_CONTENT = gql`
  query courseContent($id: Float!, $preCourseContentId: Float) {
    courseContent(id: $id, preCourseContentId: $preCourseContentId) {
      id
      sequence
      title
      content
      status
      assignmentSource
      preAssignmentSource
      bookmarked
      userData
      editor {
        id
        code
        filename
        hint
        difficultyScore
        answers {
          sequence
          answers
        }
        assignmentChoices {
          id
          sequence
          name
          feedback
          isAnswer
          type
        }
      }
      userAssignment {
        retryCount
        status
      }
    }
  }
`;

export const GET_COURSE_INFO = gql`
  query getCourse($id: Float!) {
    course(id: $id) {
      id
      title
      price
      wishListed
    }
  }
`;

export const GET_CONTENT_RESULT = gql`
  query getCourseResult($id: Float!) {
    course(id: $id) {
      id
      title
      introduction
      thumbnail
      progress
      totalDifficultyScore
      totalCourseContentCount
      totalUserAssignmentRetryCount
    }
  }
`;

export const GET_CURRICULUM = gql`
  query curriculum($id: Float!) {
    curriculum(id: $id) {
      id
      title
      introduction
    }

    userLevelInfo(curriculumId: $id) {
      id
      title
      thumbnail
      introduction
      progress
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProduct($id: Float!) {
    course(id: $id) {
      id
      thumbnail
      title
      difficulty
      lecturesCount
      price
      introduction
    }
  }
`;

export const GET_WISHLIST = gql`
  query getWishList {
    wishList {
      courseInfoList {
        id
        categoryId
        title
        thumbnail
        difficulty
        price
        purchased
      }
    }
  }
`;

export const GET_COURSE_COMPLETED = gql`
  query course($id: Float!) {
    course(id: $id) {
      id
      title
      price
      appId
    }
  }
`;

export const GET_LEVEL = gql`
  query level($id: Float!) {
    level(id: $id) {
      id
      title
      curriculumId
      introduction
      userCourseInfo {
        id
        title
        thumbnail
        introduction
        progress
      }
    }
  }
`;

export const GET_BOOKMARKLIST = gql`
  query bookmarks {
    bookmarks {
      userId
      courseContentInfoList {
        courseId
        courseTitle
        id
        title
      }
    }
  }
`;

export const GET_CURRICULUM_TITLE = gql`
  query curriculum($id: Float!) {
    curriculum(id: $id) {
      title
    }
  }
`;

export const GET_MY_COURSE_INFO = gql`
  query myCourseInfo {
    myCourseInfo {
      id
      categoryId
      title
      introduction
      difficulty
      thumbnail
      progress
      purchased
    }
  }
`;

export const GET_USER_APP = gql`
  query userApp($appId: String!) {
    userApp(appId: $appId) {
      id
      courseId
      userData
      assignmentSource
      permission
      isOwner
    }
  }
`;

export const GET_PRODUCT_THUMBNAIL = gql`
  query course($id: Float!) {
    course(id: $id) {
      thumbnail
    }
  }
`;

export const GET_SHARE_DATA = gql`
  query course($id: Float!) {
    course(id: $id) {
      title
      introduction
      thumbnail
    }
  }
`;

export const GET_INCORRECT_ASSIGNMENT_COUNT = gql`
  query incorrectAssignmentCount {
    incorrectAssignmentCount {
      count
    }
  }
`;

export const GET_UNCOMPLETED_ASSIGNMENT_IDS = gql`
  query uncompletedAssignmentIds {
    uncompletedAssignmentIds
  }
`;

export const GET_ASSIGNMENT = gql`
  query assignment($assignmentId: Float!) {
    assignment(assignmentId: $assignmentId) {
      id
      content
      code
      codeFilename
      assignmentCodeChoices {
        id
        name
        feedback
        isAnswer
        sequence
        assignmentId
        codeInputType
      }
    }
  }
`;

export const GET_USER_LEARNING_DATA = gql`
  query userLearningData {
    userLearningData {
      hasActiveSubscription
      remainingDailyAssignmentGenerationCount
    }
  }
`;

export const GET_GENERATE_CHAT_RESPONSE_FOR_ASSIGNMENT = gql`
  query generateChatResponseForAssignment(
    $userChat: String!
    $assignmentId: Float!
  ) {
    generateChatResponseForAssignment(
      userChat: $userChat
      assignmentId: $assignmentId
    )
  }
`;

export const GET_SERVICE_PLAN = gql`
  query servicePlan($servicePlanType: ServicePlanType!) {
    servicePlan(servicePlanType: $servicePlanType) {
      id
      name
      price
    }
  }
`;
