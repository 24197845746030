import React, { useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import OpenGraph from 'Components/core/Helmet/OpenGraph';
import {
  GET_ASSIGNMENT,
  GET_UNCOMPLETED_ASSIGNMENT_IDS,
} from 'gql/course/query';
import { PATH } from 'utils/constants/routes';
import { Assignment } from 'gql/course/type';
import Contents from '../Contents';
import css from './Session.module.scss';

const Session = () => {
  const navigate = useNavigate();
  const { contentId } = useParams();

  const { data, loading } = useQuery<{
    uncompletedAssignmentIds: number[];
  }>(GET_UNCOMPLETED_ASSIGNMENT_IDS, {
    context: { endpoint: 'course' },
    fetchPolicy: 'network-only',
    onCompleted: ({ uncompletedAssignmentIds }) => {
      const hasContentId = uncompletedAssignmentIds.includes(Number(contentId));
      const hasAssignment = uncompletedAssignmentIds.length !== 0;
      const firstContentId = uncompletedAssignmentIds[0];

      if (hasAssignment) {
        navigate(
          `${PATH.CONTENT.base}/${hasContentId ? contentId : firstContentId}`,
          {
            replace: true,
          },
        );
      } else {
        navigate(PATH.LEVEL_SELECT.language);
      }
    },
  });

  const [getAssignment, { data: contentData, error, refetch: refetchContent }] =
    useLazyQuery<{
      assignment: Assignment;
    }>(GET_ASSIGNMENT, {
      context: { endpoint: 'course' },
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (!contentId) return;

    getAssignment({
      variables: {
        assignmentId: Number(contentId),
      },
    });
  }, [contentId]);

  if (loading || !data || !contentData || error) return null;

  return (
    <div className={css.container}>
      <OpenGraph />
      <Contents
        assignment={contentData.assignment}
        contentList={data.uncompletedAssignmentIds}
        refetchContent={() => refetchContent()}
      />
    </div>
  );
};

export default Session;
