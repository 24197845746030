/**
 * 아이콘을 import 할때에는 반드시 PascalCamelCase로 작성한다 (2022.10.22 YunKukPark)
 */
import { ReactComponent as ArrowCollapseClose } from 'assets/icon/svg/md/arrow_collapse_close.svg';
import { ReactComponent as ArrowCollapseCloseM } from 'assets/icon/svg/md/arrow_collapse_close_m.svg';
import { ReactComponent as ArrowCollapseOpen } from 'assets/icon/svg/md/arrow_collapse_open.svg';
import { ReactComponent as ArrowCollapseOpenM } from 'assets/icon/svg/md/arrow_collapse_open_m.svg';
import { ReactComponent as BellAlarmDefault } from 'assets/icon/svg/md/bell_alarm_default.svg';
import { ReactComponent as BellAlarmNoti } from 'assets/icon/svg/md/bell_alarm_noti.svg';
import { ReactComponent as BookmarkFalse } from 'assets/icon/svg/md/bookmark_false.svg';
import { ReactComponent as BookmarkTrue } from 'assets/icon/svg/md/bookmark_true.svg';
import { ReactComponent as CartActive } from 'assets/icon/svg/md/cart_active.svg';
import { ReactComponent as Coin } from 'assets/icon/svg/md/coin.svg';
import { ReactComponent as ExpendMuFalse } from 'assets/icon/svg/md/expend_mu_false.svg';
import { ReactComponent as ExpendMuTrue } from 'assets/icon/svg/md/expend_mu_true.svg';
import { ReactComponent as FireCoding } from 'assets/icon/svg/md/fire_coding.svg';
import { ReactComponent as FootnotePrefixClip } from 'assets/icon/svg/md/footnote_prefix_clip.svg';
import { ReactComponent as FootnotePrefixFile } from 'assets/icon/svg/md/footnote_prefix_file.svg';
import { ReactComponent as FootnotePrefixLink } from 'assets/icon/svg/md/footnote_prefix_link.svg';
import { ReactComponent as FootnotePrefixUpload } from 'assets/icon/svg/md/footnote_prefix_upload.svg';
import { ReactComponent as FootnotePrefixYoutube } from 'assets/icon/svg/md/footnote_prefix_youtube.svg';
import { ReactComponent as BackGrey } from 'assets/icon/svg/md/ic_back_grey.svg';
import { ReactComponent as Bookmark } from 'assets/icon/svg/md/ic_bookmark.svg';
import { ReactComponent as IcCard } from 'assets/icon/svg/md/ic_card.svg';
import { ReactComponent as Close } from 'assets/icon/svg/md/ic_close.svg';
import { ReactComponent as IcContents } from 'assets/icon/svg/md/ic_contents.svg';
import { ReactComponent as DelPhoneGrey } from 'assets/icon/svg/md/ic_del_phone_grey.svg';
import { ReactComponent as IcDifficult } from 'assets/icon/svg/md/ic_difficult.svg';
import { ReactComponent as Hamburger } from 'assets/icon/svg/md/ic_hamburger.svg';
import { ReactComponent as Heart } from 'assets/icon/svg/md/ic_heart.svg';
import { ReactComponent as HeartFilled } from 'assets/icon/svg/md/ic_heart_filled.svg';
import { ReactComponent as HeartActive } from 'assets/icon/svg/md/ic_heart_fill.svg';
import { ReactComponent as HeartActive40 } from 'assets/icon/svg/md/ic_heart_fill_40.svg';
import { ReactComponent as HeartGrey } from 'assets/icon/svg/md/ic_heart_grey.svg';
import { ReactComponent as HeartDefault } from 'assets/icon/svg/md/ic_heart_line.svg';
import { ReactComponent as HeartWhite } from 'assets/icon/svg/md/ic_heart_white.svg';
import { ReactComponent as History } from 'assets/icon/svg/md/ic_history.svg';
import { ReactComponent as List } from 'assets/icon/svg/md/ic_list.svg';
import { ReactComponent as MdLock } from 'assets/icon/svg/md/ic_lock.svg';
import { ReactComponent as Logout } from 'assets/icon/svg/md/ic_logout.svg';
import { ReactComponent as Profile } from 'assets/icon/svg/md/ic_profile.svg';
import { ReactComponent as Refresh } from 'assets/icon/svg/md/ic_refresh.svg';
import { ReactComponent as Reset } from 'assets/icon/svg/md/reset.svg';
import { ReactComponent as Share } from 'assets/icon/svg/md/ic_share_aos.svg';
import { ReactComponent as ShareWhite } from 'assets/icon/svg/md/ic_share_aos_white.svg';
import { ReactComponent as IcTime } from 'assets/icon/svg/md/ic_time.svg';
import { ReactComponent as Trash } from 'assets/icon/svg/md/ic_trash.svg';
import { ReactComponent as IcVideo } from 'assets/icon/svg/md/ic_video.svg';
import { ReactComponent as KakaoPay } from 'assets/icon/svg/md/kakao_pay_logo.svg';
import { ReactComponent as LevelBg } from 'assets/icon/svg/md/level_bg.svg';
import { ReactComponent as LoginEmail } from 'assets/icon/svg/md/login_email.svg';
import { ReactComponent as LogoKakaoSimple } from 'assets/icon/svg/md/logo_kakao_simple.svg';
import { ReactComponent as My } from 'assets/icon/svg/md/my.svg';
import { ReactComponent as NaverPay } from 'assets/icon/svg/md/naver_pay_logo.svg';
import { ReactComponent as PlayCircleBlue } from 'assets/icon/svg/md/play_circle_blue.svg';
import { ReactComponent as PlayCircleGreen } from 'assets/icon/svg/md/play_circle_green.svg';
import { ReactComponent as PlayCircleRed } from 'assets/icon/svg/md/play_circle_red.svg';
import { ReactComponent as Receipt } from 'assets/icon/svg/md/receipt.svg';
import { ReactComponent as ReceiptAlarm } from 'assets/icon/svg/md/receipt_alarm_default.svg';
import { ReactComponent as ReceiptAlarmColored } from 'assets/icon/svg/md/receipt_alarm_grey.svg';
import { ReactComponent as ReviewAngry } from 'assets/icon/svg/md/review_angry.svg';
import { ReactComponent as ReviewAngryColored } from 'assets/icon/svg/md/review_angry_colored.svg';
import { ReactComponent as ReviewHmm } from 'assets/icon/svg/md/review_hmm.svg';
import { ReactComponent as ReviewHmmColored } from 'assets/icon/svg/md/review_hmm_colored.svg';
import { ReactComponent as ReviewSmile } from 'assets/icon/svg/md/review_smile.svg';
import { ReactComponent as ReviewSmileColored } from 'assets/icon/svg/md/review_smile_colored.svg';
import { ReactComponent as ReviewStarDisabled } from 'assets/icon/svg/md/review_star_disabled.svg';
import { ReactComponent as ReviewStarFalse } from 'assets/icon/svg/md/review_star_false.svg';
import { ReactComponent as ReviewStarTrue } from 'assets/icon/svg/md/review_star_true.svg';
import { ReactComponent as ShopCart } from 'assets/icon/svg/md/shop_cart.svg';
import { ReactComponent as CartGrey } from 'assets/icon/svg/md/shop_cart_grey.svg';
import { ReactComponent as ShopCartNoti } from 'assets/icon/svg/md/shop_cart_noti.svg';
import { ReactComponent as CartWhite } from 'assets/icon/svg/md/shop_cart_white.svg';
import { ReactComponent as HomeIcon } from 'assets/icon/svg/md/tool_home_icon.svg';
import { ReactComponent as NextGrey } from 'assets/icon/svg/md/tool_next_grey.svg';
import { ReactComponent as TossPay } from 'assets/icon/svg/md/toss_pay_logo.svg';
import { ReactComponent as WonPrice } from 'assets/icon/svg/md/won_price.svg';
import { ReactComponent as WonPriceWhite } from 'assets/icon/svg/md/won_price_white.svg';
import { ReactComponent as JustcodeLogo } from 'assets/icon/svg/md/Logo_justcode_md.svg';
import { ReactComponent as Wait } from 'assets/icon/svg/md/wait.svg';
import { ReactComponent as ImpactLine } from 'assets/icon/svg/md/impact_line.svg';

export const MediumIcon = {
  ArrowCollapseClose,
  ArrowCollapseOpen,
  ArrowCollapseCloseM,
  ArrowCollapseOpenM,
  BellAlarmDefault,
  BellAlarmNoti,
  Coin,
  ExpendMuFalse,
  ExpendMuTrue,
  FireCoding,
  FootnotePrefixClip,
  FootnotePrefixFile,
  FootnotePrefixLink,
  FootnotePrefixUpload,
  FootnotePrefixYoutube,
  Heart,
  HeartFilled,
  HeartDefault,
  HeartActive,
  HeartActive40,
  IcContents,
  IcDifficult,
  IcTime,
  IcCard,
  IcVideo,
  LevelBg,
  LoginEmail,
  LogoKakaoSimple,
  ReceiptAlarm,
  ReceiptAlarmColored,
  Reset,
  ReviewAngry,
  ReviewAngryColored,
  ReviewHmm,
  ReviewHmmColored,
  ReviewSmile,
  ReviewSmileColored,
  ReviewStarDisabled,
  ReviewStarFalse,
  ReviewStarTrue,
  BookmarkTrue,
  BookmarkFalse,
  ShopCart,
  ShopCartNoti,
  Share,
  Close,
  History,
  MdLock,
  Profile,
  Bookmark,
  Logout,
  Hamburger,
  List,
  Trash,
  Receipt,
  CartActive,
  My,
  BackGrey,
  DelPhoneGrey,
  Refresh,
  PlayCircleBlue,
  PlayCircleRed,
  PlayCircleGreen,
  KakaoPay,
  NaverPay,
  TossPay,
  HeartGrey,
  CartGrey,
  NextGrey,
  HeartWhite,
  CartWhite,
  ShareWhite,
  WonPrice,
  HomeIcon,
  WonPriceWhite,
  JustcodeLogo,
  Wait,
  ImpactLine,
};
