import { gql } from '@apollo/client';

export const DELETE_CART = gql`
  mutation deleteCart($orderIds: [Float!]!, $cartId: String!) {
    removeOrder(orderIds: $orderIds, cartId: $cartId) {
      id
    }
  }
`;

export const DELETE_ALL_CART = gql`
  mutation deleteAllCart($cartId: String!) {
    removeAllOrders(cartId: $cartId) {
      id
    }
  }
`;

export const GET_APPROVAL_PAYMENTS = gql`
  mutation getApprovalPayments(
    $paymentId: String!
    $orderId: String!
    $amount: Float!
    $couponCode: String
    $paymentMethod: PaymentMethod!
    $orderName: String!
  ) {
    createPayment(
      paymentId: $paymentId
      orderId: $orderId
      amount: $amount
      couponCode: $couponCode
      paymentMethod: $paymentMethod
      orderName: $orderName
    ) {
      orderId
      orderName
      amount
      method
      status
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation createPayment(
    $paymentId: String!
    $orderId: String!
    $amount: Float!
    $couponCode: String
    $paymentMethod: PaymentMethod!
    $orderName: String!
  ) {
    createPayment(
      paymentId: $paymentId
      orderId: $orderId
      amount: $amount
      couponCode: $couponCode
      paymentMethod: $paymentMethod
      orderName: $orderName
    ) {
      orderId
      amount
      orderName
      status
      method
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder(
    $name: String!
    $price: Float!
    $product: Product!
    $productId: Float!
  ) {
    createOrder(
      name: $name
      price: $price
      product: $product
      productId: $productId
    ) {
      id
      status
      price
      productId
      name
    }
  }
`;

export const CREATE_INSTANT_PURCHASE = gql`
  mutation createInstantPurchase(
    $name: String!
    $price: Float!
    $product: Product!
    $productId: Float!
  ) {
    createInstantPurchase(
      name: $name
      price: $price
      product: $product
      productId: $productId
    ) {
      id
    }
  }
`;

export const CREATE_ORDER_GROUP = gql`
  mutation createOrderGroup($orderIds: [Float!]!) {
    createOrderGroup(orderIds: $orderIds) {
      id
    }
  }
`;

export const CREATE_BILLING_INFORMATION = gql`
  mutation createBillingInformation($authKey: String!) {
    createBillingInformation(authKey: $authKey) {
      billingId
    }
  }
`;

export const CREATE_BILLING_PAYMENT = gql`
  mutation createBillingPayment(
    $billingInformationId: Float!
    $orderId: String!
    $productId: Float!
    $product: Product!
    $orderName: String!
    $amount: Float!
    $customerName: String!
  ) {
    createBillingPayment(
      billingInformationId: $billingInformationId
      orderId: $orderId
      productId: $productId
      product: $product
      orderName: $orderName
      amount: $amount
      customerName: $customerName
    ) {
      status
    }
  }
`;
