import React, { useState } from 'react';
import Icon, { IconName } from 'Components/core/Icon/Icon';
import css from 'Components/core/ModalPortal/Alert/Alert.module.scss';
import { AlertDialog } from 'store/Alert';
import { copyToClipboard } from 'utils/share';
import { createMarkup } from 'utils/constants/createMarkup';
import { cn, cond } from 'utils/styles';

const AlertBody = ({
  dialog,
  message,
  hasHelpEmailInfo,
}: {
  dialog: AlertDialog;
  message?: string;
  hasHelpEmailInfo?: boolean;
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const { icon, message: dialogMessage, desc } = dialog;

  return (
    <div className={css.content}>
      {icon && <Icon className={css.icon} name={icon as IconName} />}
      <strong
        className={cn(css.message, cond(!!desc, css.bold))}
        dangerouslySetInnerHTML={createMarkup(message || dialogMessage)}
      />
      {desc && (
        <p className={css.desc} dangerouslySetInnerHTML={createMarkup(desc)} />
      )}
      {hasHelpEmailInfo && (
        <div className={css.helpEmailInfo}>
          <p className={css.email}>
            <Icon name="helpEmail" />
            <span
              className={css.emailText}
              onClick={() =>
                copyToClipboard(helpEmail, false).then(() => setIsCopied(true))
              }
            >
              {helpEmail}
            </span>
            {isCopied && (
              <>
                <Icon name="checkCircleLine" />
                복사 완료
              </>
            )}
          </p>
          <p className={css.emailGuide}>문제가 반복되면 이메일로 알려주세요.</p>
        </div>
      )}
    </div>
  );
};

export default AlertBody;

const helpEmail = 'help@gracefulrain.co';
