import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Button from 'Components/core/Button';
import { GET_PRODUCT } from 'gql/course/query';
import { ProductType } from 'gql/course/type';
import { Order } from 'gql/payment/type';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { PATH } from 'utils/constants/routes';
import { cn } from 'utils/styles';
import css from './Product.module.scss';

interface Props {
  data: Order;
  isChecked: boolean;
  handleCheck: (
    id: number,
    productId: number,
    price: number,
    name: string,
  ) => void;
  handleDelete: () => void;
}

function Product({ data, isChecked, handleCheck, handleDelete }: Props) {
  const navigate = useNavigate();
  const { productId } = data;

  const { loading, data: productData } = useQuery<ProductType>(GET_PRODUCT, {
    variables: {
      id: productId,
    },
    context: { endpoint: 'course' },
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  if (loading || !productData) return null;

  const {
    id,
    thumbnail,
    englishTitle,
    title,
    introduction,
    difficulty,
    lecturesCount,
    price,
  } = productData.course;

  const toPascalCase = (str: string) => {
    const [first, ...rest] = str;
    return first + rest.join('').toLowerCase();
  };

  return (
    <div className={css.container}>
      <div className={css.leftWrapper}>
        <input
          type="checkbox"
          className={css.checkbox}
          checked={isChecked}
          onChange={() =>
            handleCheck(data.id, data.productId, price, data.name)
          }
        />
        <div
          className={css.infoWrapper}
          onClick={() => navigate(`${PATH.CURRICULUM.detailBase}/${id}`)}
        >
          <img alt="강의 썸네일" className={css.thumbnail} src={thumbnail} />
          <div className={css.info}>
            {/* <div className={css.badgeWrapper}>
              <LevelBadge level={difficulty as Level} />
              <p className={css.subTitle}>{englishTitle}</p>
            </div> */}

            <p className={css.title}>{title}</p>
            <p className={css.introduction}>{introduction}</p>
            <p className={css.additionalInfo}>
              {difficulty && <span>{toPascalCase(difficulty)}</span>}
              {englishTitle && <span>{englishTitle}</span>}
              {lecturesCount && <span>Lesson {lecturesCount}개</span>}
            </p>
            <div className={css.mdOnly}>
              <p className={css.price}>{price.toLocaleString()}원</p>
              <div onClick={e => e.stopPropagation()}>
                <Button variant="lineGrey" onClick={handleDelete}>
                  강의 삭제
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn(css.rightWrapper, css.mdHidden)}>
        <p className={css.price}>{price.toLocaleString()}원</p>
        <div onClick={e => e.stopPropagation()}>
          <Button variant="lineGrey" onClick={handleDelete}>
            강의 삭제
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Product;
