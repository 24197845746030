import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import css from 'Components/core/ModalPortal/Alert/Alert.module.scss';
import AlertBody from 'Components/core/ModalPortal/Alert/Body';
import AlertFooter from 'Components/core/ModalPortal/Alert/Footer';
import AlertHeader from 'Components/core/ModalPortal/Alert/Header';
import { ModalPortal } from 'Components/core/ModalPortal/Modal';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { useLockBodyScroll, usePrompt } from 'utils/hooks';

type actionType = 'success' | 'close';

const Alert = () => {
  const config = useReactiveVar(alertVar);
  const [action, setAction] = useState<actionType>();
  const {
    type = 'info',
    title = '',
    message = '',
    dialog = AlertMessage.common.error.unknown,
    hasCancelButton,
    hasHelpEmailInfo,
    confirmText = '확인',
    onSuccess,
    onClose,
  } = config;
  const closeModal = () => alertVar({ ...config, show: false });

  useLockBodyScroll();
  usePrompt(!action);
  useEffect(() => {
    if (!action) return;

    const actionHandler: { [key in actionType]: (() => void) | undefined } = {
      success: onSuccess,
      close: onClose,
    };

    actionHandler[action]?.();
    closeModal();
  }, [action]);

  return (
    <ModalPortal>
      <section className={css.backdrop}>
        <div
          className={css.dimmedBackground}
          onClick={() => setAction('close')}
        />
        <article className={css.container} onClick={e => e.stopPropagation()}>
          <AlertHeader title={title} switchModal={() => setAction('close')} />
          <AlertBody
            dialog={dialog}
            message={message}
            hasHelpEmailInfo={hasHelpEmailInfo}
          />
          <AlertFooter
            type={type}
            hasCancelButton={!!hasCancelButton}
            confirmText={confirmText}
            onClick={{
              positive: () => setAction('success'),
              negative: () => setAction('close'),
            }}
          />
        </article>
      </section>
    </ModalPortal>
  );
};

export default Alert;
