import React from 'react';
import Button from 'Components/core/Button';
import css from 'Components/core/ModalPortal/Alert/Alert.module.scss';
import { SemanticType } from 'store/Alert';

interface ModalFooterProps {
  type: SemanticType;
  hasCancelButton: boolean;
  confirmText: string;
  onClick?: {
    negative?: () => void;
    positive: () => void;
  };
}

const AlertFooter = ({
  type,
  hasCancelButton,
  confirmText,
  onClick,
}: ModalFooterProps) => {
  if (hasCancelButton) {
    return (
      <footer className={css.footer}>
        <Button variant="lineBase" size="large" onClick={onClick?.negative}>
          취소
        </Button>
        <Button size="large" onClick={onClick?.positive}>
          {confirmText}
        </Button>
      </footer>
    );
  }

  return (
    <footer className={css.footer}>
      <Button
        size="large"
        variant={buttonVariantsMap[type]}
        onClick={onClick?.negative}
      >
        {confirmText}
      </Button>
    </footer>
  );
};

const buttonVariantsMap = {
  positive: 'primary',
  warn: 'lineBase',
  info: 'primary',
  error: 'lineBase',
} as const;

export default AlertFooter;
