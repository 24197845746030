export interface DetailListType {
  id: number;
  title: string;
  description: string;
}

export interface ContentInfoType {
  [key: string]: string | number;
}

export interface ContentIconName {
  [key: string]: Icon;
}

interface Icon {
  icon: IconName;
  name: string;
  unit: string;
}

export enum Product {
  CURRICULUM = 'CURRICULUM',
  COURSE = 'COURSE',
}

type IconName = 'icContents' | 'icDifficult' | 'icTime' | 'icVideo';
