import React, {
  MouseEvent,
  useEffect,
  useRef,
  useState,
  Fragment,
  useLayoutEffect,
} from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Button from 'Components/core/Button';
import Icon from 'Components/core/Icon/Icon';
import { UPDATE_USER_DATA } from 'gql/course/mutation';
import { GET_COURSE_CONTENT } from 'gql/course/query';
import {
  answerStatusVar,
  customAnswersVar,
  customAnswerVar,
  submittedAnswersVar,
} from 'store/Course';
import { getCustomBlankVariables } from 'utils/fingereditor';
import { inputMatcher } from 'utils/fingereditor';
import { cn, cond } from 'utils/styles';
import { getLineCount, playBeep } from 'utils';
import { RESULT_COURSES } from 'utils/constants/temp';
import { AssignmentCodeChoice, CodeInputType } from 'gql/course/type';
import css from './Controller.module.scss';

type Props = {
  type: CodeInputType;
  code: string;
  answers: string[][] | null;
  editMode: Record<'on' | 'off', () => void>;
  isEditing: boolean;
  isLastContent: boolean;
  hasSingleOptionList: boolean;
  caret: number;
  options: AssignmentCodeChoice[];
  isQnAOn?: boolean;
  actions: {
    checkAnswer: (inputAnswers?: string[]) => void;
    submitAnswer: () => void;
    deleteAllAnswers: () => void;
    handleQnA: () => void;
  };
  setCaret: React.Dispatch<React.SetStateAction<number>>;
};

const UNEXPECTED_USER_DATA_KEY_SET = new Set(['', 'No_key']);

const Controller = ({
  type = CodeInputType.BUTTON,
  code: propCode,
  answers,
  editMode,
  isEditing,
  isLastContent,
  hasSingleOptionList,
  caret,
  options,
  isQnAOn,
  actions,
  setCaret,
}: Props) => {
  const { courseId, contentId } = useParams();
  const inputRef = useRef<HTMLInputElement>(null);
  // const [s3File, setS3File] = useState<File>();
  const [imgLoading, setImgLoading] = useState(false);
  const [widgetPosX, setWidgetPosX] = useState(0);

  const submittedAnswers = useReactiveVar(submittedAnswersVar);
  const customAnswers = useReactiveVar(customAnswersVar);
  const answerStatus = useReactiveVar(answerStatusVar);
  const customAnswerKey = getCustomBlankVariables(propCode)?.[0] ?? '';
  const customAnswer = UNEXPECTED_USER_DATA_KEY_SET.has(customAnswerKey)
    ? undefined
    : customAnswers[customAnswerKey];
  const blankLength = propCode.match(inputMatcher)?.length || 0;
  const isQuiz = options.every(
    ({ codeInputType }) => codeInputType === CodeInputType.QUIZ,
  );
  const optionType = isQuiz
    ? 'single'
    : answers === null
    ? 'custom'
    : blankLength === 1
    ? 'single'
    : 'multiple';
  const caretPosition = inputRef.current?.selectionStart ?? 0;
  const inputWidget = document.querySelector(
    '.inputWidget:not(.copy)',
  ) as HTMLSpanElement;
  const hasResult = RESULT_COURSES.includes(Number(courseId));
  const canSubmit =
    answerStatus === 'not submitted' &&
    (submittedAnswers.length !== 0 || customAnswer) &&
    (optionType !== 'multiple' ||
      !answers ||
      answers.length <= submittedAnswers.length) &&
    !imgLoading &&
    submittedAnswers[0]?.trim().length !== 0;

  const [updateUserData, { loading }] = useMutation(UPDATE_USER_DATA, {
    context: { endpoint: 'course' },
    refetchQueries: [
      {
        query: GET_COURSE_CONTENT,
        context: { endpoint: 'course' },
        variables: {
          id: Number(contentId),
        },
        fetchPolicy: 'cache-first',
      },
      'courseContent',
    ],
    awaitRefetchQueries: true,
    onError: () => setImgLoading(false),
  });

  // const [getUploadImg] = useMutation(PRESIGNED_URL, {
  //   context: { endpoint: 'course' },
  //   onCompleted: async ({ presignedUrl: { presignedUrl } }) => {
  //     if (!s3File) {
  //       setImgLoading(false);

  //       return;
  //     }

  //     await uploadImageToS3(presignedUrl, s3File);
  //     const [imageUrl] = presignedUrl.split('?');

  //     await updateUserData({
  //       variables: {
  //         courseId: Number(courseId),
  //         data: { [customAnswerKey]: imageUrl },
  //       },
  //     });

  //     submittedAnswersVar([imageUrl]);
  //     setImgLoading(false);
  //     playBeep();
  //   },
  //   onError: () => {
  //     setImgLoading(false);
  //     alertVar({
  //       show: true,
  //       type: 'error',
  //       dialog: AlertMessage.common.error.unknown,
  //       hasHelpEmailInfo: true,
  //     });
  //   },
  // });

  const injectCode = (code: string) => (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const hasAnswer = hasSingleOptionList && submittedAnswers.includes(code);

    if (hasAnswer) return;

    switch (optionType) {
      case 'custom':
        if (type !== CodeInputType.BUTTON) {
          updateUserData({
            variables: {
              courseId: Number(courseId),
              data: { [customAnswerKey]: code },
            },
          });
        }

        submittedAnswersVar([code]);
        playBeep();
        break;

      case 'single':
        submittedAnswersVar([code]);
        playBeep();
        break;

      case 'multiple':
        if (answers && answers.length <= submittedAnswers.length) return;

        submittedAnswersVar(submittedAnswers.concat(code));
        playBeep();
        break;
    }
  };

  // const onClickUploadBtn = () => inputRef.current?.click();
  // const onClickInputBtn = () => {
  //   inputRef.current?.focus();
  //   editMode.on();
  // };
  // const onClickDateBtn = () => inputRef.current?.showPicker();
  // const onKeyPressInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === 'Enter') {
  //     inputRef.current?.blur();

  //     return;
  //   }

  //   if (e.key === 'ArrowLeft') {
  //     if (caretPosition === 0) return;

  //     setCaret(prev => prev - 1);
  //   }

  //   if (e.key === 'ArrowRight') {
  //     if (caretPosition === inputRef.current?.value.length) return;

  //     setCaret(prev => prev + 1);
  //   }
  // };

  // const onSubmitInputCodeBtn = async () => {
  //   editMode.off();
  //   const trimmedValue = inputRef.current?.value.trim();
  //   const isTrimmed = trimmedValue !== inputRef.current?.value;

  //   if (!trimmedValue) {
  //     onClickInputBtn();

  //     return;
  //   }

  //   if (isTrimmed) setCaret(prev => prev - 1);

  //   if (optionType === 'custom') {
  //     await updateUserData({
  //       variables: {
  //         courseId: Number(courseId),
  //         data: { [customAnswerKey]: trimmedValue || ' ' },
  //       },
  //     });
  //     customAnswersVar({
  //       ...customAnswers,
  //       [customAnswerKey]: trimmedValue || ' ',
  //     });
  //   } else {
  //     submittedAnswersVar([trimmedValue || ' ']);
  //   }

  //   playBeep();
  //   actions?.checkAnswer([trimmedValue]);
  // };

  // const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   e.preventDefault();

  //   const file = e.target.files as FileList;

  //   if (!file[0].type.includes('image')) {
  //     alertVar({
  //       show: true,
  //       dialog: {
  //         message: '이미지 파일만 업로드 가능합니다.',
  //       },
  //     });
  //     return;
  //   }

  //   setImgLoading(true);
  //   setS3File(file[0]);
  //   getUploadImg();
  // };

  // const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.target;
  //   const date = new Date(value).toLocaleString('en-GB', {
  //     day: 'numeric',
  //     month: 'short',
  //     year: 'numeric',
  //   });

  //   updateUserData({
  //     variables: {
  //       courseId: Number(courseId),
  //       data: { [customAnswerKey]: value },
  //     },
  //   });
  //   submittedAnswersVar(value ? [date] : []);
  //   playBeep();
  // };

  // const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = convertBadQuotes(e.target.value);

  //   if (e.target.value[0] === ' ') {
  //     if (e.target.value === '  ') return;

  //     submittedAnswersVar([value]);
  //   } else {
  //     submittedAnswersVar([value || ' ']);
  //   }
  // };

  const onClickCheckResultBtn = () => {
    if (loading || imgLoading) return;

    editMode.off();

    if (type === CodeInputType.BUTTON && optionType === 'custom') {
      updateUserData({
        variables: {
          courseId: Number(courseId),
          data: { [customAnswerKey]: submittedAnswers[0] },
        },
      }).then(() => actions?.checkAnswer());

      return;
    }

    actions?.checkAnswer();
  };

  const handleCaret = () => {
    if (!inputWidget || !isEditing) return;

    const WIDGET_SIZE_FOR_CALC = {
      padding: 8,
      border: 1,
      margin: 2,
    };

    const originTextIndent = Object.values(WIDGET_SIZE_FOR_CALC).reduce(sum, 0);

    const maxWidth = document.getElementsByClassName('cm-line')[0]?.clientWidth;
    const text = submittedAnswers[0] ?? '';

    const widgetRect = inputWidget.getBoundingClientRect();
    const copyWidget = inputWidget.cloneNode(true) as HTMLSpanElement;

    const currentLineCount = getLineCount(inputWidget);
    const isOverflowedLine = currentLineCount > 1;

    if (!widgetPosX) setWidgetPosX(widgetRect.left);

    const anchorPosition = getPreviousElementRect(inputWidget);

    const textIndent = isOverflowedLine
      ? anchorPosition.x - widgetRect.x + originTextIndent
      : WIDGET_SIZE_FOR_CALC.padding + WIDGET_SIZE_FOR_CALC.border;

    copyWidget.innerHTML = `<li class="invisible" style="text-indent:${textIndent}px;">${text.slice(
      0,
      inputRef.current?.selectionStart ?? caret,
    )}<span class="caret"><span class="caret visible"></span></span>${text.slice(
      inputRef.current?.selectionStart ?? caret,
    )}
      </li>`;

    copyWidget.style.top = widgetRect.top + window.scrollY + 'px';
    copyWidget.style.left = widgetRect.left + 'px';
    copyWidget.style.width = maxWidth + 'px';
    copyWidget.style.height = widgetRect.height + 'px';
    copyWidget.classList.add('copy');
    copyWidget.classList.remove('editing');

    removeCopyWidget();

    document.body.appendChild(copyWidget);
  };

  const removeCopyWidget = () => {
    const prevWidget = document.getElementsByClassName('copy')[0];
    prevWidget?.remove();
  };

  const BTN_TYPE: { [type in CodeInputType]: JSX.Element | JSX.Element[] } = {
    BUTTON: options?.map(({ id, name }) => {
      if (!name) return <></>;

      const isSelected = hasSingleOptionList && submittedAnswers.includes(name);

      return (
        <Button
          key={id}
          className={cn(
            css.answerBtn,
            cond(isSelected, css.selected),
            cond(options.length > 1, css.multiple),
          )}
          onClick={injectCode(name)}
          variant={submittedAnswers.includes(name) ? 'primary' : 'lineBase'}
          size="full"
          isSelected={isSelected}
          animation={hasSingleOptionList}
          disabled={answerStatus === 'incorrect'}
        >
          {name}
          <input
            className="visually-hidden"
            ref={inputRef}
            value={customAnswer}
          />
        </Button>
      );
    }),
    QUIZ: options?.map(({ id, name, feedback, isAnswer }, idx) => {
      if (!name) return <></>;

      const styleStatus =
        answerStatus === 'not submitted'
          ? ''
          : isAnswer
          ? 'correct'
          : 'incorrect';
      const choicesNumber = idx + 1;
      const isSelected = hasSingleOptionList && submittedAnswers.includes(name);

      return (
        <Fragment key={id}>
          <Button
            className={cn(
              css.answerBtn,
              css.quiz,
              cond(
                isSelected && answerStatus !== 'not submitted',
                css[styleStatus],
              ),
              cond(
                isSelected && answerStatus === 'not submitted',
                css.selected,
              ),
              cond(options.length > 1, css.multiple),
            )}
            onClick={injectCode(name)}
            variant={
              submittedAnswers.some(ans => ans === name)
                ? 'primary'
                : 'lineBase'
            }
            size="full"
            isSelected={isSelected && answerStatus === 'not submitted'}
            disabled={
              submittedAnswers.length !== 0 && answerStatus !== 'not submitted'
            }
            animation
          >
            <div className={css.quizContent}>
              <span className={css.choicesNumber}>{choicesNumber}</span>
              <p>{name}</p>
            </div>
            <input
              className="visually-hidden"
              ref={inputRef}
              value={customAnswer}
            />
          </Button>
          {feedback && styleStatus && isSelected && (
            <div className={cn(css.feedback, css[styleStatus])}>
              <Icon name="check" size={14} />
              <p>{feedback}</p>
            </div>
          )}
        </Fragment>
      );
    }),
    // input: (
    //   <>
    //     <Button
    //       onClick={onClickInputBtn}
    //       className={cn(
    //         css.answerBtn,
    //         cond(answerStatus !== 'not submitted', css.disabled),
    //       )}
    //       size="full"
    //       height="40px"
    //       disabled={answerStatus !== 'not submitted'}
    //       animation
    //     >
    //       코드를 입력해주세요
    //     </Button>
    //     <input
    //       className="visually-hidden"
    //       ref={inputRef}
    //       value={submittedAnswers[0]}
    //       onChange={onChangeInput}
    //       onKeyUp={onKeyPressInput}
    //       onBlur={onSubmitInputCodeBtn}
    //       disabled={answerStatus !== 'not submitted'}
    //     />
    //   </>
    // ),
    // image: (
    //   <div className={css.imgButtonWrapper}>
    //     <Button
    //       className={css.button}
    //       size="large"
    //       height="40px"
    //       variant="lineGrey"
    //       onClick={onClickUploadBtn}
    //     >
    //       <Icon name="footnotePrefixUpload" />
    //       이미지 업로드
    //     </Button>
    //     <span className={css.imgGuide}>JPG, PNG 파일만 게시가 가능합니다.</span>
    //     <input ref={inputRef} type="file" onChange={onChangeFile} hidden />
    //   </div>
    // ),
    // date: (
    //   <>
    //     <Button
    //       onClick={onClickDateBtn}
    //       className={css.answerBtn}
    //       size="full"
    //       height="40px"
    //     >
    //       날짜 선택
    //     </Button>
    //     <input
    //       className={css.dateInput}
    //       ref={inputRef}
    //       type="date"
    //       onChange={onChangeDate}
    //     />
    //   </>
    // ),
  };

  useEffect(() => {
    if (submittedAnswers.length !== 0) return;

    if (customAnswer) {
      submittedAnswersVar([customAnswer ?? submittedAnswers[0] ?? ' ']);
      customAnswerVar(customAnswer);
    } else {
      customAnswerVar(null);
    }
  }, [inputRef.current, submittedAnswers]);

  useEffect(() => {
    // 경우 확실하게 나눠 파악해서 조건 부여해야 할 듯 (230302 래영)
    if (inputRef.current?.value && optionType === 'custom') {
      inputRef.current.value = submittedAnswers[0] ?? ' ';
    }
  }, [submittedAnswers]);

  useEffect(() => {
    setCaret(caretPosition);
  }, [inputRef.current?.selectionStart]);

  useEffect(() => {
    window.addEventListener('resize', handleCaret);

    return () => window.removeEventListener('resize', handleCaret);
  }, []);

  useEffect(() => {
    if (isEditing) return;

    removeCopyWidget();
  }, [isEditing]);

  useEffect(() => removeCopyWidget, []);

  useLayoutEffect(() => {
    if (!isEditing) return;

    handleCaret();
  }, [inputWidget, widgetPosX]);

  return (
    <div className={cn(css.controller, cond(!propCode, css.fill))}>
      {(answerStatus === 'not submitted' || isQuiz) && (
        <div className={css.buttonGroup}>{BTN_TYPE[type]}</div>
      )}
      {answerStatus === 'incorrect' ? (
        <Button
          onClick={actions.deleteAllAnswers}
          className={css.submitBtn}
          variant="lineBase"
          size="large"
        >
          다시 선택하기
        </Button>
      ) : (
        answerStatus === 'correct' && (
          <Button
            onClick={onClickCheckResultBtn}
            className={css.submitBtn}
            size="large"
            disabled={
              (submittedAnswers.length === 0 && !customAnswer) ||
              (optionType === 'multiple' &&
                !!answers &&
                answers.length > submittedAnswers.length) ||
              imgLoading
              // || (type === 'input' && submittedAnswers[0]?.trim().length === 0)
            }
          >
            {imgLoading
              ? '업로드 중..'
              : isLastContent
              ? hasResult
                ? '완성하기'
                : '완료'
              : '다음 문제로'}
          </Button>
        )
      )}
      <div className={css.actionBtnWrapper}>
        <Icon name="btnReset" onClick={actions.deleteAllAnswers} />
        <Icon
          name={`btnSubmit${canSubmit ? 'Default' : 'Disabled'}`}
          disabled={!canSubmit}
          onClick={onClickCheckResultBtn}
        />
        <Icon
          className={cond(!!isQnAOn, css.float)}
          name="btnQnA"
          onClick={actions.handleQnA}
        />
      </div>
    </div>
  );
};

export default Controller;

function getPreviousElementRect(node: HTMLElement): {
  x: number;
  y: number;
} {
  const previousNode = node.previousSibling;

  if (previousNode === null)
    return {
      x: node.getBoundingClientRect().left,
      y: node.getBoundingClientRect().top,
    };

  if (previousNode instanceof Element) {
    const { right, y } = previousNode.getBoundingClientRect();
    return { x: right, y };
  } else if (previousNode && previousNode.nodeType === Node.TEXT_NODE) {
    const range = document.createRange();
    range.setStart(previousNode, (previousNode as Text).length);
    range.setEnd(previousNode, (previousNode as Text).length);
    const { x, y } = range.getBoundingClientRect();
    return { x, y };
  } else {
    const { x, y } = node.getBoundingClientRect();
    return { x, y };
  }
}

function sum(acc: number, cur: number) {
  return acc + cur;
}
