import React from 'react';
import { Helmet } from 'react-helmet';

type OpenGraphProps = {
  title?: string;
  description?: string;
  image?: string | null;
  type?: string;
};

/**
 * open graph meta tag props
 * @param title       {string}
 * @param description {string}
 * @param image       {string}
 * @param type        {string}
 */
const OpenGraph = (props: OpenGraphProps) => {
  const filteredProps = Object.entries(props)
    .filter(([, value]) => !!value)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  const og = { ...DEFAULT_OG, ...filteredProps };
  return (
    <Helmet>
      {Object.entries(og).map(([prop, content]) => (
        <meta
          key={prop}
          name={prop}
          property={`og:${prop}`}
          content={content}
        />
      ))}
    </Helmet>
  );
};

export default OpenGraph;

// FIXME: s3에 올라가면 이미지 주소 변경
const DEFAULT_OG = {
  title: 'JUSTCODE-저스트코드',
  description:
    '버튼 하나로 쉽고 간편하게 코딩을! JUSTCODE에서 쉽고 재밌게 코딩을 시작하세요!',
  image:
    'https://s3-test-wecode.s3.ap-northeast-2.amazonaws.com/wecode_class_course_thumbnails/01_OG/OG_justcode%402x.png',
  type: 'website',
};
