import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { PaymentMethod } from 'Components/core/PaymentModal/type';
import { TossPaymentType } from 'Pages/Toss/type';
import { TOKEN } from 'utils/constants';
import { PATH } from 'utils/constants/routes';

interface Error {
  [key: string]: string;
}

const useTossPayment = () => {
  const isToken = localStorage.getItem(TOKEN);
  const navigate = useNavigate();

  const getPayment = ({
    name,
    title,
    price,
    audience,
    orderId,
    couponCode,
    payMethod,
  }: TossPaymentType) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { TossPayments }: any = window;
    const CLIENT_ID =
      payMethod === PaymentMethod.CARD
        ? process.env.REACT_APP_TOSS_CLIENT_ID
        : process.env.REACT_APP_TOSS_EASYPAY_CLIENT_ID;

    const tossPayments = TossPayments(CLIENT_ID ?? '');

    if (!isToken) {
      alert('로그인이 필요한 서비스입니다.');
      navigate(PATH.LOGIN.base);
      return;
    }

    if (payMethod !== PaymentMethod.CARD) {
      tossPayments
        .requestPayment('CARD', {
          amount: price,
          orderId: orderId || uuidv4(),
          orderName: title,
          customerName: name,
          successUrl: `${
            process.env.REACT_APP_TOSS_SUCCESS_URL
          }${`?payMethod=${PaymentMethod.EASY_PAY}`}${`&orderName=${encodeURIComponent(
            title,
          )}`}${
            couponCode ? `&coupon=${couponCode}` : ''
          }${`&target_audience=${audience}`}&type=payment`,
          failUrl: `${process.env.REACT_APP_TOSS_FAIL_URL}?type=payment`,
          flowMode: 'DIRECT',
          easyPay: payMethod,
        })
        .catch((error: Error) => {
          if (error) {
            alert(error.message);
          }
        });

      return getPayment;
    }

    tossPayments
      .requestPayment('CARD', {
        amount: price,
        orderId: orderId || uuidv4(),
        orderName: title,
        customerName: name,
        successUrl: `${process.env.REACT_APP_TOSS_SUCCESS_URL}?payMethod=${
          PaymentMethod.CARD
        }&orderName=${encodeURIComponent(title)}${
          couponCode ? `&coupon=${couponCode}` : ''
        }&target_audience=${audience}&type=payment`,
        failUrl: `${process.env.REACT_APP_TOSS_FAIL_URL}?type=payment`,
      })
      .catch((error: Error) => {
        if (error) {
          alert(error.message);
        }
      });
  };

  return getPayment;
};

export default useTossPayment;
