import { PATH } from 'utils/constants/routes';

export const GNB_ITEMS = [
  { id: 1, path: PATH.HOME.base, content: '홈' },
  {
    id: 2,
    path: PATH.MYPAGE.base,
    content: '마이페이지',
    shouldLogin: true,
  },
];
