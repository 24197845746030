import { ApolloClient, from } from '@apollo/client';
import cache from './cache';
import {
  loadingLink,
  loadingErrorLink,
  authLink,
  errorLink,
  httpLink,
} from './links';

const client = new ApolloClient({
  link: from([loadingLink, loadingErrorLink, authLink, errorLink, httpLink]),
  cache,
});

export default client;
