import React from 'react';
import { useMutation } from '@apollo/client';
import Button from 'Components/core/Button';
import Icon from 'Components/core/Icon/Icon';
import Modal from 'Components/core/ModalPortal/Modal';
import Share from 'Pages/CurriculumRouter/CurriculumDetail/Share';
import { copyToClipboard } from 'utils/share';
import { UserPermission } from 'gql/course/type';
import {
  UPDATE_USER_APP_PERMISSION,
  CREATE_SHARING_URL,
} from 'gql/course/mutation';
import { GET_USER_APP } from 'gql/course/query';
import { ShareData, SharingUserApp } from './type';
import css from './ShareModal.module.scss';

interface Props {
  courseId: number;
  appId: string;
  userPermission: UserPermission;
  shareData: ShareData;
  onClose: () => void;
}

const ShareModal = ({
  courseId,
  appId,
  shareData,
  userPermission,
  onClose,
}: Props) => {
  const [updateUserAppPermission] = useMutation(UPDATE_USER_APP_PERMISSION, {
    context: { endpoint: 'course' },
    refetchQueries: [
      {
        query: GET_USER_APP,
        context: { endpoint: 'course' },
        variables: {
          appId,
        },
      },
      'userApp',
    ],
  });

  const [createSharingUrl] = useMutation<{ createSharingUrl: SharingUserApp }>(
    CREATE_SHARING_URL,
    {
      context: { endpoint: 'course' },
      variables: {
        courseId,
      },
      onCompleted: ({ createSharingUrl: { sharingUrl } }) =>
        copyToClipboard(sharingUrl),
    },
  );

  const PERMISSIONS = [
    {
      id: 1,
      type: '비공개',
      permission: UserPermission.PRIVATE,
      description: '결과물을 비공개하고 나만 볼 수 있습니다.',
      subContent: <></>,
    },
    {
      id: 2,
      type: '모두 공개',
      permission: UserPermission.PUBLIC,
      description: '모든 사람들이 제한없이 결과를 볼 수 있습니다.',
      subContent: (
        <div className={css.shareWrapper}>
          <Share
            course={shareData}
            width="200px"
            disabled={userPermission !== UserPermission.PUBLIC}
          />
        </div>
      ),
    },
    {
      id: 3,
      type: '링크를 가진 사람만 보기',
      permission: UserPermission.RESTRICTED,
      description: '링크를 가진 사람만 결과물을 보고 사용할 수 있습니다.',
      subContent: (
        <Button
          className={css.copyLinkBtn}
          variant="lineGrey"
          size="large"
          onClick={() => createSharingUrl()}
          disabled={userPermission !== UserPermission.RESTRICTED}
        >
          링크 주소 복사하기
        </Button>
      ),
    },
  ];

  return (
    <Modal switchModal={onClose}>
      <div className={css.modal}>
        <div className={css.modalHeader}>
          <p className={css.title}>공유하기</p>
          <Icon name="close" onClick={onClose} />
        </div>
        <div className={css.modalBody}>
          <p className={css.subTitle}>
            다른 사람이 볼 수 있는 권한을 설정해주세요.
          </p>
          {PERMISSIONS.map(
            ({ id, type, permission, description, subContent }) => (
              <div key={id} className={css.permissionBox}>
                <div className={css.radioBtn}>
                  <input
                    id={String(id)}
                    name={type}
                    value={permission}
                    type="radio"
                    className={css.radio}
                    onChange={() =>
                      updateUserAppPermission({
                        variables: {
                          courseId,
                          permission,
                        },
                      })
                    }
                    checked={permission === userPermission}
                  />
                  <label className={css.labelText} htmlFor={String(id)}>
                    {type}
                  </label>
                </div>
                <div className={css.permissionContent}>
                  <p className={css.description}>{description}</p>
                  {subContent}
                </div>
              </div>
            ),
          )}
        </div>
        <div className={css.modalFooter}>
          <Button className={css.confirmBtn} size="full" onClick={onClose}>
            확인
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
