export const PATH = {
  HOME: {
    base: '/',
  },
  CLASS: {
    base: '/class',
  },
  LOGIN: {
    base: '/login',
    contentLogin: '/content-login',
  },
  SIGNUP: {
    base: '/signup',
  },
  AUTH: {
    kakao: '/auth/kakao',
  },
  COURSE: {
    base: '/course',
    detail: '/course/:courseId',
    myContent: '/course/:courseId/:contentId',
    completed: '/course/:courseId/completed',
    payment: '/course/:courseId/payment',
  },
  CONTENT: {
    base: '/content',
    detail: '/content/:contentId',
  },
  APP: {
    base: '/app',
    detail: '/app/:appId',
  },
  SHARING: {
    base: '/sharing',
    detail: '/sharing/:sharingCode',
  },
  CURRICULUM: {
    base: '/curriculum',
    level: '/curriculum/levels/:curriculumId',
    levelBase: '/curriculum/levels',
    sessionList: '/curriculm/courses/:sessionId',
    sessionListBase: '/curriculum/courses',
    session: '/curriculum/course/:courseId',
    sessionBase: '/curriculum/course',
    detail: '/curriculum/detail/:courseId',
    detailBase: '/curriculum/detail',
  },
  TOSS: {
    success: '/toss-success',
    fail: '/toss-fail',
  },
  DEPRECATED_MYPAGE: {
    base: '/mypage',
    tab: '/mypage/:tab',
    myInfo: '/mypage/my-info',
    favorite: '/mypage/favorite',
    bookmark: '/mypage/bookmark',
    myCourse: '/mypage/my-course',
    purchaseHistory: '/mypage/purchase-history',
    editProfile: '/mypage/edit-profile',
    logout: '/mypage/logout',
  },
  MYPAGE: {
    base: '/my-page',
  },
  CART: {
    base: '/cart',
  },
  SUBSCRIPTION: {
    base: '/subscription',
  },
  TERM: {
    base: '/term/:subject',
    site: '/term/site',
    privacy: '/term/privacy',
    agreement: '/term/agreement',
    refund: '/term/refund',
  },
  LEVEL_TEST: {
    base: '/level-test',
  },
  TEST_RESULT: {
    base: '/test-result',
    step: '/test-result/:step',
    'signup-prompt': '/test-result/signup-prompt',
    'analysis-completed': '/test-result/analysis-completed',
    'score-analysis': '/test-result/score-analysis',
    evaluation: '/test-result/evaluation',
    recommend: '/test-result/recommend',
    subscription: '/test-result/subscription',
  },
  LEVEL_SELECT: {
    base: '/level-select',
    step: '/level-select/:step',
    language: '/level-select/language',
    difficulty: '/level-select/difficulty',
  },
  CONTENT_RESULT: {
    base: '/content-result',
  },
  CONTENT_LOADING: {
    base: '/content-loading',
  },
  SUBSCRIPTION_TEST: {
    base: '/subscription',
  },
  NOTFOUND: {
    base: '*',
  },
};
