import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import MypageCard from 'Components/domain/Mypage/Card';
import ErrorPage from 'Components/core/ErrorPage';
import { WishListType } from 'gql/course/type';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { PATH } from 'utils/constants/routes';
import { DELETE_WISHLIST } from 'gql/course/mutation';
import { GET_WISHLIST } from 'gql/course/query';
import NoContent from '../NoContent';
import css from './WishList.module.scss';

const WishList = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery<{
    wishList: { courseInfoList: WishListType[] };
  }>(GET_WISHLIST, {
    context: { endpoint: 'course' },
    fetchPolicy: 'network-only',
  });

  const [deleteWishList] = useMutation(DELETE_WISHLIST, {
    context: { endpoint: 'course' },
    onCompleted: refetch,
    onError: () => {
      alertVar({
        show: true,
        type: 'error',
        dialog: AlertMessage.common.error.unknown,
        hasHelpEmailInfo: true,
      });
    },
  });

  const deleteWish = (id: number) => {
    alertVar({
      show: true,
      hasCancelButton: true,
      type: 'warn',
      dialog: AlertMessage.course.warn.deleteWishlist,
      onSuccess: () =>
        deleteWishList({ variables: { courseIds: [Number(id)] } }),
    });
  };

  if (error) return <ErrorPage />;
  if (loading || !data) return null;

  return (
    <div className={css.container}>
      {data.wishList.courseInfoList.length === 0 ? (
        <NoContent
          icon="emptyWish"
          title="어떤 코딩을 해볼까요?"
          subText="구매할 강의를 미리 골라보세요."
          btnText="강의 골라보기"
          onClick={() => navigate(PATH.CLASS.base)}
          width="96px"
        />
      ) : (
        data.wishList.courseInfoList.map(course => (
          <div key={course.id} className={css.cardBox}>
            <MypageCard
              data={course}
              variant="wishList"
              hasWishBtn
              handleWishBtn={() => deleteWish(course.id)}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default WishList;
