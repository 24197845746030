import React, {
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { getLineCount } from 'utils';
import { cn } from 'utils/styles';
import Icon from '../Icon/Icon';
import Button from '../Button';
import { BubbleActionType } from './type';
import css from './SpeechBubble.module.scss';

const LINES_TO_SHOW = 3;

interface Props {
  actionType?: BubbleActionType;
  width?: string;
  height?: string;
  arrow?: 'left' | 'right' | '';
  className?: string;
  onConfirm?: () => void;
}

const SpeechBubble = ({
  actionType,
  width,
  height,
  arrow = '',
  className = '',
  onConfirm,
  children,
}: PropsWithChildren<Props>) => {
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState(1);
  const [lineHeight, setLineHeight] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const setLineHeightAndTotalPages = () => {
    if (!wrapperRef.current) return;

    // TODO: 하위 요소의 형태를 <ele><childElem></childElem></ele> 형태로 고정하게 짜여진 코드, 추후 수정 필요(230607 래영)
    const element = wrapperRef.current.firstChild?.firstChild as HTMLElement;

    if (!element) return;

    const lineCount = getLineCount(element);
    const elementLineHeight = parseInt(
      window.getComputedStyle(element).lineHeight,
      10,
    );

    setLineHeight(elementLineHeight);
    setTotalPages(Math.ceil(lineCount / LINES_TO_SHOW));
  };

  useLayoutEffect(() => {
    setLineHeightAndTotalPages();
    // TOFIX: mount 시 FingerEditor가 width overflow하는 문제 수정 후 삭제 (230616 래영)
    setTimeout(setLineHeightAndTotalPages, 200);
    setTimeout(setLineHeightAndTotalPages, 500);

    window.addEventListener('resize', setLineHeightAndTotalPages);

    return () =>
      window.removeEventListener('resize', setLineHeightAndTotalPages);
  }, [wrapperRef]);

  const isPrevActive = currentPage !== 1;
  const isNextActive = currentPage !== totalPages;

  const ACTION_MAP: { [key in BubbleActionType]: JSX.Element } = {
    confirm: (
      <Button className={css.confirmBtn} size="full" onClick={onConfirm}>
        확인
      </Button>
    ),
    pagination: (
      <div className={css.pageIndicator}>
        <Icon
          name={`btnBack${isPrevActive ? 'Default' : 'Disabled'}`}
          disabled={!isPrevActive}
          onClick={() => isPrevActive && setCurrentPage(prev => prev - 1)}
        />
        {totalPages && (
          <span className={css.indicator}>
            {currentPage}/{totalPages}
          </span>
        )}
        <Icon
          name={`btnNext${isNextActive ? 'Default' : 'Disabled'}`}
          disabled={!isNextActive}
          onClick={() => isNextActive && setCurrentPage(prev => prev + 1)}
        />
      </div>
    ),
  };

  return (
    <div
      className={cn(css.container, css[arrow], className)}
      style={{ width, height }}
    >
      <div
        className={css.contentWrapper}
        style={{
          maxHeight:
            actionType === 'pagination'
              ? `${lineHeight * LINES_TO_SHOW}px`
              : '100%',
        }}
      >
        <div
          style={{
            transform: `translateY(-${
              actionType === 'pagination'
                ? lineHeight * (currentPage - 1) * LINES_TO_SHOW
                : '0'
            }px)`,
          }}
          ref={wrapperRef}
        >
          {children}
        </div>
      </div>
      {actionType && ACTION_MAP[actionType]}
    </div>
  );
};

export default SpeechBubble;
