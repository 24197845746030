import React, { memo, MouseEvent, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Button from 'Components/core/Button';
// import LevelBadge from 'Components/core/LevelBadge';
import { useSwitchModal } from 'Components/core/ModalPortal/Modal.hook';
import PaymentModal from 'Components/core/PaymentModal';
import ProgressBar from 'Pages/CurriculumRouter/Components/ProgressBar';
import { Product } from 'Pages/CurriculumRouter/CurriculumDetail/type';
import { GET_MY_CARD_INFO } from 'gql/course/query';
import { CourseInfo } from 'gql/course/type';
import { AlertMessage } from 'lib/constant/alertMessage';
import { alertVar } from 'store/Alert';
import { PATH } from 'utils/constants/routes';
import { cn } from 'utils/styles';
import { useCart } from 'utils/hooks';
import { RESULT_COURSES } from 'utils/constants/temp';
import NotDoneModal from './NotDoneModal';
import { MyCardInfoType } from './type';
import css from './Card.module.scss';

type MypageCardVariant = 'wishList' | 'myCourse';

interface Props {
  data: CourseInfo;
  variant: MypageCardVariant;
  hasWishBtn?: boolean;
  handleWishBtn?: () => void;
}

const MypageCard = ({
  data,
  variant,
  hasWishBtn = false,
  handleWishBtn,
}: Props) => {
  const navigate = useNavigate();
  const { handleAddToCart } = useCart();
  const { isOpenModal, switchModal } = useSwitchModal();
  const { id, title, thumbnail, price = 0, purchased } = data;
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);

  const { loading, data: cardData } = useQuery<MyCardInfoType>(
    GET_MY_CARD_INFO,
    {
      variables: { courseId: id },
      context: { endpoint: 'course' },
      fetchPolicy: 'no-cache',
      skip: hasWishBtn,
    },
  );

  // TODO: 추후에 결과물이 없음을 보여주는 데이터 추가되면 수정할 것
  const hasResult = RESULT_COURSES.includes(id);
  const { appId, progress = 0 } = cardData?.course || {};

  const handleLoading = () =>
    alertVar({
      show: true,
      type: 'error',
      dialog: AlertMessage.course.warn.loadingDeployData,
    });

  const viewResult = () => {
    if (loading) return handleLoading();
    if (!appId) return switchModal();

    navigate(`${PATH.APP.base}/${appId}`);
  };

  const goToCart = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleAddToCart({
      name: title,
      price: price,
      product: Product.COURSE,
      productId: id,
    });
  };

  const onClickHeartButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleWishBtn?.();
  };

  const onClickPaymentButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpenPaymentModal(true);
  };

  const extraBottom = useMemo(
    () => ({
      wishList: (
        <div className={css.wishBtnWrapper}>
          <div className={css.left}>
            <Button
              variant="lineGrey"
              size="small"
              onClick={onClickHeartButton}
            >
              찜취소
            </Button>
          </div>
          {!purchased && (
            <div className={css.right}>
              <Button variant="lineGrey" size="small" onClick={goToCart}>
                장바구니
              </Button>
              <Button
                variant="primary"
                size="small"
                onClick={onClickPaymentButton}
              >
                구매하기
              </Button>
              {isOpenPaymentModal && (
                <PaymentModal
                  purchaseList={[{ productId: id, name: title, price }]}
                  onClose={() => setIsOpenPaymentModal(false)}
                />
              )}
            </div>
          )}
        </div>
      ),
      myCourse: (
        <div className={css.progressBtnWrapper}>
          <div className={css.progressStatusWrapper}>
            <ProgressBar
              className={css.progress}
              variant="thin"
              progress={progress}
              hasPercentage
            />
          </div>
          <div className={css.btnContainer} onClick={e => e.stopPropagation()}>
            <div className={css.btnWrapper}>
              <Button
                variant="lineGrey"
                size="small"
                to={`${PATH.COURSE.base}/${id}`}
              >
                강의 보기
              </Button>
              {hasResult && (
                <Button variant="primary" size="small" onClick={viewResult}>
                  결과 보기
                </Button>
              )}
            </div>

            {isOpenModal && (
              <NotDoneModal switchModal={switchModal} contentId={id} />
            )}
          </div>
        </div>
      ),
    }),
    [isOpenModal, progress, switchModal, viewResult, id],
  );

  return (
    <div
      className={css.container}
      onClick={() => navigate(`${PATH.COURSE.base}/${id}`)}
    >
      <div className={css.imgBox}>
        <img className={css.cardImg} alt="card" src={thumbnail} />
      </div>
      <h1 className={cn(css.title, css[variant])}>{title}</h1>
      {extraBottom[variant]}
    </div>
  );
};

export default memo(MypageCard);
